import { Component } from 'react';
import ReactGA from 'react-ga';

const disableAnalytics = (
  process.env.NODE_ENV === 'development'
  || typeof window === 'undefined'
  || (document && document.location.href.indexOf('preview=1') !== -1)
);

ReactGA.initialize('UA-240319564-2', {
  testMode: disableAnalytics,
});

const sendPageView = (location) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
  if (typeof window !== 'undefined' && window.hj) {
    window.hj('stateChange', location.pathnam);
  }
};


class Analytics extends Component {
  componentDidMount() {
    sendPageView(this.props.history.location);
    this.props.history.listen(sendPageView);
  }

  render() {
    return this.props.children;
  }
}

export default Analytics;
