import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import { hotjar } from 'react-hotjar';

import { loadDataset } from './state/actions';
import Nav from './components/Nav';
import FloatingPrompt from './components/FloatingPrompt';
import Visualization from './components/Visualization';
import PanelError from './components/PanelError';
import RouteList from './components/RouteList';

import './styles/main.scss';

const frontload = async (p) => p.loadDataset();

class Layout extends Component {
  componentDidMount() {
    return this.loaded;
  }

  render() {
    if (typeof window !== 'undefined') {
      hotjar.initialize('3430855', 6);
    }
    const { error, isModal, dataset } = this.props;
    const layoutClassnames = classNames(
      'layout',
      { 'is-modal': isModal },
    );

    if (!dataset || !Object.keys(dataset).length) return null;

    return (
      <div className={layoutClassnames}>
        <Visualization />
        <FloatingPrompt />
        {(error && error[0]) ? <PanelError errorCode={error[0]} /> : <RouteList />}
        <Nav />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isModal: state.isModal,
  dataset: state.dataset,
  error: state.error,
});

const mapDispatchToProps = (dispatch) => ({
  loadDataset: () => dispatch(loadDataset()),
});

const statefulLayout = connect(mapStateToProps, mapDispatchToProps);

const frontloadedLayout = statefulLayout(
  frontloadConnect(frontload, {
    noServerRender: false,
    onMount: true,
    onUpdate: false,
  })(Layout),
);

export default frontloadedLayout;
