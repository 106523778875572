import { matchPath } from 'react-router';

import { monthsAbvr, routesPath } from '../config';

export const extractParams = (location) => {
  const extractedParams = {};
  Object.keys(routesPath).forEach((k) => {
    const match = matchPath(location.pathname, {
      path: routesPath[k],
      exact: true,
      sensitive: true,
    });
    if (!match) return;
    Object.keys(match.params).forEach((p) => {
      if (match.params[p] !== null) extractedParams[p] = match.params[p];
    });
  });
  return extractedParams;
};
export function numberToStr(nbr) {
  return `${(nbr < 10) ? '0' : ''}${nbr}`;
}

export function getReadableDuration(durationSec) {
  if (!durationSec) return durationSec;
  const duration = Math.floor(durationSec / 60);
  const hours = Math.floor(duration / 60);
  const minutes = Math.floor(duration - hours * 60);
  return `${hours}h${numberToStr(minutes)}min`;
}

export function formatTime(input) {
  if (!input) return input;
  const hour = Number(input.substr(0, 2));
  const min = Number(input.substr(2, 2));

  let hourAmPm = hour;
  if (hour > 12) hourAmPm = hour - 12;
  if (hour === 0) hourAmPm = 12;

  const ampm = hour > 11 ? 'PM' : 'AM';

  return `${numberToStr(hourAmPm)}:${numberToStr(min)}${ampm}`;
}

export function formatPrice(val) {
  if (!val) return '';
  const re = '\\d(?=(\\d{3})+$)';
  return Number(val).toFixed(0).replace(new RegExp(re, 'g'), '$&,');
}

export function weekCodeToDate(weekCode) {
  const y = Number(String(weekCode).substr(0, 4));
  const w = Number(String(weekCode).substr(4, 2));
  const simple = new Date(y, 0, 1 + (w - 1) * 7);
  const dow = simple.getUTCDay();
  const startDate = simple;
  if (dow <= 4) {
    startDate.setUTCDate(simple.getUTCDate() - simple.getUTCDay() + 1);
  } else {
    startDate.setUTCDate(simple.getUTCDate() + 8 - simple.getUTCDay());
  }
  return startDate;
}

export function dateToWeekCode(date) {
  date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7));

  const yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
  const weekNo = Math.ceil((((date - yearStart) / 86400000) + 1) / 7);
  return Number(`${date.getUTCFullYear()}${numberToStr(weekNo)}`);
}

export function weekCodeToDateRange(weekCode) {
  const startDate = weekCodeToDate(weekCode);
  const startStr = `${monthsAbvr[startDate.getUTCMonth()]} ${numberToStr(startDate.getUTCDate())}`;

  const endDate = startDate;
  endDate.setUTCDate(endDate.getUTCDate() + 6);
  const endStr = `${monthsAbvr[endDate.getUTCMonth()]} ${numberToStr(endDate.getUTCDate())}`;

  return `${startStr} - ${endStr}`;
}

export function getCurrentWeekCode() {
  const localDate = new Date();
  const UTCDate = new Date(Date.UTC(
    localDate.getUTCFullYear(),
    localDate.getUTCMonth(),
    localDate.getUTCDate(),
  ));
  UTCDate.setUTCDate(UTCDate.getUTCDate() + 4 - (UTCDate.getUTCDay() || 7));
  return dateToWeekCode(UTCDate);
}

export function addToWeekCode(weekCode, increment) {
  const date = weekCodeToDate(weekCode);
  date.setUTCDate(date.getUTCDate() + increment * 7);
  return dateToWeekCode(new Date(date));
}

export function weekCodeDiff(w1, w2) {
  const d1 = weekCodeToDate(w1);
  const d2 = weekCodeToDate(w2);
  return Math.ceil((((d1 - d2) / 86400000) - 1) / 7);
}

export function accessValue(obj, key) {
  return key.split('.').reduce((o, i) => o[i], obj);
}

export function formatAircraft(value) {
  if (!value) return value;
  return value.replace('Industrie', '').split('/')[0].split(' With')[0];
}

export function mapObject(obj, fn) {
  const newObj = {};
  Object.keys(obj).forEach((k) => {
    newObj[k] = { ...fn(obj[k]), key: k };
  });
  return newObj;
}

export function formatDistance(value) {
  if (!value) return value;
  return `${Math.round(value / 1.609).toLocaleString()}mi`;
}

export function toTopoCoordinates(d) {
  return {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [d.longitude, d.latitude],
    },
  };
}

export function toTopoLine({ source, target }) {
  return {
    type: 'Feature',
    geometry: {
      type: 'LineString',
      coordinates: [source, target],
    },
  };
}

export function getFloatFromString(str) {
  if (!str) return null;
  return Number(String(str).replace(/[^0-9.]+|\s+/gmi, ''));
}

export function applyKey(val, key) {
  if (!key) return val;
  if (key.constructor === Function) {
    return key(val);
  }
  if (val.constructor === Object) {
    return val[key];
  }
  return val;
}

export function compareFloatAsc(a, b, key) {
  return getFloatFromString(applyKey(a, key)) - getFloatFromString(applyKey(b, key));
}

export function flightCodeToDay(flightCode) {
  const flightCodeParts = flightCode.split('-');
  const dateCode = flightCodeParts[flightCodeParts.length - 1];
  const formattedDate = `${dateCode.substr(0, 4)}-${dateCode.substr(4, 2)}-${dateCode.substr(6, 2)}`;
  const fullDate = new Date(formattedDate);
  return `${monthsAbvr[fullDate.getUTCMonth()]} ${numberToStr(fullDate.getUTCDate())}`;
}

export function stripAccents(str) {
  const translateRe = /[àáâãäçèéêëìíîïñòóôõöùúûüýÿÀÁÂÃÄÇÈÉÊËÌÍÎÏÑÒÓÔÕÖÙÚÛÜÝ]/g;
  const translate = 'aaaaaceeeeiiiinooooouuuuyyAAAAACEEEEIIIINOOOOOUUUUY';
  return str.replace(translateRe, (match) => (
    translate.substr(translateRe.source.indexOf(match) - 1, 1)
  ));
}

export function toSlug(str) {
  if (!str) return str;
  return str.toString().normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/\./g, '')
    .replace(/\//g, '-')
    .replace(/[^a-zA-Z0-9- ]/g, '')
    .replace(/ +/g, '-')
    .replace(/--/g, '-')
    .toLowerCase();
}

export function compareFloatDesc(a, b, key) {
  return getFloatFromString(applyKey(b, key)) - getFloatFromString(applyKey(a, key));
}

export function compareStringAZ(a, b, key) {
  return applyKey(a, key) < applyKey(b, key) ? -1 : 1;
}

export function compareStringZA(a, b, key) {
  return applyKey(b, key) < applyKey(a, key) ? -1 : 1;
}

export function getSortFn(sortFn) {
  if (sortFn === 'compareFloatAsc') return compareFloatAsc;
  if (sortFn === 'compareFloatDesc') return compareFloatDesc;
  if (sortFn === 'compareStringZA') return compareStringZA;
  return compareStringAZ;
}

export function immutableSort(values, compareFunction, key) {
  const compareFn = compareFunction || compareStringAZ;
  return values.concat().sort((a, b) => compareFn(a, b, key));
}

export function immutableCustomSort(values, compareFn, key, orderedList, isLast) {
  const firstSection = [];
  const secondSection = values.concat();
  orderedList.forEach((f) => {
    const valueList = (key) ? values.map((v) => v[key]) : values;
    const i = valueList.indexOf(f);
    if (i !== -1) {
      firstSection.push(values[i]);
      secondSection.splice(i, 1);
    }
  });
  const sortedSecondSection = immutableSort(secondSection, (a, b) => compareFn(a, b, key));
  return isLast
    ? sortedSecondSection.concat(firstSection)
    : firstSection.concat(sortedSecondSection);
}

export function immutableSubValueSort(values, key) {
  const compareFloatAscSubValue = (a, b, subKey) => {
    const valA = a.value[subKey];
    const valB = b.value[subKey];
    return getFloatFromString(valA) - getFloatFromString(valB);
  };
  return values.concat().sort((a, b) => compareFloatAscSubValue(a, b, key));
}
