import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { push } from 'connected-react-router';
import Loadable from 'react-loadable';

import { findRoutes, setAirportMarkers } from '../state/actions';
import { immutableSort, getSortFn } from '../utils';

import VirtualizedSelect from './VirtualizedSelect';
import SeoHelmet from './SeoHelmet';
import * as Actions from '../state/actions';


const NearbyLoading = () => (
  <div className="suggestions">
    <div className="suggestion-loading">Locating nearby airports...</div>
  </div>
);

const NearbyAirports = Loadable({
  loader: () => import(/* webpackChunkName: "NearbyAirports" */ './NearbyAirports'),
  loading: NearbyLoading,
  modules: ['NearbyAirports'],
});

class PanelHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      valueFrom: '',
      valueTo: '',
      fromObject: {},
      toObject: {},
    };
  }

  updateValueFrom(value) {
    this.setState({
      valueFrom: value.fulltext,
      fromObject: value,
    });
    this.props.findRoutes(value.airportCode);

    // this.props.setRoute(`/${value.citySlug}-${value.airportCode}`);
  }

  updateValueTo(value) {
    if (value.is_connection) {
      const { fromObject } = this.state;
      window.open(`/fr/${fromObject.airportCode}-${value.airportCode}`, '_blank', 'noreferrer');
    }
    this.setState({
      valueTo: value.fulltext,
      toObject: value,
    });
    // this.props.setRoute(`/${value.citySlug}-${value.airportCode}`);
  }

  loadValuesTo(event) {
    const { fromObject } = this.state;
    if (event.target.value !== '') {
      this.props.findRoutes(fromObject.airportCode, event.target.value);
    } else {
      this.props.findRoutes(fromObject.airportCode);
    }
  }

  submitButton() {
    const { fromObject, toObject } = this.state;
    if (fromObject.airportCode) {
      if (toObject.airportCode) {
        const citySlug = toObject.cityName.toLowerCase()
          .trim()
          .replace(/[^\w\s-]/g, '')
          .replace(/[\s_-]+/g, '-')
          .replace(/^-+|-+$/g, '');
        this.props.setRoute(`/${fromObject.citySlug}-${fromObject.airportCode}/${citySlug}-${toObject.airportCode}`);
      } else {
        this.props.setRoute(`/${fromObject.citySlug}-${fromObject.airportCode}`);
      }
    }
  }

  componentDidMount() {
    if (!this.props.dataset.airports) return;
    this.props.setAirportMarkers();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.dataset.airports && this.props.dataset.airports) {
      this.props.setAirportMarkers();
    }
  }

  render() {
    const { dataset, arrivalRoutes, flightRoutes } = this.props;
    const { valueFrom, valueTo, fromObject } = this.state;

    let options = [];
    if (dataset.airports) {
      options = immutableSort(
        Object.keys(dataset.airports).map((k) => ({
          ...dataset.airports[k],
          airportCode: k,
          fulltext: `${dataset.airports[k].name} ${dataset.airports[k].cityName} ${k} {${dataset.countries[dataset.airports[k].countryCode].name}`,
        })),
        getSortFn('compareFloatDesc'),
        'countRoutes',
      );
    }

    let arrivalOptions = [];
    let suggest = false;
    if (valueFrom) {
      if (arrivalRoutes.length) {
        arrivalOptions = arrivalRoutes.map((item) => ({ ...item, fulltext: `${item.name} ${item.cityName} ${item.airportCode} {${dataset.countries[item.countryCode].name}` }));
        arrivalOptions = [{
          fulltext: 'Any destination', cityName: 'Any destination', is_any: true, from: `${fromObject.cityName} ${fromObject.name}`,
        }, ...arrivalOptions];
      }
      if (arrivalOptions.length === 0 && flightRoutes.length) {
        suggest = true;
        arrivalOptions = flightRoutes.map((item) => ({ ...item, fulltext: `${item.name} ${item.cityName} ${item.airportCode} {${dataset.countries[item.countryCode].name}` }));
      }
    }

    return (
      <div className="home-content">
        <SeoHelmet
          title="Direct Flights | Explore all non-stop flights from any airport"
          description="Direct flights lists all non-stop flights departing from +3,200 airports with +600 airlines and displays the route map in a 3D interactive visualization. 🌎"
          img="https://direct-flights.com/static/images/social-card/default.jpg"
          url="https://direct-flights.com/" />
        <div className="logo">
          <div className="img"></div>
          <div className="text">
            <div className="main">Direct<b>Flights</b></div>
            <div className="sub">Explore all non-stop flights from any airport</div>
          </div>
        </div>
        <div className="search">
          {dataset.airports ? (
              <div>
                  <div className="flex-row">
                      <div className="search-box">
                          <VirtualizedSelect
                              clearable
                              value={valueFrom}
                              onChange={this.updateValueFrom.bind(this)}
                              onValueClick={this.updateValueFrom.bind(this)}
                              searchable
                              optionHeight={60}
                              optionRenderer={({
                                key,
                                option,
                                focusedOption,
                                selectValue,
                                focusOption,
                                style,
                              }) => {
                                const className = ['line'];
                                if (option === focusedOption) className.push('is-focused');
                                if (option.disabled) className.push('is-disabled');
                                return (
                                      <div
                                          key={key}
                                          style={style}
                                          className={className.join(' ')}
                                          onClick={() => selectValue(option)}
                                          onMouseEnter={() => focusOption(option)}
                                      >
                                          <div className="line-left">
                                              <div className="line-title">{option.name}</div>
                                              <div className="line-subtitle">{option.cityName}, {option.countryCode} – {option.countRoutes} routes</div>
                                          </div>
                                          <div className="line-right">{option.airportCode}</div>
                                      </div>
                                );
                              }}
                              options={options}
                              placeholder="Flying from"
                              valueKey="fulltext"
                              labelKey="cityName"
                          />
                          <div className="search-icon"><div className="icon-search"></div></div>
                      </div>
                      <div className="search-box" onKeyUp={this.loadValuesTo.bind(this)}>
                          <VirtualizedSelect
                              clearable
                              value={valueTo}
                              onChange={this.updateValueTo.bind(this)}
                              onValueClick={this.updateValueTo.bind(this)}
                              searchable
                              suggest={suggest}
                              optionHeight={60}
                              optionRenderer={({
                                key,
                                option,
                                focusedOption,
                                selectValue,
                                focusOption,
                                style,
                              }) => {
                                const className = ['line'];
                                if (option === focusedOption) className.push('is-focused');
                                if (option.disabled) className.push('is-disabled');
                                return (
                                      <div
                                          key={key}
                                          style={style}
                                          className={className.join(' ')}
                                          onClick={() => selectValue(option)}
                                          onMouseEnter={() => focusOption(option)}
                                      >
                                          <div className="line-left">
                                          {option.is_any ? (
                                              <div className="line-title">Browse all destinations from</div>
                                          ) : (
                                              <div className="line-title">{option.name}</div>
                                          )}
                                            {option.is_connection ? (
                                                <div className="line-subtitle">{option.cityName}, {option.countryCode} – 1 stop</div>
                                            ) : (
                                                <>
                                                    {option.is_any ? (
                                                        <div className="line-subtitle">{option.from}</div>
                                                    ) : (
                                                        <div className="line-subtitle">{option.cityName}, {option.countryCode} – {option.countRoutes} routes</div>
                                                    )}
                                                </>
                                            )}
                                          </div>
                                          {option.is_any ? (
                                              <></>
                                          ) : (
                                              <div className="line-right">{option.airportCode}</div>
                                          )}
                                      </div>
                                );
                              }}
                              options={arrivalOptions}
                              placeholder="Flying to"
                              valueKey="fulltext"
                              labelKey="cityName"
                          />
                          <div className="search-icon"><div className="icon-search"></div></div>
                      </div>
                  </div>
                <div className="home-find-block">
                  <button className="find-flight" onClick={() => this.submitButton()}>Find Flight</button>
                </div>
              </div>
          ) : (
            <div className="Select is-clearable is-searchable Select--single">
              <div className="Select-control">
                <div className="Select-placeholder">Loading...</div>
              </div>
            </div>
          )}
        </div>
        <Fragment><NearbyAirports /></Fragment>
        <div className="home-footer">
          or explore list of all <Link to={'/airport-list'}>Airports</Link> - <Link to={'/airline-list'}>Airlines</Link><br/>
          Search flights <Link to={'/websites'}>without 3D globe</Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  dataset: state.dataset,
  arrivalRoutes: state.loadedRoutes,
  flightRoutes: state.flightRoutes,
});

const mapDispatchToProps = (dispatch) => ({
  setRoute: (r) => dispatch(push(r)),
  setAirportMarkers: () => dispatch(setAirportMarkers()),
  findRoutes: (a, b) => dispatch(Actions.findRoutes(a, b)),
});


const statefulPanelHome = connect(mapStateToProps, mapDispatchToProps);

export default statefulPanelHome(PanelHome);
