/* eslint class-methods-use-this: 0 */

import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

import { setModal } from '../state/actions';

const errorTitles = {
  404: <Fragment><span role="img" aria-label="satellite">🛰</span><br/>Out of orbit...</Fragment>,
  500: <Fragment><span role="img" aria-label="bug">👾</span><br/>Houston, we have a problem...</Fragment>,
};

const errorDescriptions = {
  404: 'This page is not found',
  500: 'It\'s not you - we sent an SOS to fix ASAP',
};

class PanelError extends Component {
  componentDidMount() {
    this.props.setModal(true);
  }

  render() {
    const { errorCode, staticContext = {} } = this.props;
    staticContext.status = errorCode;
    return (
      <div className="panel-modal">
        <div className="panel-modal-top">
          <div>
            <h1>{errorTitles[errorCode] ? errorTitles[errorCode] : 'Something went wrong...'}</h1>
            <p>{errorDescriptions[errorCode]} [signal {errorCode}]</p>
          </div>
        </div>
        <div className="panel-modal-bottom">
          <div>
            <NavLink to={'/'} onClick={() => this.props.setModal(false)}className="btn">Take me back home</NavLink>
          </div>
        </div>
      </div>
    );
  }
}


const mapDispatchToProps = (dispatch) => ({
  setModal: (v) => dispatch(setModal(v)),
});


const statefulPanelError = connect(null, mapDispatchToProps)(PanelError);

export default statefulPanelError;
