export const weekdays = {
  MON: 'Monday',
  TUE: 'Tuesday',
  WED: 'Wednesday',
  THU: 'Thursday',
  FRI: 'Friday',
  SAT: 'Saturday',
  SUN: 'Sunday',
};

export const routesPath = {
  allAirports: '/airport-list',
  allAirlines: '/airline-list',
  airlineSchedule: '/:departureCitySlug([a-z0-9-]{2,})-:departureCode([A-Za-z]{3})/:arrivalCitySlug([a-z0-9-]{2,})-:arrivalCode([A-Za-z]{3})/:airlineSlug([a-z0-9-]{2,})',
  airlineRoutes: '/:departureCitySlug([a-z0-9-]{2,})-:departureCode([A-Za-z]{3})/:airlineSlug([a-z0-9-]{2,})',
  airlineAirports: '/:airlineSlug([a-z0-9-]{2,})',
  routeSchedule: '/:departureCitySlug([a-z0-9-]{2,})-:departureCode([A-Za-z]{3})/:arrivalCitySlug([a-z0-9-]{2,})-:arrivalCode([A-Za-z]{3})',
  airportRoutes: '/:departureCitySlug([a-z0-9-]{2,})-:departureCode([A-Za-z]{3})',
  redirect: '/redirect/:flightCode([A-Z0-9-]{26})',
  redirectHotel: '/redirect/hotels/:arrivalCode([A-Za-z]{3})',
  redirectCar: '/redirect/cars/:arrivalCode([A-Za-z]{3})',
  redirectDeep: '/redirect/:flightCode(deep-[A-Za-z0-9-]{21,32})',
  redirectFr: '/fr/:departureCode([A-Za-z]{3})-:arrivalCode([A-Za-z]{3})',
  terms: '/terms',
  privacy: '/privacy',
  homepage: '/',
  alternatives: '/websites',
};

export const monthsAbvr = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];


export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
