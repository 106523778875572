import React, { Component, Fragment } from 'react';

import List from './List';

class PanelLoading extends Component {
  render() {
    const headerLayout = () => (
      <div className="panel-header">
        <div className="panel-nav">
          <div className="panel-back"><div className="loading-line loading-pink"></div></div>
        </div>
        <div className={`panel-header-info${(this.props.isLarge) ? ' panel-header-large' : ''}`}>
          <div className="loading-line"></div>
          <div className="loading-line"></div>
          <div className="loading-line"></div>
          <div className="loading-line shorter"></div>
          <div></div>
          <div className="loading-line"></div>
        </div>
      </div>
    );

    return (
      <Fragment>
        <div className="panel-logo" onClick={() => this.props.setRoute('/')}></div>
        <div className="panel is-loading">
          <List
            isLoading
            headerLayout={headerLayout}
          />
        </div>
      </Fragment>
    );
  }
}

export default PanelLoading;
