import { toSlug } from './index';

const mapFields = {
  dataset: {
    alliances: (d) => ({
      name: d[0],
    }),
    airlines: (d) => ({
      name: d[0],
      slug: toSlug(d[0]),
      airportCode: d[1],
      allianceSlug: d[2],
      countRoutes: d[3],
      countDomesticRoutes: d[4],
      countWeeklyFlights: d[5],
    }),
    countries: (d) => ({
      name: d[0],
    }),
    count: {
      routes: (d) => d,
    },
    airports: (d) => ({
      name: d[0],
      cityName: d[1],
      citySlug: toSlug(d[1]),
      countryCode: d[2],
      latitude: d[3],
      longitude: d[4],
      topAirlineCodes: d[5],
      countAirlines: d[6],
      countRoutes: d[7],
      countDomesticRoutes: d[8],
      countWeeklyFlights: d[9],
    }),
  },
  redirect: (d) => ({
    providerCode: d[0],
    departingDate: new Date(d[1]),
    departureCode: d[2],
    arrivalCode: d[3],
    deepLinkUrl: d[4],
  }),
  routes: (d) => ({
    arrivalCode: d[0],
    topAirlineCodes: d[1],
    countAirlines: d[2],
    countWeeklyFlights: d[3],
    distance: d[4],
    duration: d[5],
    medianPrice: d[6],
    minPrice: d[7],
  }),
  findroutes: {
    airports: (d) => ({
      name: d.name,
      cityName: d.city_name,
      countryCode: d.country_code,
      countRoutes: d.no_routes,
      airportCode: d.IATA,
    }),
    flightRoutes: (d) => ({
      name: d.name,
      cityName: d.city_name,
      countryCode: d.country_code,
      airportCode: d.IATA,
      is_connection: d.is_connection,
    }),
  },
  schedule: {
    route: (d) => ({
      arrivalCode: d[0],
      topAirlineCodes: d[1],
      countAirlines: d[2],
      countWeeklyFlights: d[3],
      distance: d[4],
      duration: d[5],
      medianPrice: d[6],
      minPrice: d[7],
    }),
    weeks: (d) => ({
      weekCode: d[0],
      countWeeklyFlights: d[1],
      medianPrice: d[2],
      minPrice: d[3],
    }),
    selected: (d) => d,
    flights: (d) => ({
      flightCode: d[0],
      departingWeekDay: d[1],
      departingDayTime: d[2],
      arrivalWeekDay: d[3],
      arrivalDayTime: d[4],
      airlineCode: d[5],
      flightNumber: d[6],
      airplaneName: d[7],
      latestPrice: d[8],
      providerCode: d[9],
    }),
  },
  airlineAirports: (d) => ({
    airportCode: d[0],
    countRoutes: d[1],
    countDomesticRoutes: d[2],
    countWeeklyFlights: d[3],
  }),
  airlineRoutes: (d) => ({
    arrivalCode: d[0],
    countWeeklyFlights: d[1],
    distance: d[2],
    duration: d[3],
    medianPrice: d[4],
    minPrice: d[5],
  }),
  airlineSchedule: {
    route: (d) => ({
      arrivalCode: d[0],
      countWeeklyFlights: d[1],
      distance: d[2],
      duration: d[3],
      medianPrice: d[4],
      minPrice: d[5],
    }),
    weeks: (d) => ({
      weekCode: d[0],
      countWeeklyFlights: d[1],
      medianPrice: d[2],
      minPrice: d[3],
    }),
    selected: (d) => d,
    flights: (d) => ({
      flightCode: d[0],
      departingWeekDay: d[1],
      departingDayTime: d[2],
      arrivalWeekDay: d[3],
      arrivalDayTime: d[4],
      airlineCode: d[5],
      flightNumber: d[6],
      airplaneName: d[7],
      latestPrice: d[8],
      providerCode: d[9],
    }),
  },
};

export default mapFields;
