/* eslint-disable react/no-unescaped-entities */

/* eslint max-len: 0 */
import React, { Component } from 'react';
import { connect } from 'react-redux';

const launchDate = 1569394800000; // Sep 25

class FloatingPrompt extends Component {
  constructor(props) {
    super(props);
    const visible = !props.isPreview && (
      (Date.now() > launchDate && Date.now() < launchDate + 24 * 3600 * 1000)
      || props.isVisible
    );
    this.state = {
      visible,
    };
  }

  hideNav() {
    this.setState({ visible: false });
  }

  render() {
    const { visible } = this.state;
    if (!visible) return null;
    return (
      <div className="floating-prompt">
        <span className="floating-prompt-close" onClick={() => this.setState({ visible: false })}>×</span>
        <p>
          We're live on Product Hunt today! <span role="img" aria-label="rocket">🚀</span><br />
          Our CEO wrote an extensive comment with details and motivation behind Direct Flights.
        </p>
        <a href="https://www.producthunt.com/posts/direct-flights" target="_blank" rel="nofollow noopener noreferrer">Tell Me More</a>
      </div>
    );
  }
}


const mapStateToProps = (state) => ({
  isVisible: (
    state.router.location.search.indexOf('producthunt') !== -1
  ),
  isPreview: state.router.location.search.indexOf('preview') !== -1,
});

const statefulFloatingPrompt = connect(mapStateToProps);

export default statefulFloatingPrompt(FloatingPrompt);
