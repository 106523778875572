export const primaryColor = '#2BA6C8';
export const secondaryColor = '#FD64A0';
export const secondaryDarkerColor = '#f80363';
export const backgroundColor = '#FCFCFC';
export const backgroundPrimaryColor = '#FAFDFF';
export const textDefaultColor = '#7D8D96';
export const textLightColor = '#96A4AC';
export const textExtralightColor = '#BEC4C7';
export const borderColor = '#E9E9E9';
export const dangerColor = '#D0021B';
export const successColor = '#3ABA7C';
export const globeColor = '#dedede';
export const globeStokeColor = '#c5c5c5';
export const waterColor = '#ecf7ff';
export const arcColor = '#777777';
export const flyerColor = '#2BA6C8';
export const markerColor = '#FD64A0';
export const markerHoverColor = '#f80363';
