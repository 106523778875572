export default {
  type: 'MultiPolygon',
  coordinates: [
    [
      [
        [108.04968049680497, 21.552483488134882],
        [106.71406714067143, 20.696404436444354],
        [105.88245882458824, 19.75176548285482],
        [105.66285662856632, 19.05891448564485],
        [106.42606426064259, 18.00487800618005],
        [107.36207362073623, 16.69731710167102],
        [108.26928269282695, 16.07913425704257],
        [108.8776887768878, 15.276885733957329],
        [109.33489334893352, 13.42581013740137],
        [109.20169201692016, 11.666767380073793],
        [108.36648366483666, 11.008645756157563],
        [107.2216722167222, 10.364415881558813],
        [106.40446404464046, 9.530910922509221],
        [105.15885158851592, 8.600163718237184],
        [104.79524795247954, 9.240920655506557],
        [105.0760507605076, 9.918143434734347],
        [104.33444334443345, 10.48596868808687],
        [103.49563495634959, 10.631832055920555],
        [103.09243092430927, 11.154509123991232],
        [102.5848258482585, 12.185971510815108],
        [101.68841688416887, 12.646135706957068],
        [100.83160831608319, 12.627034551645508],
        [100.97920979209795, 13.411918388083876],
        [100.09720097200972, 13.40670898208981],
        [100.01800018000182, 12.307524317343166],
        [99.47799477994784, 10.847154170341696],
        [99.15399153991541, 9.963291620016193],
        [99.22239222392227, 9.239184186841868],
        [99.87399873998743, 9.207927750877502],
        [100.28080280802811, 8.294545233252322],
        [100.46080460804609, 7.429783838238379],
        [101.0188101881019, 6.856749178891789],
        [101.62361623616238, 6.740405778357783],
        [102.14202142021423, 6.221201647616468],
        [102.37242372423725, 6.1274323397233985],
        [102.96282962829628, 5.524877713077132],
        [103.38043380433805, 4.854600808508081],
        [103.43803438034382, 4.180850966609668],
        [103.33363333633338, 3.7258961764617595],
        [103.4308343083431, 3.3820753808538058],
        [103.50283502835032, 2.7916760348603447],
        [103.8556385563856, 2.51557751717516],
        [104.24804248042483, 1.631714966849657],
        [104.23004230042301, 1.2931035772357689],
        [103.52083520835208, 1.2271177679776741],
        [102.57402574025741, 1.966853419134182],
        [101.389613896139, 2.7604195988959788],
        [101.27441274412746, 3.270941386313865],
        [100.69480694806947, 3.9394818222182124],
        [100.5580055800558, 4.767777375273752],
        [100.1980019800198, 5.313028535985353],
        [100.3060030600306, 6.040608906489055],
        [100.08640086400862, 6.464307260672598],
        [99.69039690396903, 6.848066835568346],
        [99.52119521195215, 7.34296040500405],
        [98.98838988389883, 7.9073127210272105],
        [98.50238502385025, 8.38310513515134],
        [98.34038340383404, 7.794442257822567],
        [98.14958149581497, 8.3501122305223],
        [98.25758257582578, 8.9735044811448],
        [98.55278552785529, 9.933771652716516],
        [98.45558455584558, 10.675243772537726],
        [98.76518765187654, 11.441026453664534],
        [98.42678426784266, 12.033162268322684],
        [98.50958509585098, 13.121928121081211],
        [98.1027810278103, 13.641132251822512],
        [97.77877778777787, 14.837559161791617],
        [97.5987759877599, 16.099971881018803],
        [97.16317163171635, 16.92826743407433],
        [96.50436504365047, 16.4264279899799],
        [95.37035370353703, 15.714475837458366],
        [94.80874808748086, 15.803035739357384],
        [94.18954189541898, 16.037459009090085],
        [94.53514535145354, 17.277297635676348],
        [94.32634326343265, 18.21325424594245],
        [93.54153541535419, 19.366269439294385],
        [93.6639366393664, 19.72745492154921],
        [93.07713077130774, 19.855953602736022],
        [92.36792367923681, 20.670357406474054],
        [92.08352083520839, 21.193034474544746],
        [92.02592025920262, 21.70181979329793],
        [91.8351183511835, 22.182821613416124],
        [91.41751417514178, 22.764538616086156],
        [90.4959049590496, 22.80447739537395],
        [90.58590585905858, 22.392934321843214],
        [90.27270272702725, 21.835527880478793],
        [89.8478984789848, 22.038694714247143],
        [89.70029700297005, 21.85636550445504],
        [89.41949419494193, 21.965763030330294],
        [89.03069030690307, 22.056059400894],
        [88.89028890288904, 21.691400981309812],
        [88.20988209882097, 21.703556261962618],
        [86.97506975069751, 21.495180022200216],
        [87.03267032670328, 20.743289090390903],
        [86.49986499864997, 20.151153275732753],
        [85.05985059850599, 19.479139902499014],
        [83.94023940239401, 18.30181414784147],
        [83.18783187831878, 17.671476022560213],
        [82.19422194221943, 17.01682733597336],
        [82.19062190621906, 16.556663139831386],
        [81.69381693816939, 16.310084589445893],
        [80.79020790207903, 15.952372044520445],
        [80.32580325803258, 15.898541515915156],
        [80.02340023400234, 15.13623177211771],
        [80.23220232202323, 13.835616742267419],
        [80.28620286202863, 13.005584720547205],
        [79.86139861398613, 12.055736360963607],
        [79.85779857798576, 10.357470006900058],
        [79.33939339393396, 10.308848884288835],
        [78.8857888578886, 9.546539140491404],
        [79.18819188191884, 9.21661009420093],
        [78.27738277382775, 8.93356570185702],
        [77.94257942579429, 8.252869985299853],
        [77.53937539375397, 7.966352655626551],
        [76.59256592565924, 8.898836328563277],
        [76.13176131761321, 10.300166540965407],
        [75.74655746557465, 11.309054835148345],
        [75.39735397353974, 11.781374311943111],
        [74.86454864548645, 12.741641483514826],
        [74.61614616146161, 13.99189892208922],
        [74.44334443344434, 14.61702764137641],
        [73.53253532535325, 15.99057435514355],
        [73.11853118531187, 17.928473384933838],
        [72.81972819728199, 19.20825079080791],
        [72.82332823328235, 20.420305918759183],
        [72.62892628926289, 21.356262529025287],
        [71.17451174511746, 20.757180839708397],
        [70.4689046890469, 20.876997177571766],
        [69.16569165691658, 22.089052305523055],
        [69.64449644496446, 22.450237787777866],
        [69.34929349293495, 22.842679705997057],
        [68.17568175681757, 23.69181288302883],
        [67.44487444874449, 23.94533730807308],
        [67.14607146071461, 24.664235335253352],
        [66.37206372063721, 25.424808610386094],
        [64.52884528845289, 25.23726999459994],
        [62.90522905229054, 25.21816883928838],
        [61.49761497614978, 25.077514877448763],
        [59.6147961479615, 25.379660425104248],
        [58.52398523985241, 25.610610757507573],
        [57.39717397173973, 25.739109438694385],
        [56.972369723697255, 26.96679278462784],
        [56.493564935649374, 27.143912588425877],
        [55.723157231572316, 26.965056315963153],
        [54.71514715147151, 26.48058155851558],
        [53.494734947349485, 26.81224707347073],
        [52.48312483124832, 27.581502691926914],
        [51.52191521915219, 27.866283552935528],
        [50.85230852308524, 28.814395443854437],
        [50.11430114301143, 30.148003378333783],
        [49.57789577895781, 29.986511792517916],
        [48.94068940689408, 30.316440838808376],
        [48.56628566285664, 29.927471857918576],
        [47.97587975879759, 29.9760929805298],
        [48.18468184681848, 29.535029939699385],
        [48.09468094680949, 29.30581607596075],
        [48.41508415084152, 28.552188675486747],
        [48.80748807488075, 27.689163749137492],
        [49.30069300693009, 27.46168635406353],
        [49.469894698947, 27.10918321513215],
        [50.15390153901541, 26.688957798277983],
        [50.21150211502115, 26.277414724747246],
        [50.11430114301143, 25.94401274112741],
        [50.24030240302403, 25.608874288842884],
        [50.528305283052845, 25.327566365163648],
        [50.661506615066145, 24.999373787537863],
        [50.80910809108093, 24.754531705817058],
        [50.74430744307443, 25.48211207632076],
        [51.014310143101426, 26.006525613056127],
        [51.28791287912881, 26.11418667026669],
        [51.59031590315905, 25.801622310623102],
        [51.608316083160844, 25.216432370623707],
        [51.38871388713889, 24.62776949329492],
        [51.57951579515796, 24.24574638706386],
        [51.7559175591756, 24.294367509675098],
        [51.79551795517955, 24.020005460654602],
        [52.57672576725767, 24.17802410914109],
        [53.40473404734047, 24.15197707917079],
        [54.00954009540095, 24.122457111871114],
        [54.69354693546936, 24.797943422434216],
        [55.438754387543895, 25.43870035970359],
        [56.07236072360723, 26.05514673566735],
        [56.360363603636046, 26.395494593945926],
        [56.486364863648646, 26.308671160711597],
        [56.392763927639294, 25.895391618516186],
        [56.259562595625965, 25.714798877388773],
        [56.39636396363966, 24.92470563495634],
        [56.846368463684655, 24.242273449734498],
        [57.40437404374043, 23.879351498814984],
        [58.135181351813515, 23.747379880298794],
        [58.72918729187293, 23.565050670506693],
        [59.17919179191793, 22.992016011160104],
        [59.44919449194492, 22.660350496204956],
        [59.80919809198093, 22.533588283682832],
        [59.80559805598057, 22.311320294602936],
        [59.441994419944194, 21.713975073950735],
        [59.283592835928374, 21.434403618936187],
        [58.86238862388626, 21.114893384633845],
        [58.487984879848796, 20.428988262082612],
        [58.034380343803434, 20.481082322023212],
        [57.825578255782574, 20.243186114961148],
        [57.667176671766725, 19.73613726487264],
        [57.78957789577896, 19.067596828968277],
        [57.69597695976961, 18.94430755377553],
        [57.23517235172352, 18.947780491104908],
        [56.60876608766088, 18.574439728197277],
        [56.511565115651166, 18.086492033420328],
        [56.284762847628485, 17.876379324993238],
        [55.66195661956621, 17.883325199651992],
        [55.269552695526954, 17.631537243272433],
        [55.27315273152732, 17.228676513065125],
        [54.79074790747907, 16.950841526715266],
        [54.239942399423995, 17.044610834608335],
        [53.570335703357046, 16.707735913659135],
        [53.109531095310956, 16.65043244772447],
        [52.3859238592386, 16.383016273362728],
        [52.19152191521917, 15.93848029520295],
        [52.169921699217014, 15.59813243692436],
        [51.172711727117274, 15.174434082740817],
        [49.574295742957446, 14.709060480604805],
        [48.67788677886779, 14.004054202742026],
        [48.23868238682388, 13.948487205472048],
        [47.939879398794005, 14.007527140071389],
        [47.353073530735315, 13.592511129211289],
        [46.715867158671585, 13.39976310743107],
        [45.87705877058772, 13.34766904749047],
        [45.62505625056252, 13.290365581555804],
        [45.40545405454054, 13.02642234452344],
        [45.14265142651428, 12.953490660606604],
        [44.98784987849879, 12.699966235562357],
        [44.49464494644948, 12.720803859538591],
        [44.17424174241742, 12.585359303693025],
        [43.483034830348316, 12.637453363633625],
        [43.22383223832239, 13.220906834968346],
        [43.25263252632527, 13.767894464344636],
        [43.08703087030872, 14.063094137341366],
        [42.89262892628926, 14.802829788497874],
        [42.60462604626048, 15.212636393363923],
        [42.80622806228064, 15.26125751597516],
        [42.701827018270194, 15.719685243452432],
        [42.82422824228243, 15.91243326523265],
        [42.78102781027812, 16.348286900069],
        [42.64782647826479, 16.775458191581905],
        [42.34902349023491, 17.0758672705727],
        [42.26982269822699, 17.475255063450632],
        [41.75501755017552, 17.83296760837608],
        [41.22221222212224, 18.671681973419723],
        [40.93780937809379, 19.48608577715777],
        [40.246602466024655, 20.175463837038365],
        [39.80019800198002, 20.338691891518906],
        [39.1377913779138, 21.29201318843188],
        [39.02259022590226, 21.986600654306542],
        [39.065790657906575, 22.580472937629366],
        [38.49338493384934, 23.688339945699454],
        [38.02538025380255, 24.079045395253942],
        [37.48537485374854, 24.285685166351655],
        [37.154171541715414, 24.858719825698245],
        [37.20817208172082, 25.084460752107518],
        [36.9309693096931, 25.60366488284882],
        [36.63936639366395, 25.825932871928714],
        [36.250562505625055, 26.570877929079288],
        [35.638556385563874, 27.37659938949389],
        [35.13095130951311, 28.06250451204511],
        [34.63054630546307, 28.059031574715746],
        [34.78894788947889, 28.607755672756724],
        [34.83214832148323, 28.956785874358744],
        [34.95454954549547, 29.35617366723666],
        [34.92214922149222, 29.502037035070344],
        [34.641346413464134, 29.099176304863036],
        [34.425344253442546, 28.343812435724345],
        [34.15534155341555, 27.822871836318356],
        [33.921339213392145, 27.649224969849698],
        [33.586535865358655, 27.972208141481403],
        [33.13653136531366, 28.418480588305883],
        [32.42372423724237, 29.851067236672364],
        [32.31932319323195, 29.760770866108658],
        [32.73332733327334, 28.70499791797917],
        [33.34893348933491, 27.69958256112561],
        [34.10494104941051, 26.14197016890168],
        [34.47214472144722, 25.598455476854767],
        [34.79614796147962, 25.034103160831606],
        [35.69255692556925, 23.92623615276152],
        [35.49455494554945, 23.75258928629286],
        [35.5269552695527, 23.103150005700044],
        [36.68976689766899, 22.20539570605706],
        [36.86616866168663, 22.000492403624037],
        [37.190171901719026, 21.019387608076073],
        [36.97056970569707, 20.837058398283972],
        [37.114571145711466, 19.8073324801248],
        [37.481774817748175, 18.61437850748507],
        [37.86337863378634, 18.367799957099564],
        [38.41058410584105, 17.99793213152131],
        [38.990189901899015, 16.84144400084],
        [39.267392673926736, 15.922852077220767],
        [39.81459814598148, 15.434904382443818],
        [41.1790117901179, 14.490265428854286],
        [41.73341733417334, 13.92070370683706],
        [42.27702277022772, 13.344196110161093],
        [42.59022590225902, 13.000375314553139],
        [43.079830798307995, 12.699966235562357],
        [43.31743317433174, 12.390874813248132],
        [43.28503285032852, 11.97412233372333],
        [42.71622716227162, 11.736226126661265],
        [43.14463144631446, 11.461864077640769],
        [43.472234722347224, 11.277798399183993],
        [43.666636666366685, 10.864518856988568],
        [44.11664116641168, 10.446029908799076],
        [44.613446134461356, 10.442556971469713],
        [45.55665556655566, 10.69781786517865],
        [46.64386643866439, 10.815897734377344],
        [47.52587525875259, 11.126725625356244],
        [48.022680226802265, 11.192711434614338],
        [48.37908379083791, 11.37504064440644],
        [48.94788947889481, 11.409770017700168],
        [49.26829268292684, 11.430607641676417],
        [49.72909729097293, 11.578207478174775],
        [50.25830258302585, 11.678922660726599],
        [50.73350733507337, 12.022743456334553],
        [51.11151111511117, 12.024479924999241],
        [51.13311133111333, 11.74838140731407],
        [51.04311043110431, 11.166664404644038],
        [51.046710467104674, 10.640514399243983],
        [50.83430834308345, 10.279328916989158],
        [50.553505535055365, 9.199245407554073],
        [50.07110071100712, 8.080959587495869],
        [49.45189451894521, 6.804655118951189],
        [48.59508595085953, 5.339075565955653],
        [47.74187741877421, 4.219053277232774],
        [46.564665646656465, 2.855925375453751],
        [45.56385563855639, 2.0449945090450825],
        [44.069840698407006, 1.0534709015090158],
        [43.13743137431376, 0.29289762637625927],
        [42.04302043020431, -0.9191575015750146],
        [41.81261812618126, -1.4470439756397582],
        [41.58581585815858, -1.6832037140371483],
        [40.883808838088385, -2.0825915069150795],
        [40.63900639006391, -2.499343986439868],
        [40.264602646026475, -2.572275670356703],
        [40.120601206012054, -3.277281948219482],
        [39.80019800198002, -3.681879147091479],
        [39.60579605796059, -4.346946645666463],
        [39.20259202592027, -4.676875691956923],
        [38.74178741787418, -5.909768443884445],
        [38.79938799387995, -6.475857228572295],
        [39.44019440194404, -6.840515648156483],
        [39.468994689946896, -7.099249479194796],
        [39.19539195391954, -7.703540574505752],
        [39.25299252992531, -8.007422590825911],
        [39.18819188191884, -8.484951473614743],
        [39.53739537395376, -9.111816661566621],
        [39.95139951399514, -10.098130863108636],
        [40.315003150031515, -10.316925914859155],
        [40.4770047700477, -10.76493483034831],
        [40.43740437404375, -11.761667843878442],
        [40.55980559805599, -12.638584519545205],
        [40.599405994059936, -14.201406317763187],
        [40.775807758077576, -14.69109048120481],
        [40.4770047700477, -15.40651557105572],
        [40.088200882008834, -16.10110303693037],
        [39.450994509945104, -16.721022350223507],
        [38.536585365853654, -17.101308987789878],
        [37.40977409774098, -17.58578374523745],
        [36.2829628296283, -18.65892138001381],
        [35.897758977589774, -18.842987058470584],
        [35.19935199351994, -19.55320274232743],
        [34.785347853478555, -19.784153074730753],
        [34.70254702547027, -20.49784169591696],
        [35.177751777517784, -21.25494203372034],
        [35.37215372153722, -21.840131973719735],
        [35.38655386553867, -22.14054105271053],
        [35.56295562955631, -22.09018346143462],
        [35.53415534155343, -23.07128825698257],
        [35.37215372153722, -23.534925390453907],
        [35.606156061560625, -23.70683578825789],
        [35.45855458554587, -24.12185179911799],
        [35.04095040950409, -24.47782787537875],
        [34.21654216542166, -24.816439264992653],
        [33.01413014130142, -25.35821748837489],
        [32.57492574925749, -25.728085313953144],
        [32.66132661326614, -26.14831073080731],
        [32.916929169291706, -26.216033008730093],
        [32.83052830528305, -26.74218301413014],
        [32.57852578525785, -27.46976338463385],
        [32.463324633246344, -28.301531875018753],
        [32.204122041220415, -28.753013727837285],
        [31.52011520115201, -29.25658964059641],
        [31.325713257132577, -29.402453008430086],
        [30.9009090090901, -29.90950185851859],
        [30.62370623706238, -30.423496583265838],
        [30.05490054900551, -31.140658141781422],
        [28.92448924489247, -32.17212052860529],
        [28.218882188821908, -32.77120221792218],
        [27.462874628746306, -33.22615700807008],
        [26.41886418864189, -33.61512598895989],
        [25.911259112591125, -33.66722004890049],
        [25.78165781657816, -33.945055035250356],
        [25.173251732517343, -33.79745519875199],
        [24.67644676446764, -33.98673028320283],
        [23.59283592835928, -33.79398226142262],
        [22.988029880298797, -33.91727153661537],
        [22.57402574025741, -33.863441008010085],
        [21.544415444154453, -34.25935586355864],
        [20.68760687606877, -34.41737451204512],
        [20.072000720007196, -34.79592468094681],
        [19.6147961479615, -34.81849877358774],
        [19.193591935919358, -34.462522697326975],
        [18.855188551885533, -34.44515801068011],
        [18.423184231842328, -33.997149095190956],
        [18.37638376383765, -34.136066588365885],
        [18.243182431824323, -33.866913945339455],
        [18.25038250382505, -33.28172400534006],
        [17.926379263792654, -32.61144710077101],
        [18.246782467824687, -32.429117890978915],
        [18.221582215822167, -31.66159874118741],
        [17.566375663756645, -30.725642130921315],
        [17.065970659706608, -29.87824542255423],
        [17.062370623706244, -29.87650895388954],
        [16.34596345963459, -28.575893924039242],
        [15.60075600756008, -27.82053005490055],
        [15.211952119521214, -27.09121321573216],
        [14.98874988749887, -26.11705429484295],
        [14.743947439474397, -25.39294686166862],
        [14.409144091440908, -23.85269915609156],
        [14.383943839438388, -22.65627224612247],
        [14.257942579425787, -22.111021085410854],
        [13.869138691386922, -21.699478011880117],
        [13.350733507335093, -20.87291892748928],
        [12.825128251282507, -19.673019080190812],
        [12.609126091260919, -19.04615389223892],
        [11.795517955179548, -18.068522034020347],
        [11.734317343173444, -17.30273935289354],
        [11.640716407164092, -16.672401227612283],
        [11.777517775177756, -15.793748083280832],
        [12.12312123121231, -14.878629096990977],
        [12.177121771217713, -14.449721336813369],
        [12.50112501125011, -13.548494099841008],
        [12.738727387273883, -13.13868749497496],
        [13.311133111331117, -12.484038808388092],
        [13.635136351363514, -12.039502830228301],
        [13.739537395373958, -11.298030710407104],
        [13.685536855368554, -10.731941925719255],
        [13.386733867338677, -10.374229380793807],
        [13.120331203312048, -9.766465348153488],
        [12.875528755287547, -9.167383658836599],
        [12.929529295292951, -8.959007419074197],
        [13.235532355323556, -8.563092563525643],
        [12.933129331293316, -7.595879517295174],
        [12.72792727927279, -6.927339081390812],
        [12.227522275222753, -6.295264487444882],
        [12.321123211232106, -6.100779996999975],
        [12.180721807218077, -5.789952106021062],
        [11.914319143191449, -5.038061174211748],
        [11.09351093510935, -3.978815288752898],
        [10.067500675006755, -2.9699269945699456],
        [9.405094050940505, -2.145104378843797],
        [8.796687966879688, -1.111905523355233],
        [8.829088290882908, -0.7785035397353965],
        [9.048690486904889, -0.4589933054330544],
        [9.289892898928997, 0.26858706507064767],
        [9.491494914949158, 1.010059184891844],
        [9.304293042930425, 1.1611319587195794],
        [9.649896498965006, 2.28462718477185],
        [9.793897938979399, 3.072983958539581],
        [9.405094050940505, 3.734578519785188],
        [8.947889478894808, 3.9047524489244836],
        [8.746287462874648, 4.352761364413638],
        [8.487084870848719, 4.495151794917945],
        [8.501485014850147, 4.771250312603115],
        [7.461074610746124, 4.4118012990129785],
        [7.083070830708323, 4.463895358953579],
        [6.6978669786697935, 4.239890901209009],
        [5.898658986589879, 4.262464993849932],
        [5.36225362253623, 4.887593713137122],
        [5.034650346503469, 5.611701146311461],
        [4.3254432544325425, 6.269822770227691],
        [3.5730357303573044, 6.257667489574885],
        [2.691026910269102, 6.259403958239574],
        [1.8666186661866675, 6.141324089040879],
        [1.0602106021060251, 5.929474911949114],
        [-0.5094050940509476, 5.344284971949719],
        [-1.0638106381063608, 5.000464176341765],
        [-1.9638196381963837, 4.710473909339086],
        [-2.85662856628565, 4.9952547703476995],
        [-3.3102331023310114, 4.984835958359582],
        [-4.008640086400845, 5.179320448804489],
        [-4.649446494464939, 5.168901636816358],
        [-5.833858338583383, 4.993518301683011],
        [-6.528665286652853, 4.705264503345035],
        [-7.518675186751864, 4.338869615096144],
        [-7.713077130771296, 4.364916645066444],
        [-7.975879758797589, 4.356234301743015],
        [-9.005490054900548, 4.832026715867158],
        [-9.91269912699127, 5.594336459664589],
        [-10.76590765907659, 6.141324089040879],
        [-11.439114391143903, 6.785553963639629],
        [-11.709117091170896, 6.860222116221152],
        [-12.429124291242914, 7.2630828464284605],
        [-12.947529475294743, 7.797915195151944],
        [-13.123931239312384, 8.164310083400835],
        [-13.24633246332462, 8.902309265892654],
        [-13.685536855368554, 9.494445080550804],
        [-14.074340743407419, 9.886886998769981],
        [-14.329943299432983, 10.015385679956793],
        [-14.57834578345782, 10.215079576395752],
        [-14.693546935469357, 10.656142617226166],
        [-14.841148411484113, 10.876674137641373],
        [-15.129151291512898, 11.039902192121914],
        [-15.665556655566547, 11.458391140311392],
        [-16.08676086760866, 11.524376949569486],
        [-16.31356313563134, 11.805684873248723],
        [-16.309963099630977, 11.958494115741146],
        [-16.612366123661218, 12.170343292832925],
        [-16.677166771667714, 12.385665407254066],
        [-16.842768427684263, 13.151448088380874],
        [-16.713167131671298, 13.594247597875977],
        [-17.12717127171271, 14.37392202832028],
        [-17.623976239762385, 14.72989810458104],
        [-17.18477184771848, 14.91917318903188],
        [-16.702367023670234, 15.620706529565297],
        [-16.46476464764646, 16.13470125431253],
        [-16.551165511655114, 16.67474300903008],
        [-16.27036270362703, 17.166163641136407],
        [-16.147961479614793, 18.10906612606125],
        [-16.2559625596256, 19.097116796267954],
        [-16.378363783637838, 19.593746834368332],
        [-16.277562775627757, 20.092113341133413],
        [-16.536765367653658, 20.56790575525754],
        [-17.062370623706244, 21.000286452764527],
        [-17.019170191701903, 21.42224833828338],
        [-16.972369723697227, 21.88588547175472],
        [-16.590765907659062, 22.158511052110512],
        [-16.2631626316263, 22.679451651516516],
        [-16.327963279632797, 23.018063041130404],
        [-15.982359823598216, 23.723069318993183],
        [-15.424354243542439, 24.35861685026849],
        [-15.08955089550895, 24.520108436084357],
        [-14.823148231482321, 25.103561907419063],
        [-14.801548015480137, 25.636657787477873],
        [-14.441544415444156, 26.25484063210631],
        [-13.775537755377542, 26.61949905169051],
        [-13.13833138331384, 27.640542626526255],
        [-13.12033120331202, 27.65443437584375],
        [-12.619926199261982, 28.038193950739497],
        [-11.68751687516874, 28.149327945279452],
        [-10.902709027090253, 28.831760130501294],
        [-10.398703987039852, 29.099176304863036],
        [-9.563495634956354, 29.934417732577316],
        [-9.815498154981555, 31.177729296492956],
        [-9.43389433894339, 32.03728128551285],
        [-9.30069300693006, 32.56516775957759],
        [-8.656286562865631, 33.24065407014069],
        [-7.655476554765528, 33.69734532895329],
        [-6.913869138691382, 34.1106248711487],
        [-6.244262442624432, 35.14556019530194],
        [-5.931059310593099, 35.76027010260103],
        [-5.193051930519289, 35.75506069660696],
        [-4.591845918459171, 35.33136234242342],
        [-3.6414364143641365, 35.3990846203462],
        [-2.6046260462604494, 35.178553099930994],
        [-2.1690216902169084, 35.16813428794288],
        [-1.2078120781207815, 35.715121917319166],
        [-0.1278012780127824, 35.888768783787825],
        [0.5022050220502194, 36.30204832598325],
        [1.4670146701467104, 36.60593034230341],
        [3.1626316263162835, 36.78305014610146],
        [4.815048150481516, 36.86466417334172],
        [5.319053190531918, 36.717064336843364],
        [6.262262622626224, 37.11124272372723],
        [7.331473314733159, 37.118188598385984],
        [7.738277382773845, 36.88550179731797],
        [8.422284222842222, 36.946278200582],
        [9.50949509495095, 37.349138930789294],
        [10.211502115021148, 37.229322592925925],
        [10.179101791017928, 36.724010211502105],
        [11.028710287102882, 37.092141568415684],
        [11.100711007110078, 36.899393546635466],
        [10.600306003060041, 36.40970938319383],
        [10.593105931059313, 35.94780871838718],
        [10.938709387093866, 35.69949369933698],
        [10.80910809108093, 34.83299583565835],
        [10.150301503015044, 34.33115639156391],
        [10.341103411034112, 33.78590523085231],
        [10.855908559085606, 33.768540544205436],
        [11.107911079110806, 33.29274813008129],
        [11.489514895148972, 33.13646595025949],
        [12.663126631266323, 32.79264515465154],
        [13.084330843308436, 32.87946858788587],
        [13.919539195391962, 32.71276759607595],
        [15.244352443524434, 32.264758680586795],
        [15.712357123571252, 31.37568672426724],
        [16.612366123661246, 31.18293870248702],
        [18.019980199802006, 30.762713285632856],
        [19.085590855908578, 30.266083247532464],
        [19.575195751957523, 30.526553547235466],
        [20.054000540005404, 30.984981274712737],
        [19.819998199981995, 31.752500424504234],
        [20.13320133201333, 32.238711650616494],
        [20.85320853208532, 32.7075581900819],
        [21.544415444154453, 32.84300274592745],
        [22.894428944289444, 32.638099443494426],
        [23.23643236432366, 32.19182699666996],
        [23.6108361083611, 32.186617590675894],
        [23.927639276392767, 32.01644366153661],
        [24.921249212492143, 31.900100261002606],
        [25.166051660516615, 31.56843474604746],
        [26.49446494464945, 31.585799432694316],
        [27.459274592745942, 31.32185619566195],
        [28.449284492844924, 31.024920054000532],
        [28.913689136891378, 30.87037434284342],
        [29.684096840968408, 31.1864116398164],
        [30.094500945009457, 31.472928969489686],
        [30.97650976509766, 31.556279465394653],
        [31.68931689316895, 31.42951725287253],
        [31.959319593195943, 30.932887214772137],
        [32.19332193321935, 31.261079792397922],
        [32.992529925299266, 31.024920054000532],
        [33.77373773737739, 30.96761658806588],
        [34.2669426694267, 31.21940454444544],
        [34.55494554945551, 31.5493335907359],
        [34.48654486544865, 31.604900588005876],
        [34.75294752947531, 32.073747127471265],
        [34.95454954549547, 32.82737452794527],
        [35.09855098550986, 33.08089895298953],
        [35.127351273512744, 33.091317764977646],
        [35.48375483754839, 33.90572156871568],
        [35.98055980559806, 34.610727846578456],
        [35.998559985599854, 34.6454572198722],
        [35.9049590495905, 35.40950343233432],
        [36.149761497614975, 35.821046505865056],
        [35.782557825578266, 36.27426482734826],
        [36.16056160561607, 36.65107852758527],
        [35.55215552155522, 36.56599156301563],
        [34.71334713347133, 36.795205426754265],
        [34.02574025740259, 36.22043429874299],
        [32.51012510125102, 36.10756383553834],
        [31.700117001170014, 36.64413265292653],
        [30.620106201062015, 36.67712555755557],
        [30.38970389703897, 36.263846015360144],
        [29.698496984969864, 36.144029677496775],
        [28.733687336873373, 36.67712555755557],
        [27.642876428764282, 36.65802440224401],
        [27.048870488704893, 37.65302094710947],
        [26.31806318063181, 38.208690919809186],
        [26.80406804068042, 38.984892412924125],
        [26.170461704617054, 39.46415776437763],
        [27.279272792727937, 40.419215529955295],
        [28.820088200882026, 40.459154309243075],
        [29.24129241292414, 41.219727584375846],
        [31.145711457114572, 41.08775596585966],
        [32.34812348123481, 41.735458777787784],
        [33.51453514535146, 42.01850317013171],
        [35.16695166951669, 42.04107726277263],
        [36.912969129691305, 41.33607098490984],
        [38.34938349383495, 40.94883847268471],
        [39.51219512195124, 41.103384183841825],
        [40.372603726037255, 41.01308781327813],
        [41.55341553415536, 41.5357648813488],
        [41.70461704617048, 41.96293617286173],
        [41.45261452614528, 42.64536835808357],
        [40.87660876608766, 43.01349971499715],
        [40.322203222032215, 43.128106646866456],
        [39.954999549995506, 43.43546160051599],
        [38.680586805868074, 44.2793853715537],
        [37.53937539375394, 44.657935540455384],
        [36.67536675366753, 45.244861949119496],
        [37.40257402574028, 45.404617066270646],
        [38.23418234182341, 46.24159496264963],
        [37.67257672576727, 46.63577334953348],
        [39.148591485914864, 47.04557995439953],
        [39.11979119791198, 47.26263853748537],
        [38.22338223382235, 47.102883420334194],
        [37.424174241742435, 47.023005861758605],
        [36.75816758167582, 46.69828622146221],
        [35.822158221582214, 46.64619216152161],
        [34.961749617496196, 46.272851398613966],
        [35.019350193501936, 45.65119561665617],
        [35.50895508955091, 45.40982647226471],
        [36.53136531365314, 45.470602875528755],
        [36.33336333363334, 45.11289033060331],
        [35.23895238952392, 44.93924346413465],
        [33.88173881738817, 44.36099939879398],
        [33.327333273332755, 44.564166232562314],
        [33.54693546935471, 45.034749240692406],
        [32.45252452524525, 45.328212445024434],
        [32.63252632526326, 45.51922399813998],
        [33.586535865358655, 45.850889513095126],
        [33.29853298532987, 46.08010337683376],
        [31.743317433174326, 46.33362780187801],
        [31.674916749167494, 46.706968564785655],
        [30.74970749707498, 46.58367928959288],
        [30.378903789037906, 46.03321872288723],
        [29.604896048960484, 45.29348307173072],
        [29.626496264962668, 45.034749240692406],
        [29.14049140491406, 44.81942712627125],
        [28.838088380883818, 44.91319643416435],
        [28.557285572855733, 43.708087180871814],
        [28.038880388803904, 43.2930711700117],
        [27.67527675276753, 42.577646080160804],
        [27.995679956799563, 42.00808435814358],
        [28.114481144811464, 41.62258831458314],
        [28.989289892898938, 41.299605142951435],
        [28.80568805688057, 41.0547630612306],
        [27.617676176761762, 40.999196063960625],
        [27.192871928719285, 40.6901046416464],
        [26.357663576635787, 40.15179935559354],
        [26.044460444604454, 40.617172957729565],
        [26.055260552605546, 40.82381272882728],
        [25.4468544685447, 40.853332696126955],
        [24.924849248492478, 40.947102004020024],
        [23.715237152371543, 40.68663170431702],
        [24.40644406444065, 40.12575232562324],
        [23.898838988389883, 39.96252427114271],
        [23.34443344433444, 39.96078780247802],
        [22.81522815228152, 40.47651899588996],
        [22.628026280262816, 40.25598747547474],
        [22.851228512285132, 39.65864225482255],
        [23.35163351633517, 39.18979571535715],
        [22.97362973629737, 38.97100066360662],
        [23.531635316353174, 38.51083646746466],
        [24.024840248402484, 38.220846200462006],
        [24.03924039240394, 37.65475741577416],
        [23.114031140311397, 37.92043712147121],
        [23.409234092340938, 37.40991533405334],
        [22.775627756277572, 37.30572721417214],
        [23.153631536315373, 36.421864663846634],
        [22.491224912249123, 36.40970938319383],
        [21.670416704167053, 36.84556301803018],
        [21.296012960129616, 37.644338603786025],
        [21.119611196111975, 38.3111425710257],
        [20.730807308073082, 38.76957029850297],
        [20.216002160021617, 39.340868489184885],
        [20.15120151201512, 39.6256493501935],
        [19.978399783997844, 39.695108096780956],
        [19.960399603996052, 39.91563961719618],
        [19.40599405994061, 40.250778069480674],
        [19.319593195931958, 40.72657048360483],
        [19.402394023940246, 41.40900266882667],
        [19.53919539195394, 41.71983055980559],
        [19.369993699936998, 41.877849208292076],
        [19.161191611916138, 41.95425382953829],
        [18.880388803888053, 42.28070993849937],
        [18.448384483844848, 42.48040383493833],
        [17.508775087750877, 42.8502716605166],
        [16.929169291692915, 43.209720674106734],
        [16.014760147601493, 43.50665681576814],
        [15.175951759517602, 44.2429195295953],
        [15.377553775537763, 44.31758768217682],
        [14.920349203492037, 44.73781309903097],
        [14.902349023490245, 45.076424488644875],
        [14.257942579425787, 45.234443137131365],
        [13.95193951939521, 44.80206243962439],
        [13.65673656736567, 45.13720089190892],
        [13.678336783367854, 45.484494624846235],
        [13.714337143371438, 45.50012284282843],
        [13.937539375393754, 45.590419213392124],
        [13.141931419314204, 45.736282581225794],
        [12.328323283232834, 45.38204297362972],
        [12.382323823238238, 44.88541293552936],
        [12.259922599226002, 44.600632074520746],
        [12.587525875258763, 44.09184675576756],
        [13.527135271352734, 43.58827084300842],
        [14.031140311403135, 42.761711758617565],
        [15.143551435514354, 41.95599029820298],
        [15.924759247592476, 41.96119970419704],
        [16.169561695616977, 41.74066818378182],
        [15.888758887588892, 41.54097428734286],
        [16.785167851678523, 41.17978880508804],
        [17.51957519575197, 40.87764325743257],
        [18.37638376383765, 40.354966189361875],
        [18.480784807848096, 40.169164042240425],
        [18.293582935829363, 39.81145149731498],
        [17.73917739177392, 40.276825099450974],
        [16.86796867968681, 40.44178962259622],
        [16.450364503645034, 39.79582327933278],
        [17.170371703717052, 39.42421898508985],
        [17.05157051570515, 38.90327838568385],
        [16.633966339663402, 38.84423845108449],
        [16.101161011610117, 37.986422930729304],
        [15.683556835568368, 37.908281840818404],
        [15.687156871568732, 38.21390032580325],
        [15.892358923589256, 38.750469143191424],
        [16.108361083610845, 38.96405478894789],
        [15.719557195571952, 39.54403532295322],
        [15.413554135541375, 40.04761123571234],
        [14.999549995499962, 40.1726369795698],
        [14.704347043470449, 40.60501767707677],
        [14.05994059940599, 40.785610418204186],
        [13.627936279362814, 41.18847114841148],
        [12.88632886328864, 41.25272048900487],
        [12.105121051210517, 41.70420234182342],
        [11.190711907119066, 42.355378091080894],
        [10.510305103051024, 42.931885687756875],
        [10.200702007020084, 43.919936357963564],
        [9.70389703897041, 44.036279758497585],
        [8.89028890288904, 44.366208804788045],
        [8.42948429484295, 44.23076424894248],
        [7.849878498784989, 43.76712711547114],
        [7.435874358743604, 43.694195431554306],
        [6.528665286652881, 43.128106646866456],
        [4.555845558455587, 43.39899575855759],
        [3.101431014310151, 43.076012586925856],
        [2.986229862298643, 42.4734579602796],
        [3.0402304023040188, 41.89174095760956],
        [2.093420934209348, 41.22667345903457],
        [0.8118081180811885, 41.01482428194282],
        [0.7218072180722004, 40.67794936099361],
        [0.1062010620106264, 40.12401585695855],
        [-0.27900279002790285, 39.30961205322052],
        [0.10980109801099047, 38.73831386253863],
        [-0.46620466204660715, 38.29204141571415],
        [-0.682206822068224, 37.64260213512135],
        [-1.4382143821438262, 37.44290823868238],
        [-2.147421474214724, 36.67365262022619],
        [-3.414634146341456, 36.6597608709087],
        [-4.3686436864368545, 36.67712555755557],
        [-4.995049950499492, 36.32462241862417],
        [-5.3766537665376575, 35.94607224972249],
        [-5.866258662586631, 36.02942274562746],
        [-6.237062370623704, 36.368034135241345],
        [-6.521465214652153, 36.94280526325262],
        [-7.453874538745367, 37.097350974409736],
        [-7.8570785707856885, 36.83861714337142],
        [-8.382683826838274, 36.97927110521104],
        [-8.89748897488974, 36.8681371106711],
        [-8.74628746287462, 37.65128447844478],
        [-8.839888398883971, 38.26599438574385],
        [-9.286292862928633, 38.35802722497223],
        [-9.527495274952742, 38.736577393873944],
        [-9.448294482944817, 39.3912260804608],
        [-9.04869048690486, 39.755884500045],
        [-8.976689766897664, 40.15874523025229],
        [-8.767887678876775, 40.761299856898574],
        [-8.789487894878931, 41.1849982110821],
        [-8.991089910899092, 41.54271075600755],
        [-9.034290342903432, 41.88132214562145],
        [-8.983889838898392, 42.59327429814297],
        [-9.394293942939413, 43.02739146431463],
        [-7.979479794797953, 43.748025960159595],
        [-6.755467554675533, 43.56743321903218],
        [-5.41265412654127, 43.574379093690936],
        [-4.3470434704346985, 43.40420516455163],
        [-3.5190351903519, 43.45629922449223],
        [-1.9026190261902514, 43.4233063198632],
        [-1.384213842138422, 44.02238800918008],
        [-1.1934119341193252, 46.01411756757568],
        [-2.2266222662226482, 47.0646811097111],
        [-2.9646296462964585, 47.56999349113491],
        [-4.491044910449091, 47.955489534695346],
        [-4.591845918459171, 48.68480637386372],
        [-3.2958329583295836, 48.90186495694957],
        [-1.6182161821618024, 48.64486759457594],
        [-1.9350193501934996, 49.77704516395164],
        [-0.9882098820988006, 49.34813740377402],
        [1.337413374133746, 50.127811834218335],
        [1.6398163981639868, 50.94742504395043],
        [2.5146251462514613, 51.14885540905408],
        [3.313833138331404, 51.34507636816369],
        [3.828638286382869, 51.62117488584886],
        [4.707047070470708, 53.09196384483843],
        [6.07506075060752, 53.51045279302792],
        [6.906669066690682, 53.482669294392934],
        [7.101071010710115, 53.69451847148471],
        [7.936279362793641, 53.74834900009],
        [8.123481234812346, 53.52781747967478],
        [8.800288002880023, 54.020974580445795],
        [8.573485734857343, 54.3960518120181],
        [8.526685266852667, 54.96214059670595],
        [8.119881198811981, 55.5178105694057],
        [8.091080910809126, 56.54059061290613],
        [8.256682566825674, 56.809743255932545],
        [8.544685446854487, 57.11015233492333],
        [9.423094230942326, 57.17266520685206],
        [9.775897758977607, 57.44876372453723],
        [10.578705787057885, 57.730071648216466],
        [10.546305463054637, 57.21607692346922],
        [10.251102511025124, 56.889620814508135],
        [10.369903699036996, 56.61004935949359],
        [10.913509135091346, 56.45897658566585],
        [10.668706687066873, 56.08216288542886],
        [10.369903699036996, 56.18982394263941],
        [9.649896498965006, 55.46918944679447],
        [9.923499234992363, 54.98297822068221],
        [9.93789937899379, 54.597482177121776],
        [10.949509495094958, 54.363058907389075],
        [10.938709387093866, 54.008819299793004],
        [11.95751957519576, 54.19635791557914],
        [12.51912519125193, 54.47071996459965],
        [13.645936459364606, 54.074805109051084],
        [14.121141211412123, 53.757031343413416],
        [14.801548015480165, 54.05049454774547],
        [16.36396363963641, 54.51239521255212],
        [17.623976239762413, 54.851006602166024],
        [18.621186211862124, 54.6825691416914],
        [18.696786967869684, 54.43946352863529],
        [19.661596615966175, 54.42557177931778],
        [19.888398883988856, 54.86663482014819],
        [21.267212672126732, 55.1896179917799],
        [21.05481054810548, 56.031805294152946],
        [21.09081090810909, 56.783696225962245],
        [21.580415804158037, 57.41229788257883],
        [22.52362523625237, 57.75264574085739],
        [23.31923319233192, 57.005964215042155],
        [24.1220412204122, 57.0250653703537],
        [24.312843128431297, 57.7925845201452],
        [24.428044280442805, 58.38298386613866],
        [24.060840608406096, 58.257958122281224],
        [23.42723427234273, 58.612197729877295],
        [23.340833408334078, 59.18696885788856],
        [24.604446044460445, 59.466540312903135],
        [25.86445864458645, 59.610667212072116],
        [26.948069480694812, 59.44570268892687],
        [27.981279812798135, 59.47522265622655],
        [29.118891188911903, 60.02741969159692],
        [28.071280712807123, 60.50321210572105],
        [26.256862568625706, 60.42333454714546],
        [24.496444964449665, 60.05693965889657],
        [22.869228692286924, 59.846826950469506],
        [22.289622896228963, 60.39207811118109],
        [21.321213212132136, 60.72027068880689],
        [21.544415444154453, 61.704848421684204],
        [21.058410584105843, 62.60781212732127],
        [21.537215372153725, 63.189529129991286],
        [22.444424444244447, 63.81813078660787],
        [24.730447304473046, 64.90168723337231],
        [25.39645396453966, 65.1117999417994],
        [25.29565295652958, 65.53376182731827],
        [23.902439024390247, 66.00608130411302],
        [22.181621816218183, 65.7230369117691],
        [21.213212132121328, 65.02671297722978],
        [21.368013680136812, 64.41373953859537],
        [19.780397803978047, 63.609754546845465],
        [17.84717847178473, 62.75020255782556],
        [17.119971199712012, 61.34192647076469],
        [17.832778327783274, 60.636920192901925],
        [18.786787867878672, 60.08125022020221],
        [17.868778687786886, 58.954282056820574],
        [16.828368283682835, 58.71985878708787],
        [16.44676446764467, 57.04069358833587],
        [15.881558815588164, 56.10473697806978],
        [14.668346683466837, 56.20024275462754],
        [14.099540995409967, 55.40841304353043],
        [12.943929439294408, 55.361528389583896],
        [12.623526235262347, 56.307903811838116],
        [11.788317883178848, 57.441817849878504],
        [11.028710287102882, 58.85530334293341],
        [10.355503555035568, 59.47001325023248],
        [8.382683826838274, 58.3135251195512],
        [7.0470704707047105, 58.0791018498185],
        [5.6646566465664705, 58.58788716857168],
        [5.308253082530825, 59.662761272012716],
        [4.991449914499157, 61.97052812738127],
        [5.913059130591307, 62.61475800198002],
        [8.551885518855187, 63.453472367023664],
        [10.528305283052845, 64.48667122251223],
        [12.357123571235718, 65.8793190915909],
        [14.761947619476189, 67.81027224672246],
        [16.435964359643606, 68.56389964719645],
        [19.182791827918294, 69.81763002310024],
        [21.378813788137876, 70.25522012660124],
        [23.02403024030241, 70.20138959799598],
        [24.546845468454705, 71.0296851510515],
        [26.36846368463685, 70.98627343443434],
        [28.164881648816504, 71.1859673308733],
        [31.29331293312933, 70.45317755437554],
        [30.00450004500047, 70.18576138001379],
        [31.10251102511026, 69.55889619206192],
        [32.13212132121322, 69.90618992499924],
        [33.77373773737739, 69.3018988296883],
        [36.51336513365135, 69.06400262262622],
        [40.29340293402936, 67.93182505325052],
        [41.060210602106025, 67.45776910779108],
        [41.12501125011252, 66.7909651405514],
        [40.01620016200164, 66.26655160381603],
        [38.3817838178382, 65.9991354294543],
        [33.91773917739178, 66.75970870458704],
        [33.183331833318334, 66.63294649206492],
        [34.81414814148141, 65.90015671556714],
        [34.87894878948791, 65.43651958209583],
        [34.943749437494375, 64.41373953859537],
        [36.23256232562326, 64.1098575222752],
        [37.013770137701385, 63.849387222572204],
        [37.14337143371435, 64.3338619800198],
        [36.53856538565387, 64.76450620886209],
        [37.1757717577176, 65.14305637776377],
        [39.5949959499595, 64.52140059580594],
        [40.43380433804339, 64.76450620886209],
        [39.76419764197644, 65.49729598535984],
        [42.09342093420935, 66.47666431224312],
        [43.0150301503015, 66.41936084630845],
        [43.951039510395105, 66.06859417604176],
        [44.53064530645307, 66.75623576725766],
        [43.699036990369905, 67.35184451924519],
        [44.18864188641888, 67.95092620856207],
        [43.45423454234543, 68.5708455218552],
        [46.25146251462516, 68.24959881888819],
        [46.82026820268203, 67.6904559088591],
        [45.55665556655566, 67.56716663366632],
        [45.560255602556026, 67.00976019230191],
        [46.34866348663488, 66.66767586535863],
        [47.89307893078933, 66.88473444844448],
        [48.1378813788138, 67.52201844838447],
        [50.225902259022604, 67.99781086250863],
        [53.7179371793718, 68.8573628515285],
        [54.47034470344704, 68.80874172891728],
        [53.48753487534876, 68.20097769627696],
        [54.725947259472605, 68.09678957639576],
        [55.44235442354423, 68.43887390333902],
        [57.31797317973181, 68.466657401974],
        [58.80118801188013, 68.88167341283412],
        [59.94239942399426, 68.27911878618787],
        [61.07641076410766, 68.94071334743347],
        [60.028800288002884, 69.5206938814388],
        [60.55080550805508, 69.85062292772926],
        [63.50283502835029, 69.5467409114091],
        [64.8888488884889, 69.2341765517655],
        [68.51048510485106, 68.0915801704017],
        [69.180091800918, 68.61599370713705],
        [68.1648816488165, 69.14388018120181],
        [68.13608136081362, 69.35572935829359],
        [66.93006930069302, 69.45470807218072],
        [67.26127261272615, 69.92876401764016],
        [66.7248672486725, 70.70843844808448],
        [66.69606696066961, 71.0296851510515],
        [68.53928539285394, 71.93438532535325],
        [69.19809198091983, 72.84255843698435],
        [69.93969939699397, 73.04051586475865],
        [72.58932589325894, 72.77657262772627],
        [72.79452794527947, 72.22090265502655],
        [71.84771847718477, 71.40823531995318],
        [72.47052470524707, 71.09046155431554],
        [72.7909279092791, 70.39066468244681],
        [72.56412564125642, 69.02059090600906],
        [73.66933669336694, 68.40761746737468],
        [73.23733237332374, 67.740813500135],
        [71.2789127891279, 66.32038213242132],
        [72.42372423724237, 66.17278229592296],
        [72.81972819728199, 66.5322313095131],
        [73.92133921339214, 66.78922867188672],
        [74.18774187741877, 67.2841222413224],
        [75.05175051750518, 67.75991465544655],
        [74.46854468544686, 68.32947637746378],
        [74.93654936549368, 68.9893344700447],
        [73.84213842138422, 69.07094849728497],
        [73.60093600936011, 69.62835493864938],
        [74.40014400144003, 70.63203382683827],
        [73.10053100531007, 71.4464376305763],
        [74.88974889748897, 72.12192394113941],
        [74.65934659346595, 72.83213962499624],
        [75.15975159751599, 72.85471371763717],
        [75.68175681756819, 72.30078021360214],
        [75.28935289352896, 71.33530363603634],
        [76.35856358563586, 71.15297442624424],
        [75.90135901359014, 71.8736089220892],
        [77.57537575375756, 72.26778730897308],
        [79.6525965259653, 72.31988136891368],
        [81.49941499414996, 71.75031964689646],
        [80.61020610206106, 72.58208813728137],
        [80.50940509405098, 73.64827989739896],
        [82.24822248222483, 73.84971026250261],
        [84.65664656646567, 73.80629854588545],
        [86.8238682386824, 73.93653369573695],
        [86.01026010260102, 74.45921076380762],
        [87.16587165871658, 75.11559591905919],
        [88.31428314283141, 75.14337941769418],
        [90.25830258302585, 75.64000945579454],
        [92.90072900729007, 75.77371754297542],
        [93.23553235532358, 76.04634312333121],
        [95.85995859958598, 76.14011243122431],
        [96.67716677166771, 75.91610797347974],
        [98.9235892358924, 76.44746738487385],
        [100.75960759607597, 76.43010269822696],
        [101.03681036810372, 76.86248339573396],
        [101.99081990819911, 77.28791821858218],
        [104.35244352443527, 77.69772482344823],
        [106.06606066060664, 77.37474165181652],
        [104.70524705247055, 77.128163101431],
        [106.969669696697, 76.97361739027389],
        [107.23967239672396, 76.4804602895029],
        [108.15408154081541, 76.72356590255902],
        [111.07731077310774, 76.70967415324154],
        [113.33093330933309, 76.22172645846459],
        [114.13374133741337, 75.84838569555694],
        [113.88533885338853, 75.32744509615097],
        [112.78012780127801, 75.03224542315422],
        [110.1521015210152, 74.4765754504545],
        [109.39969399693996, 74.17963930879307],
        [110.6417064170642, 74.04072181561816],
        [112.11772117721176, 73.78719739057391],
        [113.02133021330212, 73.97647247502474],
        [113.52893528935289, 73.33571553775536],
        [113.96813968139685, 73.59444936879368],
        [115.56655566555668, 73.75246801728017],
        [118.77778777787779, 73.58750349413492],
        [119.0189901899019, 73.12039342333424],
        [123.20223202232023, 72.97105711817116],
        [123.25623256232564, 73.73510333063331],
        [125.38025380253805, 73.55971999549993],
        [126.97506975069751, 73.564929401494],
        [128.59148591485916, 73.03877939609396],
        [129.05229052290525, 72.39802245882458],
        [128.45828458284586, 71.9795335106351],
        [129.7146971469715, 71.19291320553205],
        [131.28791287912878, 70.78657953799538],
        [132.25272252722527, 71.8371430801308],
        [133.85833858338583, 71.38566122731226],
        [135.5611556115561, 71.6548138703387],
        [137.4979749797498, 71.34745891668916],
        [138.23238232382323, 71.6287668403684],
        [139.87039870398706, 71.48811287852877],
        [139.14679146791468, 72.41538714547144],
        [140.46800468004682, 72.8495043116431],
        [149.50049500495004, 72.20006503105031],
        [150.350103501035, 71.60619274772748],
        [152.96732967329672, 70.84214653526536],
        [157.00657006570066, 71.03142161971618],
        [158.99738997389977, 70.86645709657097],
        [159.8289982899829, 70.45317755437554],
        [159.710197101971, 69.72212424654245],
        [160.94140941409415, 69.43734338553384],
        [162.28062280622805, 69.64224668796686],
        [164.05184051840519, 69.66829371793716],
        [165.94185941859422, 69.47207275882758],
        [167.83547835478356, 69.58320675336753],
        [169.57789577895778, 68.69413479704795],
        [170.81630816308166, 69.01364503135031],
        [170.00990009900102, 69.65266549995499],
        [170.45270452704528, 70.09720147811478],
        [173.64233642336427, 69.81763002310024],
        [175.72315723157232, 69.87666995769956],
        [178.59958599585997, 69.39914107491074],
        [-180, 68.9632874400744],
        [-177.54837548375482, 68.19924122761228],
        [-174.92754927549277, 67.20598115141149],
        [-175.0139501395014, 66.5843253694537],
        [-174.34074340743408, 66.33601035040348],
        [-174.57114571145712, 67.06185425224251],
        [-171.85671856718568, 66.91251794707946],
        [-169.89829898298984, 65.97656133681335],
        [-170.8919089190892, 65.540707701977],
        [-172.529925299253, 65.43825605076049],
        [-172.55512555125551, 64.46062419254193],
        [-172.95472954729547, 64.25224795277953],
        [-173.89073890738908, 64.2817679200792],
        [-174.65394653946538, 64.63079812168121],
        [-175.98235982359824, 64.92252485734858],
        [-176.20556205562056, 65.35664202352024],
        [-177.22437224372243, 65.51987007800076],
        [-178.35838358383583, 65.39137139681395],
        [-178.9019890198902, 65.74040159841599],
        [-178.6859868598686, 66.11200589265891],
        [-179.8847988479885, 65.87410968559684],
        [-179.43119431194313, 65.40352667746677],
        [-180, 64.97982832328321],
        [-180, 64.97461891728918],
        [178.70758707587078, 64.53529234512345],
        [177.4115741157412, 64.60822402904029],
        [178.31158311583118, 64.07512814898149],
        [178.90918909189094, 63.25204200192002],
        [179.36999369993703, 62.98288935889357],
        [179.4851948519485, 62.56960981669815],
        [179.229592295923, 62.30393011100111],
        [177.3647736477365, 62.52272516275161],
        [174.56754567545676, 61.769097762277624],
        [173.67833678336785, 61.652754361743604],
        [172.14832148321483, 60.94948455254553],
        [170.69750697506976, 60.33651111391114],
        [170.33030330303302, 59.88155632376322],
        [168.90108901089013, 60.574407320973194],
        [166.2946629466295, 59.78778701587015],
        [165.84105841058414, 60.15939131011308],
        [164.87624876248765, 59.732220018600174],
        [163.54063540635406, 59.86940104311043],
        [163.2166321663217, 59.21127941919417],
        [162.01782017820182, 58.244066372963715],
        [162.0538205382054, 57.83946917409173],
        [163.19143191431914, 57.61546471634716],
        [163.05823058230584, 56.15856750667507],
        [162.12942129421293, 56.12210166471664],
        [161.70101701017012, 55.285123768337684],
        [162.1186211862119, 54.8544795394954],
        [160.3690036900369, 54.3439577520775],
        [160.02340023400234, 53.20309783937839],
        [158.52938529385295, 52.95825575765758],
        [158.23058230582308, 51.942421588815876],
        [156.79056790567904, 51.01167438454385],
        [156.419764197642, 51.69931597575976],
        [155.99135991359913, 53.15968612276123],
        [155.43335433354332, 55.38062954489544],
        [155.9157591575916, 56.76806800798008],
        [156.75816758167582, 57.36541322863229],
        [156.80856808568086, 57.83252329943298],
        [158.36378363783638, 58.05652775717758],
        [160.14940149401497, 59.31546753907537],
        [161.87381873818737, 60.34345698856987],
        [163.67023670236705, 61.14049610566104],
        [164.47304473044733, 62.5505086613866],
        [163.259832598326, 62.46542169681695],
        [162.65862658626588, 61.6423355497555],
        [160.12060120601205, 60.544887353673516],
        [159.30339303393038, 61.77430716827166],
        [156.72216722167224, 61.4339593099931],
        [154.21654216542169, 59.758267048570474],
        [155.04455044550446, 59.14529360993609],
        [152.8125281252813, 58.8830868415684],
        [151.26451264512644, 58.78063519035189],
        [151.33651336513367, 59.50474262352623],
        [149.78489784897852, 59.65581539735396],
        [148.54648546485464, 59.164394765247636],
        [145.48645486454865, 59.336305163051634],
        [142.19602196021964, 59.0393690213902],
        [138.95958959589598, 57.087578242282405],
        [135.12555125551256, 54.72945379563794],
        [136.70236702367026, 54.60269158311581],
        [137.1919719197192, 53.97756286382864],
        [138.16398163981643, 53.75529487474873],
        [138.8047880478805, 54.2553978501785],
        [139.90279902799028, 54.18941204092039],
        [141.34641346413463, 53.09022737617374],
        [141.3788137881379, 52.23935773047731],
        [140.59760597605975, 51.2391517796178],
        [140.5148051480515, 50.04619780697806],
        [140.06120061200613, 48.44691016680167],
        [138.55638556385566, 47.00043176911768],
        [138.21798217982183, 46.30758077190771],
        [136.8607686076861, 45.14414676656767],
        [135.51435514355143, 43.98939510455105],
        [134.869948699487, 43.39899575855759],
        [133.53793537935383, 42.812069349893505],
        [132.9079290792908, 42.798177600576],
        [132.27792277922782, 43.28438882668826],
        [130.9351093510935, 42.55333551885519],
        [130.78030780307802, 42.219933535235356],
        [130.39870398703988, 42.28070993849937],
        [129.9666996669967, 41.94209854888547],
        [129.66789667896683, 41.601750690606906],
        [129.7038970389704, 40.88285266342663],
        [129.18909189091892, 40.66232114301141],
        [129.00909009090094, 40.485201339213376],
        [128.63468634686348, 40.19000166621666],
        [127.96867968679686, 40.025037143071415],
        [127.53307533075332, 39.75762096870969],
        [127.5006750067501, 39.32350380253803],
        [127.38547385473856, 39.21410627666276],
        [127.78507785077852, 39.050878222182206],
        [128.35028350283505, 38.61155165001648],
        [129.21429214292147, 37.43248942669426],
        [129.45909459094594, 36.78478661476615],
        [129.46989469894697, 35.631771421414214],
        [129.0918909189092, 35.08304732337322],
        [128.18468184681848, 34.890299301593004],
        [127.38547385473856, 34.475283290732904],
        [126.48546485464857, 34.39019632616325],
        [126.3738637386374, 34.933711018210175],
        [126.55746557465574, 35.6838654813548],
        [126.11826118261183, 36.72574668016679],
        [126.85986859868598, 36.8941841406414],
        [126.1758617586176, 37.750263192331914],
        [125.68985689856902, 37.939538276782756],
        [125.56745567455675, 37.7519996609966],
        [125.2758527585276, 37.66864916509165],
        [125.23985239852402, 37.85792424954249],
        [124.98064980649809, 37.9482206201062],
        [124.71064710647107, 38.10797573725736],
        [124.98424984249846, 38.54903877808778],
        [125.2218522185222, 38.66538217862177],
        [125.13185131851321, 38.84771138841387],
        [125.38745387453878, 39.38775314313142],
        [125.32265322653228, 39.550981197611975],
        [124.73584735847362, 39.66037872348724],
        [124.26424264242644, 39.92779489784897],
        [122.86742867428677, 39.63780463084629],
        [122.1330213302133, 39.170694560045604],
        [121.05301053010533, 38.89806897968978],
        [121.58581585815858, 39.36170611316112],
        [121.3770137701377, 39.75067509405093],
        [122.16902169021694, 40.42268846728467],
        [121.63981639816399, 40.947102004020024],
        [120.76860768607685, 40.59286239642395],
        [119.63819638196384, 39.89827493054929],
        [119.02259022590226, 39.25230858728585],
        [118.04338043380437, 39.20368746467463],
        [117.53217532175324, 38.73831386253863],
        [118.06138061380614, 38.06109108331083],
        [118.87858878588787, 37.89786302883029],
        [118.91098910989109, 37.448117644676444],
        [119.70299702997033, 37.15639090900909],
        [120.82260822608225, 37.8700795301953],
        [121.71181711817121, 37.481110549305484],
        [122.35622356223564, 37.455063519335184],
        [122.51822518225185, 36.93064998259982],
        [121.10341103411037, 36.65107852758527],
        [120.63540635406355, 36.11103677286772],
        [119.66339663396633, 35.60919732877328],
        [119.15219152191526, 34.909400456904564],
        [120.22860228602286, 34.360676358863586],
        [120.62100621006209, 33.37609862598626],
        [121.22941229412294, 32.46097963969639],
        [121.90981909819101, 31.691724021240205],
        [121.89181891818919, 30.94851543275432],
        [121.26541265412658, 30.675889852398512],
        [121.50301503015032, 30.142793972339717],
        [122.09342093420935, 29.831966081360804],
        [121.93861938619386, 29.017562277622773],
        [121.6830168301683, 28.225732566525664],
        [121.1250112501125, 28.135436195961958],
        [120.39420394203944, 27.05361621786217],
        [119.58419584195843, 25.740845907359073],
        [118.65538655386553, 24.547891934719345],
        [117.28017280172804, 23.624090605106048],
        [115.89055890558905, 22.783639771397702],
        [114.7637476374764, 22.66729637086371],
        [114.15174151741519, 22.224496861368607],
        [113.80613806138064, 22.547480033000326],
        [113.2409324093241, 22.05084999489995],
        [111.84411844118443, 21.550747019470194],
        [110.78570785707859, 21.39793777697777],
        [110.4437044370444, 20.340428360183594],
        [109.88929889298896, 20.283124894248942],
        [109.62649626496267, 21.008968796087956],
        [109.86409864098641, 21.394464839648393],
        [108.52128521285215, 21.715711542615423],
        [108.04968049680497, 21.552483488134882],
      ],
      [
        [23.80523805238053, 8.666149527495264],
        [24.56844568445686, 8.228559423994241],
        [23.88803888038882, 8.61926487354873],
        [23.80523805238053, 8.666149527495264],
      ],
      [
        [33.96453964539646, 9.464925113251127],
        [33.97533975339755, 8.685250682806824],
        [33.96093960939609, 9.583004982449822],
        [33.96453964539646, 9.464925113251127],
      ],
      [
        [52.50112501125011, 41.78407990039901],
        [52.447124471244734, 42.02718551345512],
        [52.69192691926921, 42.443937992979926],
        [52.50112501125011, 42.79296819458193],
        [51.34191341913419, 43.13331605286052],
        [50.89190891908919, 44.03107035250352],
        [50.33750337503375, 44.28459477754777],
        [50.30510305103053, 44.60931441784416],
        [51.27711277112772, 44.51554510995109],
        [51.31671316713167, 45.246598417784185],
        [52.16632166321665, 45.40809000360002],
        [53.041130411304124, 45.258753698436976],
        [53.22113221132213, 46.234649087990874],
        [53.041130411304124, 46.852831932619324],
        [52.04032040320405, 46.8042108100081],
        [51.190711907119066, 47.049052891728905],
        [50.03510035100351, 46.60972631956318],
        [49.102691026910264, 46.39961361113612],
        [48.64548645486457, 45.80574132781328],
        [47.67707677076771, 45.640776804668036],
        [46.683466834668366, 44.60931441784416],
        [47.59067590675909, 43.65946605826056],
        [47.493474934749344, 42.985716216362164],
        [48.584285842858435, 41.80839046170462],
        [49.10989109891099, 41.28224045630455],
        [49.61749617496176, 40.57376124111241],
        [50.08550085500855, 40.52687658716587],
        [50.39150391503915, 40.25598747547474],
        [49.57069570695708, 40.17610991689915],
        [49.39429394293944, 39.39990842378424],
        [49.221492214922165, 39.04914175351752],
        [48.85788857888579, 38.814718483784844],
        [48.88308883088831, 38.31982491434914],
        [49.19989199892001, 37.583562200521996],
        [50.14670146701468, 37.375185960759595],
        [50.84150841508415, 36.873346516665165],
        [52.263522635226366, 36.69969965019649],
        [53.82593825938261, 36.965379355893546],
        [53.92313923139233, 37.19806615696156],
        [53.735937359373594, 37.906545372153715],
        [53.879938799388015, 38.95189950829507],
        [53.10233102331023, 39.29051089790897],
        [53.35793357933579, 39.974679551795504],
        [52.69552695526957, 40.03371948639486],
        [52.915129151291524, 40.87590678876788],
        [53.85833858338583, 40.631064707047074],
        [54.7367473674737, 40.9505749413494],
        [54.00954009540095, 41.551393099330994],
        [53.721537215372166, 42.12269129001288],
        [52.915129151291524, 41.867430396303945],
        [52.81432814328144, 41.13464061980619],
        [52.50112501125011, 41.78407990039901],
      ],
    ],
    [
      [
        [-68.63288632886328, -52.63640374193742],
        [-68.25128251282513, -53.10004087540876],
        [-67.75087750877509, -53.85019533855339],
        [-66.45126451264512, -54.44927702787028],
        [-65.05085050850508, -54.69932851558516],
        [-65.50085500855008, -55.19943149101491],
        [-66.45126451264512, -55.249789082290825],
        [-66.95886958869588, -54.897285943359435],
        [-67.290072900729, -55.301883142231425],
        [-68.14688146881468, -55.61097456454565],
        [-68.640086400864, -55.579718128581284],
        [-69.23049230492305, -55.4998405700057],
        [-69.95769957699576, -55.197695022350224],
        [-71.00531005310053, -55.053568123181236],
        [-72.26532265322653, -54.494425213152134],
        [-73.28413284132841, -53.95785639576396],
        [-74.66294662946629, -52.837834107041076],
        [-73.83853838538386, -53.04794681546816],
        [-72.43452434524345, -53.71475078270783],
        [-71.10611106111061, -54.07419979629797],
        [-70.59130591305913, -53.61577206882069],
        [-70.26730267302672, -52.93160341493415],
        [-69.34569345693457, -52.518323872738726],
        [-68.63288632886328, -52.63640374193742],
      ],
    ],
    [
      [
        [-53.372333723337235, -33.76793523145232],
        [-53.80793807938079, -34.39653688806888],
        [-54.934749347493465, -34.95220686076861],
        [-55.672756727567275, -34.75251296432965],
        [-56.216362163621625, -34.86017402154022],
        [-57.13797137971379, -34.431266261362616],
        [-57.81837818378183, -34.462522697326975],
        [-58.42678426784268, -33.90858919329194],
        [-58.49518495184951, -34.431266261362616],
        [-57.22437224372243, -35.28734531305313],
        [-57.361173611736106, -35.97672337293373],
        [-56.73836738367383, -36.41257700777008],
        [-56.78876788767887, -36.90226117121171],
        [-57.749977499775, -38.18377504575046],
        [-59.23319233192332, -38.72034386313863],
        [-61.23841238412383, -38.92872010290103],
        [-62.33642336423364, -38.82800492034921],
        [-62.1240212402124, -39.42361367233673],
        [-62.329223292232925, -40.17203166681667],
        [-62.145621456214556, -40.677344048240485],
        [-62.746827468274674, -41.028110718507186],
        [-63.76923769237692, -41.16702821168212],
        [-64.73044730447305, -40.80236979209792],
        [-65.11925119251192, -41.06457656046561],
        [-64.97884978849788, -42.057836636666366],
        [-64.3020430204302, -42.35824571565716],
        [-63.75483754837548, -42.04394488734888],
        [-63.459634596345964, -42.563149018090186],
        [-64.37764377643776, -42.87397690906909],
        [-65.18045180451804, -43.49563269102691],
        [-65.3280532805328, -44.50104804788048],
        [-65.56565565655656, -45.03761686526865],
        [-66.50886508865088, -45.03935333393334],
        [-67.29367293672937, -45.5516115900159],
        [-67.58167581675816, -46.301766053160534],
        [-66.59886598865988, -47.0345558296583],
        [-65.64125641256412, -47.23598619476195],
        [-65.98685986859869, -48.13374049440495],
        [-67.16767167671676, -48.69809281042811],
        [-67.81567815678156, -49.870209159091594],
        [-68.73008730087301, -50.26438754597546],
        [-69.13689136891368, -50.733234085440856],
        [-68.81648816488165, -51.77164234692347],
        [-68.15048150481505, -52.34988641226413],
        [-68.57168571685716, -52.299528820988215],
        [-69.46089460894609, -52.29258294632947],
        [-69.94329943299432, -52.53742502805028],
        [-70.84330843308433, -52.898610510305105],
        [-71.00531005310053, -53.83283065190652],
        [-71.430114301143, -53.85714121321213],
        [-72.55692556925568, -53.53068510425104],
        [-73.70173701737016, -52.8343611697117],
        [-74.94734947349473, -52.26306297902979],
        [-75.26055260552606, -51.629251916419165],
        [-74.97614976149761, -51.04406197641977],
        [-75.48015480154801, -50.37899447784478],
        [-75.60975609756098, -48.673782249122496],
        [-75.18135181351813, -47.71177860888609],
        [-74.12654126541265, -46.93905005310053],
        [-75.64575645756457, -46.64732331743318],
        [-74.69174691746917, -45.763460767107674],
        [-74.35334353343534, -44.10339672366724],
        [-73.24093240932409, -44.45416339393394],
        [-72.7189271892719, -42.382556276962774],
        [-73.38853388533884, -42.11687657126571],
        [-73.70173701737016, -43.36539754117541],
        [-74.33174331743317, -43.224743579335794],
        [-74.01854018540185, -41.79562986829868],
        [-73.67653676536764, -39.942817803078036],
        [-73.21933219332193, -39.25864914919149],
        [-73.50733507335073, -38.2827537596376],
        [-73.58653586535866, -37.15578559625597],
        [-73.16533165331653, -37.12452916029161],
        [-72.55332553325533, -35.509613302133026],
        [-71.86211862118621, -33.90858919329194],
        [-71.43731437314372, -32.41869907899079],
        [-71.66771667716677, -30.920126621366215],
        [-71.36891368913689, -30.09530400564006],
        [-71.49131491314913, -28.860674785047856],
        [-70.90450904509045, -27.63993731377314],
        [-70.72450724507245, -25.70551122131222],
        [-70.40410404104041, -23.62869469834699],
        [-70.09090090900908, -21.39385952689527],
        [-70.16290162901629, -19.756369576095764],
        [-70.37170371703716, -18.348093489034895],
        [-71.3761137611376, -17.773322361023617],
        [-71.46251462514624, -17.363515756157568],
        [-73.44613446134461, -16.35983686796868],
        [-75.23895238952389, -15.265861609216103],
        [-76.00936009360093, -14.649415233252341],
        [-76.42336423364233, -13.82285614886149],
        [-76.25776257762577, -13.534602350523514],
        [-77.10737107371074, -12.22356850868509],
        [-78.09378093780937, -10.377702318123184],
        [-79.03699036990369, -8.385972759727608],
        [-79.44739447394474, -7.931017969579699],
        [-79.76059760597606, -7.194755255752568],
        [-80.53820538205382, -6.54184303783039],
        [-81.2510125101251, -6.137245838958393],
        [-80.9270092700927, -5.690973392133927],
        [-81.40941409414094, -4.735915626556263],
        [-81.09981099810997, -4.03611875468755],
        [-80.30420304203042, -3.4040441607416057],
        [-79.77139771397714, -2.6573626349263577],
        [-79.98739987399874, -2.221509000090009],
        [-80.36900369003689, -2.6851461335613465],
        [-80.96660966609666, -2.2475560300603092],
        [-80.7650076500765, -1.9645116377163845],
        [-80.93420934209341, -1.0580749947499584],
        [-80.5850058500585, -0.9070022209222088],
        [-80.39780397803978, -0.28360997029970747],
        [-80.01980019800197, 0.36061990429904256],
        [-80.09180091800917, 0.7686900405004025],
        [-79.54099540995409, 0.9822756862568554],
        [-78.8569885698857, 1.3816634791347866],
        [-78.99018990189902, 1.6907549014490115],
        [-78.61938619386194, 1.7671595226952235],
        [-78.66258662586625, 2.2672624981249783],
        [-78.42858428584286, 2.630184449044492],
        [-77.93177931779317, 2.6961702583025726],
        [-77.51057510575106, 3.3247719149191397],
        [-77.1289712897129, 3.849185451654506],
        [-77.49617496174962, 4.087081658716585],
        [-77.3089730897309, 4.668798661386603],
        [-77.53217532175321, 5.5821811790117835],
        [-77.31977319773198, 5.846124416044162],
        [-77.47817478174781, 6.6917846557465595],
        [-77.88137881378813, 7.223144067140666],
        [-78.21618216182162, 7.511397865478656],
        [-78.42858428584286, 8.051439620196192],
        [-78.18378183781837, 8.318855794557948],
        [-78.43578435784357, 8.388314541145405],
        [-78.62298622986229, 8.718243587435865],
        [-79.11979119791198, 8.996078573785738],
        [-79.5589955899559, 8.931829233192332],
        [-79.76059760597606, 8.584535500255],
        [-80.16380163801638, 8.332747543875428],
        [-80.38340383403833, 8.298018170581699],
        [-80.48060480604806, 8.089641930819297],
        [-80.00540005400053, 7.547863707437074],
        [-80.27540275402754, 7.4193650262502615],
        [-80.41940419404193, 7.271765189751889],
        [-80.88740887408873, 7.221407598475977],
        [-81.06021060210601, 7.818752819128193],
        [-81.18981189811898, 7.648578889988897],
        [-81.5210152101521, 7.705882355923549],
        [-81.72261722617226, 8.108743086130858],
        [-82.1330213302133, 8.174728895388952],
        [-82.39222392223922, 8.292808764587647],
        [-82.82062820628207, 8.291072295922959],
        [-82.84942849428494, 8.074013712837129],
        [-82.96462964629646, 8.225086486664864],
        [-83.50823508235082, 8.447354475744746],
        [-83.70983709837098, 8.657467184171836],
        [-83.59463594635946, 8.831114050640508],
        [-83.63423634236342, 9.051645571055701],
        [-83.91143911439114, 9.291278246782468],
        [-84.30384303843039, 9.48749920589205],
        [-84.64584645846458, 9.615997887078862],
        [-84.71424714247142, 9.907724622746215],
        [-84.9770497704977, 10.086580895208954],
        [-84.91224912249122, 9.796590628206275],
        [-85.11025110251101, 9.556957952479522],
        [-85.34065340653406, 9.83479293882938],
        [-85.6610566105661, 9.933771652716516],
        [-85.79785797857978, 10.135202017820177],
        [-85.79065790657906, 10.439084034140336],
        [-85.6610566105661, 10.755121331113301],
        [-85.94185941859418, 10.89577529295292],
        [-85.71145711457115, 11.088523314733138],
        [-86.0570605706057, 11.402824143041428],
        [-86.5250652506525, 11.807421341913411],
        [-86.74466744667447, 12.144296262862625],
        [-87.16587165871658, 12.458597091170901],
        [-87.66987669876698, 12.910078943989433],
        [-87.55827558275583, 13.064624655146545],
        [-87.39267392673926, 12.91355188131881],
        [-87.3170731707317, 12.984747096570956],
        [-87.48987489874898, 13.297311456214558],
        [-87.79227792277922, 13.384134889448887],
        [-87.90387903879038, 13.149711619716186],
        [-88.48348483484834, 13.16360336903368],
        [-88.84348843488435, 13.259109145591452],
        [-89.25749257492575, 13.45880304203041],
        [-89.81189811898119, 13.521315913959128],
        [-90.09630096300963, 13.734901559715595],
        [-90.60750607506074, 13.910284894848942],
        [-91.23391233912339, 13.927649581495814],
        [-91.6911169111691, 14.125607009270084],
        [-92.22752227522275, 14.53888655146551],
        [-93.35793357933579, 15.615497123571231],
        [-93.87633876338764, 15.940216763867639],
        [-94.68994689946899, 16.200687063570626],
        [-95.25155251552515, 16.12775537965379],
        [-96.05436054360543, 15.752678148081472],
        [-96.55836558365583, 15.653699434194337],
        [-97.26397263972639, 15.917642671226702],
        [-98.01278012780128, 16.106917755677557],
        [-98.94878948789487, 16.56534548315483],
        [-99.69759697596976, 16.705999444994447],
        [-100.8280082800828, 17.171373047130473],
        [-101.66681666816667, 17.64890192991929],
        [-101.91881918819188, 17.916318104281032],
        [-102.47682476824768, 17.975358038880387],
        [-103.49923499234993, 18.29313180451804],
        [-103.91683916839168, 18.748086594665935],
        [-104.99324993249932, 19.315911848018473],
        [-105.49365493654936, 19.94624997329973],
        [-105.73125731257312, 20.434197668076678],
        [-105.39645396453965, 20.531439913299124],
        [-105.50085500855008, 20.816220774307737],
        [-105.27045270452705, 21.07669107401074],
        [-105.26685266852668, 21.42224833828338],
        [-105.60165601656016, 21.871993722437225],
        [-105.69165691656916, 22.269645046650467],
        [-106.030060300603, 22.773220959409585],
        [-106.90846908469085, 23.768217504275043],
        [-107.91647916479164, 24.549628403384034],
        [-108.40248402484025, 25.173020654006535],
        [-109.25929259292593, 25.581090790207895],
        [-109.44289442894429, 25.824196403264025],
        [-109.29169291692916, 26.442379247892475],
        [-109.80289802898028, 26.676802517625177],
        [-110.39330393303933, 27.16127727507275],
        [-110.64170641706417, 27.859337678276773],
        [-111.1781117811178, 27.94095170551705],
        [-111.76131761317613, 28.467101710917106],
        [-112.22932229322294, 28.955049405694055],
        [-112.27252272522725, 29.267613765337643],
        [-112.8089280892809, 30.021241165811645],
        [-113.16533165331653, 30.787023846938467],
        [-113.14733147331474, 31.170783421834216],
        [-113.87093870938709, 31.56843474604746],
        [-114.20574205742057, 31.523286560765598],
        [-114.77814778147781, 31.799385078450783],
        [-114.93654936549365, 31.393051410914097],
        [-114.7709477094771, 30.91378605946059],
        [-114.67374673746737, 30.161895127651277],
        [-114.33174331743317, 29.75035205412054],
        [-113.590135901359, 29.06097399423993],
        [-113.42453424534246, 28.826550724507243],
        [-113.27333273332734, 28.755355509255082],
        [-113.14013140131401, 28.41153471364713],
        [-112.96372963729637, 28.425426462964623],
        [-112.7621276212762, 27.779460119701184],
        [-112.45612456124562, 27.525935694656937],
        [-112.24372243722436, 27.171696087060866],
        [-111.61731617316173, 26.662910768307682],
        [-111.28611286112861, 25.73216356403563],
        [-110.98730987309872, 25.294573460534593],
        [-110.710107101071, 24.825726921069204],
        [-110.65610656106561, 24.29784044700446],
        [-110.17370173701737, 24.26484754237542],
        [-109.77049770497705, 23.8116292208922],
        [-109.41049410494105, 23.365356774067735],
        [-109.43209432094321, 23.184764032940322],
        [-109.85329853298532, 22.818369144691445],
        [-110.02970029700296, 22.823578550685497],
        [-110.2961029610296, 23.43134258332583],
        [-110.94770947709476, 24.00090430534304],
        [-111.67131671316713, 24.48364259412594],
        [-112.18252182521825, 24.738903487834875],
        [-112.15012150121501, 25.469956795667954],
        [-112.30132301323013, 26.011735019050178],
        [-112.77652776527765, 26.32256291002909],
        [-113.4641346413464, 26.768835356853558],
        [-113.59733597335973, 26.63860020700207],
        [-113.84933849338493, 26.900806975369747],
        [-114.4649446494465, 27.14217611976119],
        [-115.05535055350553, 27.722156653766532],
        [-114.98334983349834, 27.798561275012744],
        [-114.56934569345694, 27.74125780907808],
        [-114.19854198541985, 28.11459857198571],
        [-114.16254162541625, 28.56608042480424],
        [-114.9329493294933, 29.27976904599045],
        [-115.51975519755197, 29.555867563675633],
        [-115.8869588695887, 30.180996282962823],
        [-116.25776257762578, 30.83564496954969],
        [-116.72216722167221, 31.636157023970227],
        [-117.1289712897129, 32.53564779227791],
        [-117.29457294572946, 33.046169579695786],
        [-117.94257942579426, 33.620940707707064],
        [-118.41058410584105, 33.74075704557045],
        [-118.51858518585186, 34.02727437524375],
        [-119.08019080190802, 34.07763196651966],
        [-119.44019440194401, 34.34852107821078],
        [-120.36900369003689, 34.447499792097915],
        [-120.62460624606246, 34.60899137791377],
        [-120.74340743407434, 35.15771547595476],
        [-121.71541715417155, 36.16139436414363],
        [-122.54702547025471, 37.552305764557644],
        [-122.5110251102511, 37.78325609696097],
        [-122.95382953829538, 38.11318514325143],
        [-123.72783727837279, 38.95189950829507],
        [-123.86463864638645, 39.7663033120331],
        [-124.39744397443974, 40.313290941409406],
        [-124.17784177841779, 41.141586494464946],
        [-124.21384213842138, 41.999402014820134],
        [-124.53424534245343, 42.76518469594694],
        [-124.14184141841417, 43.708087180871814],
        [-124.01944019440194, 44.616260292502915],
        [-123.90063900639007, 45.522696935469355],
        [-124.08064080640807, 46.864987213272116],
        [-124.39744397443974, 47.719329796297956],
        [-124.68544685446855, 48.18470339843398],
        [-124.56664566645667, 48.379187888878874],
        [-123.11943119431194, 48.04057649926497],
        [-122.58662586625866, 47.09593754567544],
        [-122.33822338223382, 47.35988078270782],
        [-122.50022500225002, 48.179493992439916],
        [-122.83862838628386, 49.0008436708367],
        [-122.97542975429755, 49.00258013950139],
        [-124.90864908649087, 49.98542140371404],
        [-125.6250562505625, 50.416065632556325],
        [-127.43587435874358, 50.83108164341644],
        [-127.99387993879938, 51.71668066240662],
        [-127.84987849878499, 52.329654101041],
        [-129.13149131491315, 52.75508892388922],
        [-129.30429304293042, 53.560810384303835],
        [-130.5139051390514, 54.28839075480755],
        [-130.53550535505354, 54.8023854795548],
        [-131.08631086310862, 55.179199179791794],
        [-131.96831968319682, 55.496972945429434],
        [-132.2491224912249, 56.37041668376682],
        [-133.5379353793538, 57.179611081510814],
        [-134.07794077940778, 58.12251356643566],
        [-135.0391503915039, 58.18849937569374],
        [-136.62676626766267, 58.21280993699935],
        [-137.80037800378005, 58.49932726667265],
        [-139.86679866798667, 59.53773552815528],
        [-140.82440824408243, 59.72701061260611],
        [-142.5740257402574, 60.084723157531556],
        [-143.960039600396, 59.99963619296193],
        [-145.92565925659255, 60.4580639204392],
        [-147.11367113671136, 60.88523521195211],
        [-148.2260822608226, 60.67338603486033],
        [-148.01728017280172, 59.978798568985695],
        [-148.57168571685716, 59.914549228392275],
        [-149.72729727297272, 59.70617298862987],
        [-150.60930609306092, 59.36756159901597],
        [-151.7181171811718, 59.15571242192422],
        [-151.85851858518586, 59.744375299252994],
        [-151.40851408514084, 60.72548009480093],
        [-150.34650346503466, 61.032835048450465],
        [-150.62010620106201, 61.28462300483005],
        [-151.89451894518945, 60.72721656346562],
        [-152.57852578525785, 60.06214906489063],
        [-154.01854018540186, 59.350196912369114],
        [-153.28773287732878, 58.863985686256854],
        [-154.23094230942309, 58.14682412774127],
        [-155.30735307353075, 57.72833517955178],
        [-156.30816308163082, 57.42271669456693],
        [-156.55656556565566, 56.979917185071855],
        [-158.1189811898119, 56.46418599165992],
        [-158.4321843218432, 55.993602983529826],
        [-159.60219602196022, 55.56643169201692],
        [-160.289802898029, 55.64283631326313],
        [-161.2222122221222, 55.36500132691327],
        [-162.23742237422374, 55.02465346863468],
        [-163.0690306903069, 54.68951501635016],
        [-164.78624786247863, 54.404734155341544],
        [-164.94104941049412, 54.571435147151476],
        [-163.84663846638466, 55.04028168661685],
        [-162.8710287102871, 55.34763664026639],
        [-161.80541805418054, 55.89462426964269],
        [-160.56340563405635, 56.007494732847334],
        [-160.070200702007, 56.41730133771338],
        [-158.6841868418684, 57.01638302703026],
        [-158.4609846098461, 57.21607692346922],
        [-157.7229772297723, 57.570316531065316],
        [-157.550175501755, 58.32915333753337],
        [-157.04257042570424, 58.91955268352683],
        [-158.19458194581946, 58.61567066720667],
        [-158.51858518585186, 58.78758106501064],
        [-159.05859058590585, 58.42465911409113],
        [-159.710197101971, 58.93170796417962],
        [-159.98019980199803, 58.572258950589486],
        [-160.35460354603546, 59.070625457354566],
        [-161.35541355413554, 58.67123766447665],
        [-161.96741967419675, 58.67123766447665],
        [-162.05382053820537, 59.26684641646415],
        [-161.87381873818737, 59.63324130471304],
        [-162.51822518225183, 59.9892173809738],
        [-163.81783817838178, 59.79820582785828],
        [-164.6638466384664, 60.26705236732366],
        [-165.34785347853477, 60.506685043050425],
        [-165.35145351453514, 61.07451029640296],
        [-166.1218612186122, 61.49994511925118],
        [-165.7330573305733, 62.07471624726247],
        [-164.91944919449193, 62.63385915729157],
        [-164.56304563045632, 63.14611741337413],
        [-163.7530375303753, 63.21904909729096],
        [-163.06543065430654, 63.059293980139785],
        [-162.2590225902259, 63.54203226892267],
        [-161.53541535415354, 63.45520883568835],
        [-160.7722077220772, 63.76603672666727],
        [-160.95940959409594, 64.22272798547985],
        [-161.51741517415175, 64.40332072660726],
        [-160.77940779407794, 64.7888167701677],
        [-161.39141391413915, 64.77666148951488],
        [-162.45342453424536, 64.55960290642906],
        [-162.75942759427593, 64.33907138601384],
        [-163.54783547835478, 64.55960290642906],
        [-164.9590495904959, 64.44673244322442],
        [-166.42426424264244, 64.68636511895119],
        [-166.84546845468455, 65.08922584915848],
        [-168.10908109081092, 65.66920638316381],
        [-166.7050670506705, 66.0876953313533],
        [-164.4730447304473, 66.57737949479494],
        [-163.65223652236523, 66.57737949479494],
        [-163.7890378903789, 66.0772765193652],
        [-161.67941679416793, 66.11547882998829],
        [-162.48942489424894, 66.73539814328143],
        [-163.72063720637206, 67.1156847808478],
        [-164.429844298443, 67.61578775627754],
        [-165.3910539105391, 68.04295904779048],
        [-166.76266762667626, 68.35899634476343],
        [-166.20466204662046, 68.8834098814988],
        [-164.429844298443, 68.91640278612786],
        [-163.16983169831698, 69.37135757627576],
        [-162.9322293222932, 69.85756880238802],
        [-161.90981909819098, 70.33336121651215],
        [-160.93420934209342, 70.44796814838148],
        [-159.04059040590406, 70.89250412654127],
        [-158.1189811898119, 70.82478184861847],
        [-156.58176581765818, 71.35787772867727],
        [-155.0661506615066, 71.1477650202502],
        [-154.34254342543426, 70.69628316743166],
        [-153.89973899739, 70.89076765787658],
        [-152.21132211322114, 70.82999125461254],
        [-152.26892268922688, 70.6007773908739],
        [-150.7389073890739, 70.43060346173462],
        [-149.72009720097202, 70.52958217562175],
        [-147.6140761407614, 70.21354487864878],
        [-145.69165691656917, 70.1197755707557],
        [-144.9212492124921, 69.9895404209042],
        [-143.58923589235891, 70.15276847538473],
        [-142.07362073620737, 69.85235939639395],
        [-140.98640986409865, 69.71170543455435],
        [-139.12159121591216, 69.47033629016289],
        [-137.5447754477545, 68.9893344700447],
        [-136.50436504365044, 68.89730163081629],
        [-135.6259562595626, 69.31579057900578],
        [-134.41634416344164, 69.62661846998469],
        [-132.92952929529295, 69.50506566345663],
        [-131.43191431914317, 69.94439223562236],
        [-129.7938979389794, 70.19444372333723],
        [-129.10629106291063, 69.77942771247712],
        [-128.3610836108361, 70.01211451354513],
        [-128.1378813788138, 70.48443399033991],
        [-127.44667446674467, 70.37677293312933],
        [-125.75465754657546, 69.48075510215102],
        [-124.42624426244262, 70.1579778813788],
        [-124.28944289442894, 69.39914107491074],
        [-123.06183061830617, 69.56410559805596],
        [-122.68382683826837, 69.85583233372333],
        [-121.47061470614706, 69.79852886778866],
        [-119.94419944199441, 69.37830345093451],
        [-117.60417604176041, 69.01190856268562],
        [-116.22536225362254, 68.84173463354634],
        [-115.24615246152462, 68.90598397413973],
        [-113.89973899738997, 68.39893512405123],
        [-115.30375303753037, 67.90230508595084],
        [-113.49653496534965, 67.6887194401944],
        [-110.79650796507966, 67.80679930939309],
        [-109.94689946899469, 67.98044617586174],
        [-108.88128881288813, 67.38136448654487],
        [-107.7940779407794, 67.88667686796867],
        [-108.81288812888128, 68.31211169081689],
        [-108.16848168481684, 68.65419601776017],
        [-106.95166951669516, 68.69934420304202],
        [-106.14886148861488, 68.80005938559384],
        [-105.34245342453424, 68.5604267098671],
        [-104.3380433804338, 68.01864848648486],
        [-103.2220322203222, 68.09852604506045],
        [-101.45441454414544, 67.6470441922419],
        [-99.90279902799028, 67.8050628407284],
        [-98.44478444784447, 67.78248874808747],
        [-98.55998559985599, 68.4041445300453],
        [-97.67077670776708, 68.57779139651396],
        [-96.11916119161191, 68.23918000690006],
        [-96.12636126361264, 67.29280458464584],
        [-95.4891548915489, 68.08984370173701],
        [-94.68634686346863, 68.06379667176671],
        [-94.23274232742327, 69.06921202862029],
        [-95.30555305553055, 69.68565840458405],
        [-96.47196471964719, 70.09025560345603],
        [-96.39276392763928, 71.19464967419674],
        [-95.20835208352084, 71.92049357603574],
        [-93.89073890738906, 71.76073845888459],
        [-92.87912879128791, 71.31793894938949],
        [-91.51831518315183, 70.19097078600785],
        [-92.40752407524074, 69.69955015390153],
        [-90.54630546305462, 69.49811978879788],
        [-90.54990549905499, 68.47533974529745],
        [-89.21429214292142, 69.25848711307111],
        [-88.0190801908019, 68.61425723847239],
        [-88.31788317883178, 67.87278511865117],
        [-87.34947349473494, 67.19903527675277],
        [-86.30546305463054, 67.92140624126242],
        [-85.57825578255782, 68.78443116761167],
        [-85.52065520655206, 69.88187936369363],
        [-84.10224102241023, 69.80547474244742],
        [-82.62262622626226, 69.65787490594906],
        [-81.27981279812798, 69.16124486784867],
        [-81.21861218612186, 68.66635129841296],
        [-81.96381963819638, 68.13325541835417],
        [-81.25821258212582, 67.596686600966],
        [-81.38781387813877, 67.11047537485373],
        [-83.34623346233462, 66.41067850298504],
        [-84.73584735847358, 66.25786926049258],
        [-85.7690576905769, 66.5582783394834],
        [-86.06786067860678, 66.05643889538894],
        [-87.03267032670327, 65.21251512435123],
        [-87.32427324273242, 64.77492502085019],
        [-88.48348483484834, 64.0994387102871],
        [-89.91269912699127, 64.033452901029],
        [-90.70470704707047, 63.609754546845465],
        [-90.76950769507695, 62.96031526625265],
        [-91.93231932319323, 62.835289522395215],
        [-93.15633156331563, 62.02435865598656],
        [-94.239942399424, 60.899126961269616],
        [-94.62874628746287, 60.110770187501856],
        [-94.68634686346863, 58.94907265082651],
        [-93.21393213932139, 58.782371659016576],
        [-92.76392763927639, 57.84641504875049],
        [-92.29592295922959, 57.087578242282405],
        [-90.8991089910899, 57.2855356700567],
        [-89.03789037890378, 56.85141850388504],
        [-88.04068040680407, 56.47113186631864],
        [-87.32427324273242, 55.99881238952389],
        [-86.07146071460714, 55.72445034050341],
        [-85.0130501305013, 55.30248845498454],
        [-83.36063360633607, 55.245184989049875],
        [-82.27342273422734, 55.14794274382743],
        [-82.43542435424354, 54.2814448801488],
        [-82.12582125821258, 53.27776599195991],
        [-81.40221402214021, 52.15774370323703],
        [-79.91179911799118, 51.20789534365343],
        [-79.14139141391414, 51.534351452614516],
        [-78.60138601386014, 52.562340902109014],
        [-79.12339123391233, 54.140790918309165],
        [-79.82899828998289, 54.666940923709234],
        [-78.2269822698227, 55.13578746317464],
        [-77.09657096570966, 55.837320803708025],
        [-76.54216542165422, 56.533644738247375],
        [-76.62496624966249, 57.20218517415174],
        [-77.30177301773017, 58.05131835118351],
        [-78.51858518585186, 58.8049457516575],
        [-77.33777337773377, 59.85203635646354],
        [-77.77337773377734, 60.75847299942998],
        [-78.10818108181081, 62.31955832898328],
        [-77.40977409774098, 62.5505086613866],
        [-75.69615696156961, 62.27788308103081],
        [-74.66654666546665, 62.18064083580836],
        [-73.83853838538386, 62.44458407284071],
        [-72.90972909729096, 62.10423621456215],
        [-71.67851678516784, 61.52599214922148],
        [-71.37251372513725, 61.137023168331666],
        [-69.59049590495904, 61.060618547085454],
        [-69.61929619296193, 60.22190418204181],
        [-69.2880928809288, 58.95775499414992],
        [-68.37368373683736, 58.80147281432812],
        [-67.65007650076501, 58.21280993699935],
        [-66.20286202862029, 58.76674344103441],
        [-65.24525245252453, 59.87113751177512],
        [-64.58284582845828, 60.334774645246455],
        [-63.80523805238052, 59.442229751597495],
        [-62.5020250202502, 58.1676617517175],
        [-61.39681396813968, 56.967761904419035],
        [-61.80001800018, 56.33916024780248],
        [-60.46800468004679, 55.77480793177932],
        [-59.567995679956795, 55.203509741097406],
        [-57.97677976779768, 54.94477591005909],
        [-57.332373323733236, 54.627002144421425],
        [-56.93636936369363, 53.77960543605434],
        [-56.15876158761587, 53.64763381753818],
        [-55.75555755557555, 53.270820117301156],
        [-55.68355683556835, 52.1473248912489],
        [-56.41076410764107, 51.770511191011906],
        [-57.12717127171271, 51.41974452074521],
        [-58.775987759877594, 51.063768444484424],
        [-60.032400324003234, 50.24241876608767],
        [-61.72441724417244, 50.0809271802718],
        [-63.862838628386285, 50.29103988869889],
        [-65.3640536405364, 50.297985763357616],
        [-66.39726397263972, 50.22852701677016],
        [-67.2360723607236, 49.51136545825457],
        [-68.51048510485104, 49.06856594875947],
        [-69.9540995409954, 47.745376826268256],
        [-71.10611106111061, 46.82157549665496],
        [-70.25650256502564, 46.9865400198002],
        [-68.65088650886509, 48.299310330303285],
        [-66.5520655206552, 49.13281528935289],
        [-65.0580505805058, 49.233530471904714],
        [-64.17244172441724, 48.74210983979839],
        [-65.11565115651156, 48.07009646656465],
        [-64.79884798847988, 46.99348589445893],
        [-64.47124471244712, 46.23812202532025],
        [-63.171631716317165, 45.73975551855517],
        [-61.51921519215192, 45.88388241772418],
        [-60.51840518405183, 47.00737764377644],
        [-60.450004500045, 46.2832702106021],
        [-59.80199801998019, 45.920348259682584],
        [-61.04041040410404, 45.26569957309573],
        [-63.25443254432544, 44.670090821108204],
        [-64.2480424804248, 44.26549362223622],
        [-65.3640536405364, 43.54485912639126],
        [-66.12366123661236, 43.61952727897278],
        [-66.16326163261633, 44.46518751867518],
        [-64.42444424444244, 45.29174660306603],
        [-66.02646026460265, 45.258753698436976],
        [-67.13887138871388, 45.13720089190892],
        [-66.9660696606966, 44.80900831428315],
        [-68.03168031680316, 44.324533556835576],
        [-69.06129061290612, 43.98071276122761],
        [-70.1161011610116, 43.6837766195662],
        [-70.64530645306452, 43.089904336243364],
        [-70.81450814508145, 42.865899878498766],
        [-70.82530825308253, 42.33454046710466],
        [-70.4941049410494, 41.80491752437524],
        [-70.080100801008, 41.78060696306963],
        [-70.18450184501845, 42.14526538265383],
        [-69.88569885698857, 41.92299739357392],
        [-69.9648996489965, 41.63648006390062],
        [-70.64170641706417, 41.47498847808478],
        [-71.12051120511205, 41.49408963339633],
        [-71.85851858518585, 41.32044276692767],
        [-72.2941229412294, 41.27008517565176],
        [-72.87732877328773, 41.221464053040535],
        [-73.70893708937089, 40.931473786037856],
        [-72.24012240122401, 41.11901240182402],
        [-71.94491944919449, 40.92973731737317],
        [-73.34533345333453, 40.629328238382385],
        [-73.98253982539825, 40.6275917697177],
        [-73.95373953739536, 40.75088104491044],
        [-74.2561425614256, 40.473046058560584],
        [-73.96093960939609, 40.42789787327874],
        [-74.1769417694177, 39.708999846098465],
        [-74.90774907749078, 38.93974422764228],
        [-74.97974979749797, 39.196741590015904],
        [-75.19935199351993, 39.248835649956504],
        [-75.52695526955269, 39.498887137671375],
        [-75.32175321753218, 38.960581851618514],
        [-75.07335073350733, 38.78172557915579],
        [-75.05535055350553, 38.404911878918796],
        [-75.37575375753757, 38.01594289802898],
        [-75.94095940959409, 37.21716731227312],
        [-76.03096030960309, 37.257106091560914],
        [-75.72135721357213, 37.93780180811808],
        [-76.23256232562325, 38.31982491434914],
        [-76.35136351363514, 39.14985693606934],
        [-76.54216542165422, 38.71747623856237],
        [-76.32976329763298, 38.08366517595175],
        [-76.98856988569885, 38.23994735577355],
        [-76.3009630096301, 37.91870065280652],
        [-76.25776257762577, 36.967115824558235],
        [-75.97335973359733, 36.89765707797078],
        [-75.86895868958689, 36.552099813698135],
        [-75.72855728557285, 35.55015739417394],
        [-76.36216362163621, 34.80868527435274],
        [-77.3989739897399, 34.51174913269132],
        [-78.05418054180541, 33.92482272402724],
        [-78.55458554585546, 33.86057338343383],
        [-79.06219062190621, 33.49417849518494],
        [-79.20259202592025, 33.15904004290043],
        [-80.30060300603006, 32.50960076230761],
        [-80.86580865808658, 32.03380834818347],
        [-81.33741337413373, 31.439936064860646],
        [-81.49221492214922, 30.7297203810038],
        [-81.31221312213121, 30.03513291512914],
        [-80.9810098100981, 29.180790332103314],
        [-80.53460534605345, 28.472311116911158],
        [-80.5310053100531, 28.039930419404186],
        [-80.05580055800557, 26.879969351393513],
        [-80.08820088200882, 26.206219509495085],
        [-80.13140131401313, 25.817250528605285],
        [-80.37980379803798, 25.206013558635576],
        [-80.67860678606786, 25.079251346113452],
        [-81.17181171811718, 25.200804152641524],
        [-81.33021330213302, 25.640130724807236],
        [-81.70821708217082, 25.869344588545886],
        [-82.2410224102241, 26.73063304623045],
        [-82.70542705427054, 27.494679258692585],
        [-82.85662856628566, 27.885384708247074],
        [-82.65142651426514, 28.550452206822058],
        [-82.92862928629286, 29.099176304863036],
        [-83.70983709837098, 29.936154201242005],
        [-84.09864098640986, 30.090699912399117],
        [-85.11025110251101, 29.635745122251222],
        [-85.28665286652866, 29.686102713527134],
        [-85.77265772657726, 30.153212784327835],
        [-86.3990639906399, 30.399791334713342],
        [-87.52947529475294, 30.274765590855907],
        [-88.41868418684186, 30.38416311673116],
        [-89.18189181891819, 30.316440838808376],
        [-89.59229592295922, 30.16015865898659],
        [-89.41229412294122, 29.89447895328952],
        [-89.43029430294303, 29.48814528575285],
        [-89.21789217892179, 29.291924326643255],
        [-89.40869408694087, 29.15995270812708],
        [-89.77949779497794, 29.307552544625437],
        [-90.1539015390154, 29.118277460174596],
        [-90.88110881108811, 29.147797427474273],
        [-91.62631626316262, 29.67742037020369],
        [-92.49752497524975, 29.552394626346256],
        [-93.22473224732246, 29.78334495874958],
        [-93.84753847538475, 29.71388621216211],
        [-94.68994689946899, 29.47946294242942],
        [-95.60075600756008, 28.737990822608225],
        [-96.59436594365944, 28.307346593765928],
        [-97.14157141571415, 27.82981771097711],
        [-97.36837368373683, 27.380072326823267],
        [-97.37917379173791, 26.690694266942657],
        [-97.32877328773287, 26.209692446824462],
        [-97.14157141571415, 25.869344588545886],
        [-97.52677526775267, 24.992427912879123],
        [-97.70317703177031, 24.27179341703416],
        [-97.77517775177752, 22.932976076560763],
        [-97.87237872378724, 22.445028381783814],
        [-97.69957699576996, 21.898040752407525],
        [-97.38997389973899, 21.411829526295264],
        [-97.18837188371883, 20.635628033180325],
        [-96.5259652596526, 19.89068297602975],
        [-96.2919629196292, 19.32112125401254],
        [-95.89955899558996, 18.827964153241524],
        [-94.83754837548375, 18.56228444754447],
        [-94.42714427144271, 18.143795499354994],
        [-93.54873548735488, 18.42336695436954],
        [-92.78552785527854, 18.524082136921365],
        [-92.03672036720367, 18.704674878048777],
        [-91.40671406714067, 18.876585275852747],
        [-90.7731077310773, 19.28465541205412],
        [-90.53190531905318, 19.868108883388828],
        [-90.45270452704527, 20.706823248432485],
        [-90.279902799028, 21.000286452764527],
        [-89.6030960309603, 21.262493221132203],
        [-88.54468544685446, 21.493443553535528],
        [-87.6590765907659, 21.4587141802418],
        [-87.05067050670506, 21.54380114481144],
        [-86.8130681306813, 21.331951967719675],
        [-86.84546845468455, 20.849213678936778],
        [-87.38187381873819, 20.255341395613954],
        [-87.61947619476194, 19.645840894308932],
        [-87.43587435874359, 19.472194027840274],
        [-87.58707587075871, 19.039813330333303],
        [-87.83547835478355, 18.260138899889],
        [-88.09108091080911, 18.51713626226261],
        [-88.29988299882999, 18.499771575615753],
        [-88.29628296282962, 18.35390820778207],
        [-88.10548105481054, 18.348698801788018],
        [-88.12348123481235, 18.07607322143221],
        [-88.28548285482854, 17.64369252392524],
        [-88.1990819908199, 17.489146812768126],
        [-88.30348303483035, 17.13143426784268],
        [-88.23868238682387, 17.035928491284906],
        [-88.35388353883539, 16.5306161098611],
        [-88.55188551885519, 16.264936404164033],
        [-88.73188731887319, 16.23367996819968],
        [-88.92988929889299, 15.888122703927039],
        [-88.60588605886059, 15.705793494134937],
        [-88.51948519485194, 15.855129799297984],
        [-88.22428224282243, 15.72836758677586],
        [-88.11988119881198, 15.688428807488066],
        [-87.90027900279003, 15.863812142621427],
        [-87.61587615876158, 15.879440360603596],
        [-87.52227522275223, 15.797826333363332],
        [-87.36747367473674, 15.846447455974555],
        [-86.9030690306903, 15.756151085410849],
        [-86.44226442264423, 15.78219811538115],
        [-86.11826118261182, 15.89333210992109],
        [-86.00306003060031, 16.00620257312572],
        [-85.68265682656826, 15.954108513185133],
        [-85.4450544505445, 15.88638623526235],
        [-85.18225182251823, 15.908960327903273],
        [-84.98424984249843, 15.995783761137602],
        [-84.5270452704527, 15.856866267962673],
        [-84.36864368643685, 15.83429217532175],
        [-84.06264062640626, 15.648490028200271],
        [-83.77463774637746, 15.424485570455701],
        [-83.4110341103411, 15.271676327963277],
        [-83.14823148231481, 14.995577810278093],
        [-83.23463234632347, 14.900072033720335],
        [-83.2850328503285, 14.67606757597575],
        [-83.18063180631806, 14.311409156391562],
        [-83.4110341103411, 13.969324829448283],
        [-83.5190351903519, 13.568200567905677],
        [-83.55143551435513, 13.127137527075263],
        [-83.49743497434974, 12.870140164701638],
        [-83.47223472234722, 12.418658311883107],
        [-83.6270362703627, 12.32141606666066],
        [-83.72063720637206, 11.892508306483066],
        [-83.65223652236521, 11.628565069450687],
        [-83.85383853838538, 11.373304175741751],
        [-83.81063810638106, 11.102415064050632],
        [-83.65583655836558, 10.939187009570091],
        [-83.40383403834038, 10.395672317523164],
        [-83.0150301503015, 9.99281158731587],
        [-82.5470254702547, 9.56564029580295],
        [-82.1870218702187, 9.207927750877502],
        [-82.20862208622086, 8.996078573785738],
        [-81.8090180901809, 8.950930388503878],
        [-81.71541715417153, 9.032544415744155],
        [-81.43821438214381, 8.785965865358648],
        [-80.94860948609485, 8.858897549275483],
        [-80.52020520205201, 9.110685505655056],
        [-79.91539915399153, 9.312115870758703],
        [-79.57339573395734, 9.61078848108481],
        [-79.02259022590225, 9.553485015150144],
        [-79.05859058590586, 9.45450630126301],
        [-78.50058500585006, 9.41977692796928],
        [-78.05418054180541, 9.247866530165297],
        [-77.73017730177301, 8.9474574511745],
        [-77.35217352173521, 8.67135893348933],
        [-76.83736837368373, 8.63836602886029],
        [-76.08496084960849, 9.336426432064314],
        [-75.67455674556746, 9.444087489274892],
        [-75.66375663756637, 9.774016535565352],
        [-75.48015480154801, 10.619676775267749],
        [-74.90774907749078, 11.083313908739086],
        [-74.27774277742778, 11.102415064050632],
        [-74.19854198541985, 11.310791303813033],
        [-73.41373413734136, 11.227440807908067],
        [-72.62892628926289, 11.732753189331888],
        [-72.23652236522365, 11.955021178411783],
        [-71.7541175411754, 12.437759467194667],
        [-71.40131401314012, 12.37524659526595],
        [-71.13851138511384, 12.113039826898259],
        [-71.33291332913329, 11.77616490594906],
        [-71.36171361713616, 11.54000516755167],
        [-71.94851948519485, 11.423661767017663],
        [-71.62091620916209, 10.968706976869768],
        [-71.63171631716317, 10.446029908799076],
        [-72.07452074520745, 9.866049374793747],
        [-71.69651696516965, 9.07248319503195],
        [-71.26451264512644, 9.136732535625356],
        [-71.04131041310413, 9.86083996879968],
        [-71.35091350913508, 10.211606639066389],
        [-71.40131401314012, 10.968706976869768],
        [-70.15570155701556, 11.37504064440644],
        [-70.29250292502924, 11.847360121201206],
        [-69.94329943299432, 12.161660949509496],
        [-69.58329583295833, 11.46012760897608],
        [-68.88128881288813, 11.442762922329223],
        [-68.23328233282332, 10.885356480964802],
        [-68.19368193681936, 10.555427434674343],
        [-67.29727297272973, 10.545008622686225],
        [-66.22806228062281, 10.649196742567426],
        [-65.65565655656556, 10.201187827078272],
        [-64.88884888848888, 10.07789855188551],
        [-64.33084330843307, 10.390462911529113],
        [-64.31644316443165, 10.642250867908672],
        [-63.0780307803078, 10.701290802508026],
        [-61.87921879218791, 10.715182551825507],
        [-62.72882728827288, 10.41998287882879],
        [-62.38682386823868, 9.94766340203401],
        [-61.58761587615875, 9.872995249452487],
        [-60.83160831608316, 9.381574617346175],
        [-60.66960669606695, 8.579326094260935],
        [-60.15120151201512, 8.601900186901858],
        [-59.75879758797588, 8.36747691716917],
        [-59.09999099990999, 7.999345560255591],
        [-58.48438484384843, 7.348169810998101],
        [-58.45558455584555, 6.8324386175861775],
        [-58.07758077580776, 6.80986452494524],
        [-57.54117541175411, 6.321916830168291],
        [-57.14877148771487, 5.972886628566286],
        [-55.949959499594996, 5.773192732127313],
        [-55.84195841958419, 5.953785473254726],
        [-55.031950319503196, 6.024980688506886],
        [-53.95913959139591, 5.755828045480456],
        [-53.61713617136171, 5.64643051960519],
        [-52.882728827288275, 5.410270781207814],
        [-51.82431824318243, 4.566347010170091],
        [-51.658716587165856, 4.1565404053040425],
        [-51.31671316713167, 4.203425059250591],
        [-51.06831068310683, 3.651228023880236],
        [-50.510305103051024, 1.9008676098760873],
        [-49.973899738997375, 1.7359030867308576],
        [-49.948699486994855, 1.0465250268502615],
        [-50.69750697506976, 0.2234388797887874],
        [-50.38790387903879, -0.07870666786668323],
        [-48.62028620286202, -0.23498884768848427],
        [-48.584285842858435, -1.2386677358773568],
        [-47.82467824678247, -0.5822825806258152],
        [-46.56826568265683, -0.9417315942159519],
        [-44.905049050490504, -1.5512320955209589],
        [-44.419044190441895, -2.138158504185043],
        [-44.58104581045811, -2.6920920082200865],
        [-43.41823418234182, -2.3830005859058616],
        [-41.47421474214741, -2.9126235286352937],
        [-39.980199801998026, -2.872684749347499],
        [-38.50058500585004, -3.700980302403025],
        [-37.222572225722246, -4.821002591125918],
        [-36.452164521645216, -5.109256389463894],
        [-35.5989559895599, -5.149195168751689],
        [-35.235352353523524, -5.465232465724668],
        [-34.8969489694897, -6.738063996939971],
        [-34.73134731347312, -7.342355092250926],
        [-35.127351273512744, -8.997209729697303],
        [-35.638556385563845, -9.650121947619482],
        [-37.046170461704605, -11.04103334803348],
        [-37.683376833768335, -12.17147444874449],
        [-38.42498424984248, -13.037972312423122],
        [-38.673386733867346, -13.057073467734682],
        [-38.9541895418954, -13.793336181561813],
        [-38.88218882188821, -15.666985870758708],
        [-39.15939159391593, -17.208970045000456],
        [-39.267392673926736, -17.867091668916686],
        [-39.584195841958405, -18.263006524465254],
        [-39.760597605976045, -19.598350927609275],
        [-40.775807758077576, -20.904175363453632],
        [-40.94500945009449, -21.937374218942196],
        [-41.755017550175495, -22.371491385113856],
        [-41.989019890198904, -22.970573074430746],
        [-43.07623076230763, -22.96710013710137],
        [-44.64944649446494, -23.352596180661806],
        [-45.35145351453514, -23.797132158821597],
        [-46.47106471064711, -24.08885889448895],
        [-47.64827648276483, -24.885898011580124],
        [-48.49428494284942, -25.87742161911619],
        [-48.641886418864175, -26.624103144931453],
        [-48.47628476284763, -27.17630018030181],
        [-48.659886598865995, -28.186924943149435],
        [-48.886688866888676, -28.674872637926384],
        [-49.588695886958874, -29.22533320463205],
        [-50.69750697506976, -30.98437596195962],
        [-51.575915759157596, -31.777942141721418],
        [-52.25632256322562, -32.245052212522126],
        [-52.71352713527135, -33.19663704077041],
        [-53.372333723337235, -33.76793523145232],
      ],
      [
        [-54.39834398343983, 4.21210740257402],
        [-54.00594005940059, 3.6199715879158703],
        [-54.18234182341823, 3.1893273590735873],
        [-54.268742687426865, 2.7326361002610042],
        [-54.52434524345243, 2.3124106834068243],
        [-54.27234272342723, 2.7395819749197443],
        [-54.18594185941859, 3.194536765067639],
        [-54.013140131401315, 3.621708056580559],
        [-54.39834398343983, 4.21210740257402],
      ],
    ],
    [
      [
        [-59.57159571595716, -80.0396157393574],
        [-59.86679866798667, -80.55013752677527],
        [-60.158401584015834, -80.9998829109291],
        [-62.253622536225365, -80.86270188641886],
        [-64.48924489244892, -80.92174182101822],
        [-65.7420574205742, -80.58833983739838],
        [-65.7420574205742, -80.55013752677527],
        [-66.28926289262893, -80.25493785377854],
        [-64.03924039240393, -80.29487663306634],
        [-61.88281882818828, -80.39211887828878],
        [-61.13761137611375, -79.98057580475805],
        [-60.60840608406083, -79.62807266582666],
        [-59.57159571595716, -80.0396157393574],
      ],
    ],
    [
      [
        [-159.20979209792097, -79.49783751597516],
        [-161.12861128611286, -79.6350185404854],
        [-162.4390243902439, -79.28077893288933],
        [-163.0258302583026, -78.92827579395794],
        [-163.06543065430654, -78.8692358593586],
        [-163.71343713437133, -78.5948738103381],
        [-163.10503105031052, -78.22326951609516],
        [-161.2438124381244, -78.37955169591696],
        [-160.24660246602465, -78.69385252422524],
        [-159.48339483394835, -79.04635566315663],
        [-159.20979209792097, -79.49783751597516],
      ],
    ],
    [
      [
        [-45.15345153451534, -78.04788618096181],
        [-43.92223922239222, -78.4785304098041],
        [-43.490234902349016, -79.08629444244443],
        [-43.371433714337144, -79.51693867128671],
        [-43.33183331833317, -80.02572399003991],
        [-44.879848798487984, -80.34002481834818],
        [-46.5070650706507, -80.59354924339243],
        [-48.38628386283861, -80.82970898178982],
        [-50.48150481504814, -81.0259299408994],
        [-52.85032850328503, -80.96689000630006],
        [-54.164341643416435, -80.63348802268023],
        [-53.987939879398795, -80.22194494914949],
        [-51.853118531185316, -79.947582900129],
        [-50.99270992709927, -79.61418091650917],
        [-50.36630366303663, -79.18353668766687],
        [-49.91269912699127, -78.81193239342393],
        [-49.30789307893079, -78.45942925449255],
        [-48.659886598865995, -78.04614971229712],
        [-48.659886598865995, -78.04788618096181],
        [-48.15228152281523, -78.04788618096181],
        [-46.66186661866618, -77.83082759787598],
        [-45.15345153451534, -78.04788618096181],
      ],
    ],
    [
      [
        [-121.21141211412115, -73.50181121681217],
        [-119.9189991899919, -73.65809339663397],
        [-118.72378723787239, -73.48097359283594],
        [-119.29259292592926, -73.83347673176732],
        [-120.23220232202323, -74.08873762547626],
        [-121.62181621816218, -74.01059653556536],
        [-122.62262622626227, -73.65809339663397],
        [-122.40662406624065, -73.32469141301414],
        [-121.21141211412115, -73.50181121681217],
      ],
    ],
    [
      [
        [-125.56025560255603, -73.48097359283594],
        [-124.03024030240303, -73.87341551105511],
        [-124.62064620646206, -73.83347673176732],
        [-125.91305913059131, -73.73623448654487],
        [-127.28467284672845, -73.46187243752438],
        [-126.55746557465574, -73.24655032310324],
        [-125.56025560255603, -73.48097359283594],
      ],
    ],
    [
      [
        [-98.98118981189812, -71.93378001260012],
        [-97.88317883178831, -72.07096103711038],
        [-96.78876788767887, -71.95288116791168],
        [-96.2019620196202, -72.52070642126421],
        [-96.98316983169832, -72.44256533135331],
        [-98.19638196381963, -72.4825041106411],
        [-99.43119431194312, -72.44256533135331],
        [-100.78480784807847, -72.50160526595266],
        [-101.80361803618035, -72.30538430684307],
        [-102.32922329223292, -71.89384123331233],
        [-101.70281702817027, -71.71845789817898],
        [-100.43200432004319, -71.85563892268922],
        [-98.98118981189812, -71.93378001260012],
      ],
    ],
    [
      [
        [-68.45288452884529, -70.95614815438154],
        [-68.3340833408334, -71.4058935385354],
        [-68.51048510485104, -71.79833545675457],
        [-68.7840878408784, -72.16993975099751],
        [-69.95769957699576, -72.30712077550776],
        [-71.07731077310773, -72.50334173461735],
        [-72.38772387723877, -72.48424057930579],
        [-71.89811898118981, -72.09179866108661],
        [-73.07533075330753, -72.22897968559685],
        [-74.19134191341914, -72.3661607101071],
        [-74.95454954549545, -72.07269750577505],
        [-75.01215012150121, -71.66115443224433],
        [-73.91413914139142, -71.26871251402514],
        [-73.23013230132301, -71.15236911349113],
        [-72.07452074520745, -71.19057142411424],
        [-71.77931779317792, -70.68178610536106],
        [-71.72171721717217, -70.30844534245342],
        [-71.74331743317433, -69.5061968193682],
        [-71.17451174511744, -69.03561381123811],
        [-70.25290252902529, -68.8793316314163],
        [-69.72369723697237, -69.25093592565926],
        [-69.48969489694896, -69.62254021990219],
        [-69.05769057690577, -70.07402207272072],
        [-68.72648726487265, -70.50466630156302],
        [-68.45288452884529, -70.95614815438154],
      ],
    ],
    [
      [
        [-180, -84.71418938469385],
        [-179.94239942399423, -84.7211352593526],
        [-179.06039060390603, -84.13941825668256],
        [-177.25677256772568, -84.45371908499085],
        [-177.14157141571417, -84.41725324303243],
        [-176.08316083160832, -84.09947947739478],
        [-175.94635946359463, -84.1098982893829],
        [-175.83115831158312, -84.11858063270633],
        [-174.3839438394384, -84.53359664356644],
        [-173.11673116731168, -84.11858063270633],
        [-172.889928899289, -84.06127716677167],
        [-169.95229952299522, -83.88415736297362],
        [-168.99828998289982, -84.11858063270633],
        [-168.53028530285303, -84.23666050190502],
        [-167.0218702187022, -84.57006248552486],
        [-164.18144181441815, -84.8253233792338],
        [-161.93141931419314, -85.1378877388774],
        [-158.07218072180723, -85.37404747727477],
        [-155.1921519215192, -85.09968542825428],
        [-150.94050940509405, -85.29590638736387],
        [-148.5320853208532, -85.60847074700747],
        [-145.88965889658897, -85.31500754267543],
        [-143.10683106831067, -85.04064549365494],
        [-142.89082890828908, -84.57006248552486],
        [-146.8292682926829, -84.53186017490175],
        [-150.0621006210062, -84.29570043650436],
        [-150.90450904509044, -83.90499498694987],
        [-153.58653586535866, -83.68793640386404],
        [-153.41013410134101, -83.2381910197102],
        [-153.03933039330394, -82.82664794617946],
        [-152.66492664926648, -82.45504365193652],
        [-152.8629286292863, -82.04350057840578],
        [-154.5261452614526, -81.76913852938529],
        [-155.28935289352893, -81.41489892178922],
        [-156.83736837368374, -81.10233456214561],
        [-154.40734407344073, -81.16137449674497],
        [-152.0961209612096, -81.00335584825848],
        [-150.6489064890649, -81.33675783187832],
        [-148.8668886688867, -81.04329462754627],
        [-147.22167221672217, -80.67169033330333],
        [-146.4188641886419, -80.3382883496835],
        [-146.77166771667717, -79.92674527615276],
        [-148.0640806408064, -79.65238322713228],
        [-149.5328953289533, -79.35892002280023],
        [-151.58851588515884, -79.29988008820088],
        [-153.38853388533886, -79.16269906369064],
        [-155.3289532895329, -79.0637203498035],
        [-155.9769597695977, -78.69211605556056],
        [-157.26937269372695, -78.37781522725227],
        [-158.05058050580504, -78.02531208832089],
        [-158.36378363783638, -76.88966158161581],
        [-157.87417874178743, -76.98690382683827],
        [-156.9741697416974, -77.30120465514655],
        [-155.3289532895329, -77.20222594125941],
        [-153.74133741337414, -77.06504491674917],
        [-152.92052920529204, -77.49742561425614],
        [-151.3329133291333, -77.39844690036901],
        [-150.00090000900008, -77.18312478594785],
        [-148.7480874808748, -76.90876273692737],
        [-147.6140761407614, -76.57536075330754],
        [-146.10566105661056, -76.47811850808509],
        [-146.14526145261453, -76.10477774517746],
        [-146.49446494464945, -75.73317345093452],
        [-146.2028620286203, -75.38067031200312],
        [-144.91044910449105, -75.20355050820508],
        [-144.32364323643236, -75.53695249182492],
        [-142.79362793627936, -75.34073153271532],
        [-141.6380163801638, -75.08720710767108],
        [-140.2088020880209, -75.06636948369484],
        [-138.85878858788587, -74.96912723847238],
        [-137.5051750517505, -74.732967500075],
        [-136.42876428764288, -74.51764538565385],
        [-135.21555215552155, -74.30232327123271],
        [-134.43074430744306, -74.36136320583206],
        [-133.74673746737466, -74.43950429574296],
        [-132.25632256322564, -74.30232327123271],
        [-130.92430924309244, -74.47944307503076],
        [-129.55269552695526, -74.45860545105451],
        [-128.24228242282422, -74.32142442654427],
        [-126.89226892268923, -74.4204031404314],
        [-125.40185401854018, -74.51764538565385],
        [-124.01224012240122, -74.47944307503076],
        [-122.56142561425614, -74.4985442303423],
        [-121.07461074610745, -74.51764538565385],
        [-119.7029970299703, -74.47944307503076],
        [-118.68418684186841, -74.18424340203401],
        [-117.4709747097471, -74.02796122221223],
        [-116.21456214562146, -74.24328333663337],
        [-115.0229502295023, -74.06790000150002],
        [-113.94293942939429, -73.71539686256862],
        [-113.29853298532986, -74.02796122221223],
        [-112.94572945729458, -74.38046436114361],
        [-112.29772297722977, -74.71386634476345],
        [-111.2609126091261, -74.4204031404314],
        [-110.06570065700657, -74.79200743467435],
        [-108.71568715687157, -74.91008730387304],
        [-107.56007560075601, -75.18444935289352],
        [-106.14886148861488, -75.12540941829418],
        [-104.87444874448744, -74.95002608316084],
        [-103.36963369633696, -74.98822839378394],
        [-102.0160201602016, -75.12540941829418],
        [-100.64440644406444, -75.30252922209222],
        [-100.11520115201152, -74.87014852458525],
        [-100.76320763207632, -74.5384830096301],
        [-101.25281252812528, -74.18424340203401],
        [-102.54522545225451, -74.10610231212313],
        [-103.1140311403114, -73.73449801788018],
        [-103.330033300333, -73.36289372363724],
        [-103.68283682836828, -72.61794866648667],
        [-102.9160291602916, -72.75512969099691],
        [-101.60561605616056, -72.81416962559626],
        [-100.31320313203132, -72.75512969099691],
        [-99.13599135991359, -72.91141187081871],
        [-98.11718117181171, -73.20487507515075],
        [-97.68877688776888, -73.55737821408215],
        [-96.33516335163351, -73.61641814868149],
        [-95.04275042750427, -73.47923712417125],
        [-93.67113671136711, -73.28301616506165],
        [-92.43992439924399, -73.16667276452765],
        [-91.42111421114211, -73.40109603426035],
        [-90.08910089100891, -73.32295494434945],
        [-89.22869228692286, -72.55890873188733],
        [-88.42228422284222, -73.00865411604116],
        [-87.26667266672666, -73.18577391983919],
        [-86.01386013860139, -73.08853167461675],
        [-85.1930519305193, -73.47923712417125],
        [-83.8790387903879, -73.51917590345903],
        [-82.66582665826658, -73.63725577265772],
        [-81.47061470614706, -73.85257788707887],
        [-80.68580685806857, -73.47923712417125],
        [-80.2970029700297, -73.12673398523985],
        [-79.29619296192962, -73.51917590345903],
        [-77.92457924579246, -73.4201971895719],
        [-76.90576905769058, -73.63725577265772],
        [-76.22176221762217, -73.96892128761287],
        [-74.88974889748897, -73.87167904239043],
        [-73.85293852938528, -73.65635692796928],
        [-72.8341283412834, -73.40109603426035],
        [-71.62091620916209, -73.2639150097501],
        [-70.20970209702097, -73.14583514055141],
        [-68.93528935289352, -73.00865411604116],
        [-67.9560795607956, -72.79333200162002],
        [-67.36927369273693, -72.48076764197643],
        [-67.13527135271352, -72.04838694446944],
        [-67.25047250472504, -71.6385803396034],
        [-67.56367563675636, -71.24613842138422],
        [-67.91647916479164, -70.85369650316503],
        [-68.22968229682296, -70.46125458494585],
        [-68.48528485284852, -70.10875144601447],
        [-68.54288542885429, -69.71804599645996],
        [-68.44568445684456, -69.32560407824079],
        [-67.97767977679776, -68.95399978399784],
        [-67.58527585275853, -68.5424567104671],
        [-67.42687426874268, -68.15001479224793],
        [-67.62487624876249, -67.71937056340563],
        [-67.740077400774, -67.32692864518646],
        [-67.25047250472504, -66.87544679236792],
        [-66.70326703267033, -66.58198358803588],
        [-66.05526055260552, -66.21037929379294],
        [-65.37125371253713, -65.89607846548466],
        [-64.56844568445685, -65.60261526115261],
        [-64.1760417604176, -65.17197103231032],
        [-63.628836288362876, -64.89760898328984],
        [-63.00243002430024, -64.64234808958089],
        [-62.04122041220411, -64.58330815498155],
        [-61.414814148141474, -64.27074379533795],
        [-60.70920709207091, -64.07452283622837],
        [-59.88839888398884, -63.95644296702967],
        [-59.16119161191611, -63.70118207332074],
        [-58.59598595985959, -63.388617713677135],
        [-57.81117811178112, -63.27053784447845],
        [-57.22437224372243, -63.52579873818738],
        [-57.595175951759515, -63.85920072180722],
        [-58.613986139861396, -64.15266392613927],
        [-59.0459904599046, -64.36798604056041],
        [-59.78759787597876, -64.21170386073861],
        [-60.612006120061196, -64.30894610596106],
        [-61.2960129601296, -64.54510584435845],
        [-62.02322023220232, -64.7986302694027],
        [-62.51282512825128, -65.09382994239942],
        [-62.64962649626496, -65.48453539195393],
        [-62.58842588425884, -65.85787615486154],
        [-62.120421204212036, -66.18954166981669],
        [-62.80442804428044, -66.42570140821408],
        [-63.7440374403744, -66.50384249812498],
        [-64.29484294842948, -66.83724448174482],
        [-64.88164881648817, -67.14980884138842],
        [-65.5080550805508, -67.58218953889539],
        [-65.66645666456664, -67.95379383313833],
        [-65.31365313653136, -68.36533690666907],
        [-64.78444784447844, -68.67963773497735],
        [-63.960039600396, -68.91406100471005],
        [-63.196831968319685, -69.22836183301834],
        [-62.786427864278636, -69.61906728257283],
        [-62.57042570425703, -69.99240804548046],
        [-62.27522275222752, -70.38311349503495],
        [-61.80721807218072, -70.71651547865478],
        [-61.51201512015119, -71.08985624156242],
        [-61.37521375213751, -72.01018463384634],
        [-61.08361083610836, -72.38178892808928],
        [-61.00441004410044, -72.77423084630846],
        [-60.69120691206912, -73.16667276452765],
        [-60.8280082800828, -73.69455923859239],
        [-61.37521375213751, -74.10610231212313],
        [-61.9620196201962, -74.43950429574296],
        [-63.2940329403294, -74.5766853202532],
        [-63.7440374403744, -74.92918845918459],
        [-64.35244352443524, -75.26259044280442],
        [-65.86085860858608, -75.63593120571205],
        [-67.19287192871928, -75.79221338553386],
        [-68.44568445684456, -76.007535499955],
        [-69.79929799297993, -76.22285761437614],
        [-70.60210602106021, -76.63440068790688],
        [-72.20772207722077, -76.67433946719467],
        [-73.9681396813968, -76.63440068790688],
        [-75.55575555755557, -76.71254177781778],
        [-77.24057240572405, -76.71254177781778],
        [-76.92736927369273, -77.10498369603695],
        [-75.40095400954009, -77.28036703117031],
        [-74.28134281342813, -77.55472908019081],
        [-73.65493654936549, -77.90896868778688],
        [-74.77094770947708, -78.22153304743047],
        [-76.49536495364953, -78.12429080220802],
        [-77.92457924579246, -78.37781522725227],
        [-77.98577985779858, -78.78935830078301],
        [-78.02538025380254, -79.18180021900218],
        [-76.84816848168481, -79.51520220262202],
        [-76.6321663216632, -79.88680649686496],
        [-75.36135361353614, -80.2601472597726],
        [-73.24453244532445, -80.4164294395944],
        [-71.44451444514445, -80.69079148861489],
        [-70.01170011700117, -81.00335584825848],
        [-68.19008190081901, -81.31765667656677],
        [-65.7060570605706, -81.47393885638857],
        [-63.25443254432544, -81.74830090540905],
        [-61.55161551615515, -82.04350057840578],
        [-59.69039690396903, -82.37516609336093],
        [-58.71118711187111, -82.84574910149101],
        [-58.22158221582215, -83.21908986439864],
        [-57.00837008370084, -82.86485025680257],
        [-55.363153631536306, -82.57138705247053],
        [-53.62073620736207, -82.25882269282693],
        [-51.54351543515435, -82.00356179911799],
        [-49.76149761497615, -81.72919975009751],
        [-47.27387273872739, -81.71009859478595],
        [-44.82584825848258, -81.84727961929619],
        [-42.809828098280974, -82.08170288902889],
        [-42.16182161821618, -81.6510586601866],
        [-40.77220772207721, -81.35759545585456],
        [-38.244982449824505, -81.33675783187832],
        [-36.26496264962648, -81.12143571745717],
        [-34.38574385743857, -80.90611360303603],
        [-32.30852308523086, -80.76893257852578],
        [-30.098100981009793, -80.59181277472774],
        [-28.550085500855005, -80.3382883496835],
        [-29.255692556925567, -79.98578521075211],
        [-29.684096840968408, -79.63328207182072],
        [-29.684096840968408, -79.25994130891308],
        [-31.624516245162454, -79.29988008820088],
        [-33.68013680136801, -79.45616226802268],
        [-35.638556385563845, -79.45616226802268],
        [-35.915759157591566, -79.08455797377974],
        [-35.77535775357754, -78.33961291662916],
        [-35.32535325353254, -78.12429080220802],
        [-33.896138961389596, -77.88813106381063],
        [-32.211322113221115, -77.65370779407795],
        [-30.998109981099816, -77.3602445897459],
        [-29.78489784897849, -77.06504491674917],
        [-28.88128881288813, -76.67433946719467],
        [-27.513275132751318, -76.49721966339663],
        [-26.159661596615962, -76.3600386388864],
        [-25.475654756547556, -76.2818975489755],
        [-23.927639276392767, -76.2419587696877],
        [-22.458824588245875, -76.10477774517746],
        [-21.22401224012239, -75.91029325473255],
        [-20.010800108001064, -75.67413351633516],
        [-18.912789127891273, -75.43971024660246],
        [-17.523175231752305, -75.12540941829418],
        [-16.641166411664102, -74.79200743467435],
        [-15.70155701557016, -74.4985442303423],
        [-15.406354063540618, -74.10610231212313],
        [-16.46476464764646, -73.87167904239043],
        [-16.11196111961118, -73.46013596885969],
        [-15.445954459544595, -73.14583514055141],
        [-14.409144091440908, -72.9513506501065],
        [-13.311133111331117, -72.71519091170912],
        [-12.292322923229221, -72.40262655206553],
        [-11.5111151111511, -72.01018463384634],
        [-11.021510215102154, -71.53960162571626],
        [-10.294302943029436, -71.26523957669576],
        [-9.102691026910264, -71.32427951129512],
        [-8.61308613086129, -71.65768149491495],
        [-7.4178741787417835, -71.69588380553806],
        [-7.378273782737807, -71.32427951129512],
        [-6.867068670686706, -70.93183759307593],
        [-5.790657906579071, -71.03081630696308],
        [-5.535055350553506, -71.40242060120602],
        [-4.3434434344343344, -71.46146053580536],
        [-3.047430474304747, -71.28434073200732],
        [-1.794617946179443, -71.16799733147332],
        [-0.660606606066068, -71.22703726607266],
        [-0.2286022860228627, -71.6385803396034],
        [0.8694086940869568, -71.30517835598356],
        [1.8882188821888235, -71.12805855218552],
        [3.022230222302227, -70.99087752767528],
        [4.138241382413838, -70.85369650316503],
        [5.157051570515705, -70.61927323343234],
        [6.273062730627316, -70.46125458494585],
        [7.137071370713727, -70.2459324705247],
        [7.74187741877418, -69.89342933159332],
        [8.487084870848719, -70.14869022530226],
        [9.523895238952406, -70.011509200792],
        [10.251102511025124, -70.4820922089221],
        [10.81630816308163, -70.83459534785348],
        [11.953919539195397, -70.63837438874388],
        [12.403924039240394, -70.2459324705247],
        [13.42273422734229, -69.97157042150421],
        [14.736747367473669, -70.03061035610357],
        [15.125551255512562, -70.4039511190112],
        [15.949959499594996, -70.03061035610357],
        [17.02637026370263, -69.91253048690487],
        [18.19998199982001, -69.87432817628176],
        [19.258392583925854, -69.89342933159332],
        [20.374403744037437, -70.011509200792],
        [21.454414544145436, -70.07054913539136],
        [21.922419224192254, -70.4039511190112],
        [22.570425704257048, -70.69741432334324],
        [23.664836648366503, -70.52029451954519],
        [24.84204842048422, -70.4820922089221],
        [25.97605976059762, -70.4820922089221],
        [27.092070920709205, -70.46125458494585],
        [28.092880928809308, -70.32407356043561],
        [29.151291512915122, -70.2077301599016],
        [30.033300333003325, -69.9333681108811],
        [30.972909729097296, -69.75624830708307],
        [31.99171991719919, -69.65900606186062],
        [32.75492754927549, -69.38464401284013],
        [33.302133021330235, -68.83591991479915],
        [33.870938709387104, -68.50251793117931],
        [34.90774907749079, -68.65880011100111],
        [35.30015300153002, -69.0113032499325],
        [36.16056160561607, -69.24746298832989],
        [37.20097200972012, -69.16932189841899],
        [37.90657906579065, -69.52182503735037],
        [38.648186481864826, -69.77534946239463],
        [39.66699666996672, -69.54092619266193],
        [40.019800198001974, -69.11028196381963],
        [40.919809198092, -68.9331621600216],
        [41.96021960219602, -68.59976017640176],
        [42.93942939429394, -68.46257915189152],
        [44.11304113041132, -68.26809466144661],
        [44.897848978489804, -68.05103607836078],
        [45.718657186571875, -67.8166128086281],
        [46.50346503465036, -67.60129069420694],
        [47.4430744307443, -67.71937056340563],
        [48.34308343083433, -67.36686742447425],
        [48.99108991089912, -67.09250537545375],
        [49.93069930699309, -67.1116065307653],
        [50.755107551075525, -66.87544679236792],
        [50.94950949509496, -66.52294365343653],
        [51.79191791917921, -66.24858160441605],
        [52.61272612726128, -66.05236064530645],
        [53.61353613536136, -65.89607846548466],
        [54.53514535145354, -65.81793737557376],
        [55.413554135541375, -65.8769773101731],
        [56.35676356763568, -65.97421955539556],
        [57.15957159571596, -66.24858160441605],
        [57.256772567725676, -66.68096230192302],
        [58.13878138781388, -67.01262781687817],
        [58.74358743587436, -67.28698986589866],
        [59.938799387993896, -67.40506973509736],
        [60.60480604806048, -67.67943178411784],
        [61.429214292142916, -67.95379383313833],
        [62.38682386823868, -68.01283376773767],
        [63.189631896318986, -67.8166128086281],
        [64.05364053640537, -67.40506973509736],
        [64.99324993249934, -67.6203918495185],
        [65.97245972459726, -67.7384717187172],
        [66.91206912069123, -67.85655158791587],
        [67.89127891278915, -67.93469267782677],
        [68.88848888488886, -67.93469267782677],
        [69.71289712897129, -68.97310093930939],
        [69.67329673296734, -69.22836183301834],
        [69.55449554495547, -69.67810721717217],
        [68.59688596885971, -69.9333681108811],
        [67.81207812078122, -70.30497240512405],
        [67.94887948879489, -70.69741432334324],
        [69.0648906489065, -70.67831316803168],
        [68.9280892808928, -71.06901861758618],
        [68.42048420484207, -71.4423593804938],
        [67.94887948879489, -71.85390245402454],
        [68.71208712087122, -72.16646681366814],
        [69.86769867698678, -72.26544552755527],
        [71.02331023310234, -72.08832572375724],
        [71.57411574115741, -71.69588380553806],
        [71.90531905319054, -71.32427951129512],
        [72.45612456124562, -71.00997868298683],
        [73.08253082530825, -70.71651547865478],
        [73.33453334533345, -70.3640123397234],
        [73.86373863738638, -69.87432817628176],
        [74.49014490144901, -69.77534946239463],
        [75.62775627756278, -69.73714715177152],
        [76.62496624966252, -69.61906728257283],
        [77.64377643776436, -69.46278510275103],
        [78.13338133381336, -69.07034318453185],
        [78.42858428584287, -68.69873889028891],
        [79.11259112591125, -68.32539812738128],
        [80.0918009180092, -68.07187370233703],
        [80.93420934209342, -67.87565274322743],
        [81.4850148501485, -67.5422507596076],
        [82.05022050220504, -67.36686742447425],
        [82.77742777427773, -67.20884877598776],
        [83.7746377463775, -67.3078274898749],
        [84.67464674646749, -67.20884877598776],
        [85.65385653856538, -67.09250537545375],
        [86.75186751867517, -67.14980884138842],
        [87.47547475474755, -66.87544679236792],
        [87.98667986679868, -66.21037929379294],
        [88.35748357483578, -66.48474134281344],
        [88.82908829088291, -66.95532435094351],
        [89.67149671496713, -67.14980884138842],
        [90.6291062910629, -67.22968639996401],
        [91.59031590315902, -67.1116065307653],
        [92.60912609126092, -67.1897476206762],
        [93.54873548735486, -67.20884877598776],
        [94.17514175141753, -67.1116065307653],
        [95.01755017550175, -67.17064646536465],
        [95.78075780757808, -67.3859685797858],
        [96.68076680766808, -67.24878755527556],
        [97.7607776077761, -67.24878755527556],
        [98.67878678786786, -67.1116065307653],
        [99.71919719197194, -67.24878755527556],
        [100.38520385203856, -66.91538557165572],
        [100.89280892808927, -66.58198358803588],
        [101.58041580415806, -66.30762153901539],
        [102.83322833228334, -65.56267648186483],
        [103.47763477634777, -65.69985750637507],
        [104.2408424084241, -65.97421955539556],
        [104.90684906849071, -66.32672269432695],
        [106.18126181261812, -66.93448672696726],
        [107.16047160471607, -66.95532435094351],
        [108.08208082080824, -66.95532435094351],
        [109.15849158491585, -66.83724448174482],
        [110.23490234902351, -66.70006345723458],
        [111.05931059310592, -66.42570140821408],
        [111.74331743317435, -66.13223820388204],
        [112.85932859328597, -66.09229942459424],
        [113.60453604536048, -65.8769773101731],
        [114.38934389343893, -66.0731982692827],
        [114.8969489694897, -66.38576262892629],
        [115.60255602556026, -66.70006345723458],
        [116.70056700567005, -66.66012467794678],
        [117.38457384573849, -66.91538557165572],
        [118.579785797858, -67.17064646536465],
        [119.83259832598327, -67.2678887105871],
        [120.86940869408693, -67.1897476206762],
        [121.65421654216544, -66.87544679236792],
        [122.32022320223206, -66.56288243272434],
        [123.22023220232205, -66.48474134281344],
        [124.12384123841241, -66.62192236732368],
        [125.16065160651607, -66.71916461254612],
        [126.10026100261001, -66.56288243272434],
        [127.00027000270006, -66.56288243272434],
        [127.88227882278824, -66.66012467794678],
        [128.80388803888042, -66.75910339183392],
        [129.7038970389704, -66.58198358803588],
        [130.78030780307802, -66.42570140821408],
        [131.7991179911799, -66.38576262892629],
        [132.9367293672937, -66.38576262892629],
        [133.85473854738547, -66.28852038370384],
        [134.75834758347582, -66.21037929379294],
        [135.0319503195032, -65.7206951303513],
        [135.07155071550716, -65.30915205682057],
        [135.69795697956982, -65.58351410584106],
        [135.87435874358744, -66.0332594899949],
        [136.2055620556206, -66.44480256352564],
        [136.61956619566195, -66.77820454714548],
        [137.46197461974623, -66.95532435094351],
        [138.5959859598596, -66.89628441634417],
        [139.909999099991, -66.87544679236792],
        [140.810008100081, -66.81814332643327],
        [142.12042120421205, -66.81814332643327],
        [143.06363063630636, -66.79730570245702],
        [144.3740437404374, -66.83724448174482],
        [145.490054900549, -66.91538557165572],
        [146.19566195661957, -67.22968639996401],
        [146.00126001260014, -67.60129069420694],
        [146.64566645666457, -67.894753898539],
        [147.72207722077223, -68.13091363693637],
        [148.83808838088385, -68.38443806198062],
        [150.13410134101343, -68.56155786577867],
        [151.48411484114843, -68.71784004560045],
        [152.50292502925032, -68.87412222542225],
        [153.6369363693637, -68.8949598493985],
        [154.2849428494285, -68.56155786577867],
        [155.16695166951672, -68.83591991479915],
        [155.93015930159305, -69.14848427444275],
        [156.81216812168122, -69.38464401284013],
        [158.02538025380255, -69.48188625806259],
        [159.1809918099181, -69.59996612726127],
        [159.67059670596706, -69.99240804548046],
        [160.80820808208085, -70.22683131521316],
        [161.57141571415713, -70.57933445414454],
        [162.68742687426874, -70.73561663396634],
        [163.8430384303843, -70.71651547865478],
        [164.91944919449196, -70.77555541325412],
        [166.11466114661147, -70.75645425794258],
        [167.30987309873098, -70.83459534785348],
        [168.4258842588426, -70.97177637236372],
        [169.4626946269463, -71.20619964209642],
        [170.50310503105032, -71.40242060120602],
        [171.20511205112052, -71.69588380553806],
        [171.089910899109, -72.08832572375724],
        [170.5607056070561, -72.44082886268862],
        [170.1107011070111, -72.89231071550716],
        [169.75789757897581, -73.24481385443855],
        [169.28629286292863, -73.65635692796928],
        [167.9758797587976, -73.81263910779109],
        [167.38907389073893, -74.16514224672247],
        [166.09306093060934, -74.38046436114361],
        [165.64305643056434, -74.7729062793628],
        [164.9590495904959, -75.14451057360574],
        [164.23544235442358, -75.45881140191402],
        [163.82143821438217, -75.87035447544476],
        [163.56943569435697, -76.2419587696877],
        [163.4686346863469, -76.69344062250623],
        [163.49023490234902, -77.06504491674917],
        [164.0590405904059, -77.45748683496835],
        [164.27504275042753, -77.82909112921129],
        [164.7430474304743, -78.18333073680736],
        [166.6042660426604, -78.31877529265293],
        [166.9966699666997, -78.7511559901599],
        [165.19305193051935, -78.9074381699817],
        [163.66663666636668, -79.12276028440284],
        [161.76581765817662, -79.16269906369064],
        [160.92340923409233, -79.73052431704318],
        [160.74700747007472, -80.20110732517325],
        [160.31860318603185, -80.5727116194162],
        [159.78939789397896, -80.94605238232383],
        [161.12141121411213, -81.27771789727898],
        [161.6290162901629, -81.68926097080971],
        [162.48942489424894, -82.06260173371734],
        [163.70623706237063, -82.39600371733718],
        [165.09585095850957, -82.70856807698077],
        [166.6042660426604, -83.02286890528906],
        [168.8938889388894, -83.33543326493265],
        [169.40509405094053, -83.82511742837428],
        [172.28512285122855, -84.04217601146011],
        [172.47592475924762, -84.11858063270633],
        [173.2247322473225, -84.41378030570306],
        [175.9859598595986, -84.15851941199412],
        [178.27558275582754, -84.4728202403024],
        [-180, -84.71418938469385],
      ],
    ],
    [
      [
        [68.93528935289353, -48.625161126511266],
        [69.57969579695799, -48.93946195481955],
        [70.52650526505266, -49.06448769867699],
        [70.55890558905591, -49.25549925179252],
        [70.28170281702819, -49.71045404194042],
        [68.74448744487447, -49.77470338253383],
        [68.71928719287195, -49.243343971139716],
        [68.8668886688867, -48.83006442894429],
        [68.93528935289353, -48.625161126511266],
      ],
    ],
    [
      [
        [145.39645396453966, -40.7919509801098],
        [146.36486364863651, -41.137508244382445],
        [146.90846908469086, -41.000327219872204],
        [147.68967689676896, -40.807579198091986],
        [148.2872828728287, -40.87530147601476],
        [148.35928359283594, -42.06304604266043],
        [148.01728017280175, -42.406866838268385],
        [147.9128791287913, -43.2108518300183],
        [147.5636756367564, -42.9382262496625],
        [146.86886868868692, -43.63455018420184],
        [146.6636666366664, -43.58071965559656],
        [146.04806048060482, -43.5494632196322],
        [145.43245432454324, -42.69338416794168],
        [145.29565295652958, -42.033526075360754],
        [144.71964719647195, -41.16181880568806],
        [144.7448474484745, -40.703391078210785],
        [145.39645396453966, -40.7919509801098],
      ],
    ],
    [
      [
        [143.56043560435603, -13.76381621426215],
        [143.92043920439204, -14.548700050700518],
        [144.56484564845647, -14.17188635046351],
        [144.89604896048962, -14.593848235982364],
        [145.37485374853748, -14.984553685536852],
        [145.27045270452703, -15.427353195031955],
        [145.48645486454865, -16.285168715387158],
        [145.63765637656377, -16.785271690816913],
        [145.88965889658897, -16.90682449734497],
        [146.159661596616, -17.76116708037081],
        [146.06246062460627, -18.28037121111211],
        [146.38646386463864, -18.957593990339902],
        [147.47007470074703, -19.480271058410594],
        [148.17928179281796, -19.956063472534723],
        [148.84888848888488, -20.39191710737107],
        [148.71568715687158, -20.633286251762527],
        [149.28809288092884, -21.260151439714406],
        [149.6768967689677, -22.34197141781418],
        [150.07650076500767, -22.12317636606366],
        [150.48330483304835, -22.555557063570646],
        [150.72810728107282, -22.402747821078208],
        [150.90090900909013, -23.461993706537072],
        [151.610116101161, -24.076703613836145],
        [152.07452074520745, -24.458726720067204],
        [152.8557285572856, -25.267921117811184],
        [153.13653136531366, -26.071906109561098],
        [153.1617316173162, -26.641467831578318],
        [153.09333093330935, -27.259650676206768],
        [153.5685356853569, -28.110520321903223],
        [153.51093510935112, -28.994382872228726],
        [153.33813338133382, -29.458020005700057],
        [153.0681306813068, -30.350564899348996],
        [153.08973089730898, -30.923599558695592],
        [152.89172891728919, -31.640761117211177],
        [152.44892448924492, -32.55067069750698],
        [151.71091710917108, -33.0420913296133],
        [151.3437134371344, -33.81655635406354],
        [151.00891008910088, -34.30971345483455],
        [150.71370713707137, -35.17273838118381],
        [150.32850328503287, -35.671104887948886],
        [150.07650076500767, -36.41952288242883],
        [149.94689946899473, -37.108900942309425],
        [149.99729997299977, -37.4249382392824],
        [149.4248942489425, -37.77223197221973],
        [148.30528305283053, -37.808697814178146],
        [147.38007380073805, -38.218504419044194],
        [146.92286922869232, -38.60573693126931],
        [146.31806318063184, -39.0363811601116],
        [145.490054900549, -38.59358165061651],
        [144.8780487804878, -38.41819831548316],
        [145.0328503285033, -37.895521247412475],
        [144.48564485644857, -38.08479633186332],
        [143.61083610836107, -38.808903765037655],
        [142.74682746827472, -38.538014653346536],
        [142.17802178021782, -38.379996004860054],
        [141.60561605616056, -38.3088007896079],
        [140.63720637206376, -38.01881052260523],
        [139.99279992799927, -37.40236414664147],
        [139.80559805598057, -36.64352734017341],
        [139.57519575195755, -36.13821495874959],
        [139.08199081990819, -35.7336177598776],
        [138.12078120781212, -35.61206495334954],
        [138.44838448384485, -35.12759019590196],
        [138.20718207182074, -34.384381607416074],
        [137.7175771757718, -35.07723260462605],
        [136.82836828368283, -35.26129828308283],
        [137.35397353973542, -34.70736477904779],
        [137.50517505175054, -34.13085718237183],
        [137.89037890378904, -33.64117301893019],
        [137.81117811178115, -32.89970089910899],
        [136.99756997569978, -33.75230701347014],
        [136.3711637116371, -34.09439134041341],
        [135.98955989559897, -34.889693988839895],
        [135.20835208352082, -34.47815091530916],
        [135.2407524075241, -33.948527972579726],
        [134.61434614346143, -33.22268407074071],
        [134.08514085140854, -32.84760683916839],
        [134.27234272342724, -32.61665650676507],
        [132.9907299072991, -32.01062894278943],
        [132.28872288722886, -31.982845444154442],
        [131.3275132751328, -31.496634218042182],
        [129.53469534695347, -31.590403525935265],
        [128.24228242282425, -31.948116070860713],
        [127.10107101071014, -32.28151805448055],
        [126.14706147061474, -32.215532245222455],
        [125.0886508865089, -32.729526969969704],
        [124.22104221042213, -32.95874083370834],
        [124.03024030240306, -33.48315437044371],
        [123.65943659436596, -33.889488037980385],
        [122.809828098281, -33.913798599286],
        [122.18342183421834, -34.0040949698497],
        [121.2978129781298, -33.8217657600576],
        [120.58140581405814, -33.92942681726817],
        [119.8937989379894, -33.976311471214714],
        [119.29979299793001, -34.509407351273516],
        [119.0081900819008, -34.46425916599166],
        [118.5041850418504, -34.74730355833559],
        [118.02538025380255, -35.06507732397324],
        [117.29457294572944, -35.02513854468545],
        [116.62496624966252, -35.02513854468545],
        [115.56295562955631, -34.38611807608076],
        [115.02655026550269, -34.19684299162992],
        [115.04815048150482, -33.623808332283325],
        [115.5449554495545, -33.486627307773084],
        [115.71415714157143, -33.25914991269913],
        [115.67815678156785, -32.89970089910899],
        [115.80055800558006, -32.20511343323434],
        [115.68895688956889, -31.61297761857619],
        [115.15975159751599, -30.60235285572856],
        [114.99774997749978, -30.031054665046653],
        [115.04095040950409, -29.461492943029434],
        [114.64134641346413, -28.810317193771944],
        [114.61614616146164, -28.516853989439895],
        [114.17334173341732, -28.11746619656197],
        [114.04734047340474, -27.33431882878829],
        [113.47853478534785, -26.542489117691183],
        [113.33813338133382, -26.11705429484295],
        [113.77733777337772, -26.54943499234993],
        [113.44253442534426, -25.620424256742567],
        [113.93573935739357, -25.910414523745246],
        [114.23454234542345, -26.297647035970364],
        [114.21654216542169, -25.787125248552485],
        [113.71973719737201, -24.998768474784754],
        [113.6261362613626, -24.684467646476463],
        [113.39213392133922, -24.38405856748568],
        [113.5037350373504, -23.805814502145026],
        [113.70533705337056, -23.560972420424207],
        [113.84213842138422, -23.059132976329764],
        [113.73773737737378, -22.475679504995057],
        [114.14814148141483, -21.755045009150095],
        [114.22374223742236, -22.51735475294754],
        [114.64854648546486, -21.82971316173162],
        [115.45855458554587, -21.494574709447093],
        [115.94815948159481, -21.069139886598876],
        [116.71136711367114, -20.701008529685296],
        [117.1649716497165, -20.622867439774396],
        [117.44217442174426, -20.746156714967157],
        [118.23058230582308, -20.374552420724214],
        [118.83538835388356, -20.26341842618426],
        [118.98658986589868, -20.044623374433755],
        [119.25299252992534, -19.95259053520536],
        [119.80379803798041, -19.97690109651097],
        [120.85500855008553, -19.68343789217893],
        [121.39861398613988, -19.238901914019138],
        [121.65421654216544, -18.705806033960343],
        [122.2410224102241, -18.19702071520716],
        [122.28782287822878, -17.799369390993917],
        [122.31302313023133, -17.2541182302823],
        [123.01143011430116, -16.40498505325054],
        [123.43263432634325, -17.268009979599796],
        [123.86103861038612, -17.068316083160838],
        [123.50463504635047, -16.59599660636607],
        [123.8178381783818, -16.1115218489185],
        [124.25704257042571, -16.32858043200433],
        [124.37944379443798, -15.566270688206885],
        [124.92664926649269, -15.074850056100559],
        [125.1678516785168, -14.680671669216693],
        [125.6718567185672, -14.510497740077398],
        [125.68625686256865, -14.23092628506285],
        [126.12546125461256, -14.347269685596856],
        [126.14346143461438, -14.095481729217298],
        [126.58266582665829, -13.953091298712991],
        [127.0650706507065, -13.817646742867439],
        [127.80307803078034, -14.27607447034471],
        [128.36108361083615, -14.869946753667534],
        [128.9838898388984, -14.8751561596616],
        [129.6210962109621, -14.968925467554683],
        [129.4086940869409, -14.420201369513705],
        [129.88749887498875, -13.617952846428466],
        [130.3411034110341, -13.357482546725464],
        [130.18270182701826, -13.107431059010594],
        [130.61830618306186, -12.536132868328693],
        [131.22311223112234, -12.183629729397296],
        [131.73431734317342, -12.301709598595991],
        [132.5767257672577, -12.114170982809839],
        [132.55872558725588, -11.603649195391952],
        [131.82431824318246, -11.273720149101493],
        [132.35712357123572, -11.127856781267823],
        [133.01953019530197, -11.376171800318005],
        [133.55233552335523, -11.785978405184053],
        [134.3947439474395, -12.042975767557678],
        [134.67914679146793, -11.940524116341166],
        [135.29835298352987, -12.247879069990702],
        [135.88155881558816, -11.963098208982089],
        [136.259562595626, -12.049921642216432],
        [136.49356493564937, -11.857173620436214],
        [136.9507695076951, -12.352067189871903],
        [136.68436684366844, -12.886899538595387],
        [136.30636306363067, -13.291496737467384],
        [135.96075960759606, -13.324489642096424],
        [136.0759607596076, -13.723877434974355],
        [135.78435784357845, -14.22398041040411],
        [135.4279542795428, -14.715401042510436],
        [135.49995499955003, -14.998445434854347],
        [136.29556295562958, -15.550642470224702],
        [137.06597065970664, -15.870152704527044],
        [137.58077580775807, -16.215709968799686],
        [138.30438304383046, -16.807845783457836],
        [138.5851858518585, -16.806109314793147],
        [139.10719107191073, -17.063106677166772],
        [139.26199261992622, -17.372198099480997],
        [140.21600216002162, -17.7108094890949],
        [140.8748087480875, -17.36872516215162],
        [141.0728107281073, -16.832156344763447],
        [141.27441274412746, -16.38935683526836],
        [141.39681396813967, -15.84063273722738],
        [141.70281702817027, -15.045330088800895],
        [141.56241562415624, -14.560855331353324],
        [141.63441634416347, -14.270865064350645],
        [141.51921519215193, -13.697830405004055],
        [141.65241652416523, -12.944203004530053],
        [141.84321843218436, -12.741036170761717],
        [141.68841688416887, -12.40763418714188],
        [141.92961929619298, -11.878011244412448],
        [142.1168211682117, -11.327550677706782],
        [142.14202142021423, -11.042769816698168],
        [142.51642516425164, -10.667692585125849],
        [142.79722797227976, -11.157376748567486],
        [142.86562865628656, -11.784241936519365],
        [143.11763117631176, -11.905794743047437],
        [143.1572315723157, -12.326020159901603],
        [143.52083520835208, -12.834805478654786],
        [143.59643596435967, -13.400894263342636],
        [143.56043560435603, -13.76381621426215],
      ],
    ],
    [
      [
        [-77.53577535775358, 23.7595351609516],
        [-77.78057780577805, 23.70917756967569],
        [-78.03258032580325, 24.285685166351655],
        [-78.40698406984069, 24.575675433354334],
        [-78.1909819098191, 25.209486495964953],
        [-77.88857888578886, 25.169547716677158],
        [-77.53937539375393, 24.339515694956944],
        [-77.53577535775358, 23.7595351609516],
      ],
    ],
    [
      [
        [-77.82017820178201, 26.579560272402716],
        [-78.91098910989109, 26.419805155251552],
        [-78.97938979389794, 26.789672980829806],
        [-78.51138511385113, 26.86955053940538],
        [-77.8489784897849, 26.840030572105718],
        [-77.82017820178201, 26.579560272402716],
      ],
    ],
    [
      [
        [-76.99936999369993, 26.589979084390833],
        [-77.17217172171722, 25.879763400534003],
        [-77.35577355773557, 26.006525613056127],
        [-77.34137341373413, 26.529202681126804],
        [-77.78777787777878, 26.92511753667536],
        [-77.79137791377913, 27.039724468544676],
        [-76.99936999369993, 26.589979084390833],
      ],
    ],
    [
      [
        [109.66249662496625, 2.0067921984219765],
        [110.39690396903973, 1.6629714028140228],
        [111.16731167311673, 1.8505100186001755],
        [111.36891368913689, 2.697906726967261],
        [111.79731797317976, 2.885445342753428],
        [112.99612996129963, 3.102503925839258],
        [113.71253712537128, 3.8943336369363664],
        [114.2057420574206, 4.526408230882311],
        [114.60174601746019, 4.8997489937899275],
        [115.45135451354514, 5.44847309183092],
        [116.2217622176222, 6.143060557705567],
        [116.7257672576726, 6.924471456814558],
        [117.12897128971292, 6.927944394143935],
        [117.64377643776442, 6.422632012720129],
        [117.69057690576909, 5.98677837788378],
        [118.34938349383492, 5.708943391533907],
        [119.18099180991811, 5.408534312543125],
        [119.10899108991089, 5.016092394323934],
        [118.43938439384397, 4.965734803048022],
        [118.61938619386194, 4.477787108271073],
        [117.88137881378816, 4.137439249992497],
        [117.31257312573126, 3.2344755443554334],
        [118.04698046980474, 2.2881001221012127],
        [117.87417874178743, 1.8279359259592525],
        [118.99738997389977, 0.9023981276812663],
        [117.8129781297813, 0.7843182584825854],
        [117.47817478174784, 0.1018860732607294],
        [117.52137521375215, -0.8045505697056967],
        [116.56016560165602, -1.4869827549275527],
        [116.53496534965353, -2.483715768457685],
        [116.14976149761497, -4.013544662046627],
        [116.00216002160022, -3.6575685857858673],
        [114.86454864548648, -4.10731396993971],
        [114.46854468544689, -3.4960769999700005],
        [113.7557375573756, -3.4387735340353487],
        [113.25533255332556, -3.1192632997330065],
        [112.06732067320672, -3.4787123133231432],
        [111.7037170371704, -2.994237555875557],
        [111.04851048510488, -3.0498045531455347],
        [110.22410224102242, -2.933461152611528],
        [110.06930069300694, -1.592907343473442],
        [109.57249572495726, -1.3150723571235687],
        [109.09009090090905, -0.4589933054330544],
        [108.95328953289533, 0.41618690156900584],
        [109.06849068490686, 1.341724699846992],
        [109.66249662496625, 2.0067921984219765],
      ],
    ],
    [
      [
        [-63.664836648366474, 46.55068638496385],
        [-62.937629376293756, 46.41524182911829],
        [-62.01242012420124, 46.443025327753276],
        [-62.505625056250565, 46.03321872288723],
        [-62.872828728287274, 45.96896938229381],
        [-64.14364143641436, 46.392667736477364],
        [-64.3920439204392, 46.72780618876189],
        [-64.0140401404014, 47.035161142411425],
        [-63.664836648366474, 46.55068638496385],
      ],
    ],
    [
      [
        [-61.80721807218072, 49.1050317907179],
        [-62.29322293222931, 49.087667104071045],
        [-63.589235892358914, 49.40023146371462],
        [-64.51804518045181, 49.8725509405094],
        [-64.17244172441724, 49.957637905079054],
        [-62.85842858428583, 49.705849948699466],
        [-61.8360183601836, 49.28909746917469],
        [-61.80721807218072, 49.1050317907179],
      ],
    ],
    [
      [
        [-123.50823508235082, 48.509423038730375],
        [-124.01224012240122, 48.37050554555546],
        [-125.65385653856538, 48.825460335703355],
        [-125.95625956259562, 49.179699943299426],
        [-126.84906849068491, 49.53046661356612],
        [-127.0290702907029, 49.81524747457473],
        [-128.05868058680585, 49.99410374703746],
        [-128.44388443884438, 50.53935490774907],
        [-128.35748357483575, 50.770305240152396],
        [-127.30987309873098, 50.55324665706655],
        [-126.69426694266943, 50.40043741457413],
        [-125.75465754657546, 50.29451282602827],
        [-125.41625416254163, 49.9506920304203],
        [-124.91944919449195, 49.47489961629614],
        [-123.92223922239222, 49.061620074100745],
        [-123.50823508235082, 48.509423038730375],
      ],
    ],
    [
      [
        [-56.13356133561335, 50.68695474424743],
        [-56.79596795967959, 49.811774537245356],
        [-56.14436144361443, 50.15038592685926],
        [-55.471154711547115, 49.93506381243813],
        [-55.823958239582396, 49.587770079500785],
        [-54.934749347493465, 49.3134080304803],
        [-54.47394473944739, 49.55651364353642],
        [-53.476734767347665, 49.24915868988688],
        [-53.786337863378634, 48.51636891338913],
        [-53.087930879308786, 48.6882793111931],
        [-52.95832958329582, 48.15691989979899],
        [-52.64872648726487, 47.535264117841166],
        [-53.069930699306994, 46.654874504845054],
        [-53.51993519935199, 46.61840866288662],
        [-54.17874178741786, 46.80768374733748],
        [-53.962739627396274, 47.625560488404886],
        [-54.239942399423995, 47.75232270092701],
        [-55.399153991539904, 46.88582483724838],
        [-55.99675996759967, 46.92055421054209],
        [-55.29115291152911, 47.389400750007496],
        [-56.25236252362524, 47.63250636306361],
        [-57.32517325173251, 47.573466428464286],
        [-59.265592655926554, 47.60298639576396],
        [-59.42039420394204, 47.89992253742537],
        [-58.79758797587975, 48.25068920769206],
        [-59.23319233192332, 48.523314788047884],
        [-58.39078390783908, 49.12586941469414],
        [-57.357573575735756, 50.718211180211796],
        [-56.73836738367383, 51.28777290222902],
        [-55.87075870758707, 51.63159369783696],
        [-55.40635406354063, 51.588181981219805],
        [-55.600756007560065, 51.3172928695287],
        [-56.13356133561335, 50.68695474424743],
      ],
    ],
    [
      [
        [-133.18153181531815, 54.17031088560884],
        [-132.709927099271, 54.04007573575734],
        [-131.74871748717487, 54.11995329433293],
        [-132.0511205112051, 52.98430278762788],
        [-131.17991179911797, 52.180317795877954],
        [-131.57951579515796, 52.18205426454264],
        [-132.18072180721808, 52.640481992019915],
        [-132.55152551525515, 53.100646188161875],
        [-133.05553055530555, 53.41147407914079],
        [-133.23913239132392, 53.850800651306514],
        [-133.18153181531815, 54.17031088560884],
      ],
    ],
    [
      [
        [-79.26739267392674, 62.15806674316741],
        [-79.65619656196561, 61.63365320643206],
        [-80.0990009900099, 61.71874017100171],
        [-80.36180361803618, 62.01567631266312],
        [-80.3150031500315, 62.085135059250575],
        [-79.92979929799297, 62.385544138241386],
        [-79.51939519395194, 62.362970045600434],
        [-79.26739267392674, 62.15806674316741],
      ],
    ],
    [
      [
        [-81.8990189901899, 62.71026377853778],
        [-83.0690306903069, 62.1598032118321],
        [-83.77463774637746, 62.18237730447305],
        [-83.99423994239942, 62.453266416164155],
        [-83.2490324903249, 62.913430612306115],
        [-81.87741877418773, 62.90474826898267],
        [-81.8990189901899, 62.71026377853778],
      ],
    ],
    [
      [
        [-85.16065160651605, 65.65705110251102],
        [-84.9770497704977, 65.2177245303453],
        [-84.46224462244622, 65.3722702415024],
        [-83.88263882638826, 65.11006347313472],
        [-82.78822788227882, 64.76624267752678],
        [-81.64341643416434, 64.45541478654786],
        [-81.55341553415533, 63.979622372423705],
        [-80.8190081900819, 64.0577634623346],
        [-80.10260102601026, 63.72609794737946],
        [-80.99180991809918, 63.411797119071196],
        [-82.5470254702547, 63.651429794797934],
        [-83.10863108631087, 64.10117517895179],
        [-84.09864098640986, 63.56981576755766],
        [-85.52425524255243, 63.05234810548106],
        [-85.86625866258662, 63.637538045480454],
        [-87.22347223472235, 63.54203226892267],
        [-86.35226352263523, 64.03518936969368],
        [-86.22626226262263, 64.82354614346141],
        [-85.88425884258842, 65.7386651297513],
        [-85.16065160651605, 65.65705110251102],
      ],
    ],
    [
      [
        [-75.86535865358653, 67.14867768547686],
        [-76.98856988569885, 67.09832009420094],
        [-77.23697236972369, 67.58800425764258],
        [-76.81216812168121, 68.14888363633636],
        [-75.89415894158941, 68.28780112951128],
        [-75.11295112951129, 68.00996614316142],
        [-75.10215102151021, 67.58279485164852],
        [-75.21735217352173, 67.44387735847357],
        [-75.86535865358653, 67.14867768547686],
      ],
    ],
    [
      [
        [-95.64755647556476, 69.10741433924338],
        [-96.27036270362703, 68.75664766897668],
        [-97.61677616776167, 69.06052968529684],
        [-98.43038430384304, 68.95113215942158],
        [-99.79839798397984, 69.40087754357543],
        [-98.91638916389164, 69.70996896588966],
        [-98.21798217982179, 70.14408613206132],
        [-97.1559715597156, 69.8593052710527],
        [-96.55836558365583, 69.68044899858998],
        [-96.25596255962559, 69.48943744547444],
        [-95.64755647556476, 69.10741433924338],
      ],
    ],
    [
      [
        [-114.16614166141662, 73.12212989199892],
        [-114.66654666546665, 72.65328335253352],
        [-112.44172441724417, 72.95542890018899],
        [-111.0521105211052, 72.45011651876518],
        [-109.92169921699217, 72.96063830618306],
        [-109.00729007290073, 72.63418219722197],
        [-108.19008190081901, 71.65134093300932],
        [-107.68607686076861, 72.06462047520475],
        [-108.39528395283952, 73.08913698736987],
        [-107.51687516875168, 73.23673682386823],
        [-106.52326523265232, 73.07524523805236],
        [-105.40365403654036, 72.67238450784507],
        [-104.77364773647736, 71.69822558695586],
        [-104.4640446404464, 70.99321930909309],
        [-102.78642786427864, 70.49832573965739],
        [-100.97920979209792, 70.02426979419792],
        [-101.09081090810908, 69.58494322203222],
        [-102.73242732427325, 69.50332919479195],
        [-102.09162091620917, 69.1195696198962],
        [-102.430024300243, 68.7531747316473],
        [-104.24084240842409, 68.9094569114691],
        [-105.96165961659617, 69.18034602316021],
        [-107.12087120871209, 69.1195696198962],
        [-109.0000900009, 68.7792217616176],
        [-111.53451534515345, 68.62988545645456],
        [-113.31293312933128, 68.53611614856149],
        [-113.85653856538565, 69.00669915669155],
        [-115.2209522095221, 69.27932473704738],
        [-116.10656106561066, 69.16819074250742],
        [-117.34137341373413, 69.96002045360453],
        [-116.67536675366753, 70.06594504215042],
        [-115.1309513095131, 70.23785543995439],
        [-113.71973719737197, 70.19270725467254],
        [-112.41652416524165, 70.3663541211412],
        [-114.34974349743497, 70.6007773908739],
        [-116.48816488164881, 70.52089983229831],
        [-117.90657906579065, 70.54000098760986],
        [-118.43218432184321, 70.90986881318813],
        [-116.11376113761138, 71.30925660606604],
        [-117.65457654576545, 71.29536485674856],
        [-119.40059400594006, 71.55930809378094],
        [-118.56178561785617, 72.30772608826086],
        [-117.8669786697867, 72.70537741247412],
        [-115.18855188551885, 73.31487791377913],
        [-114.16614166141662, 73.12212989199892],
      ],
    ],
    [
      [
        [-104.50004500045, 73.42080250232502],
        [-105.37845378453784, 72.75920794107941],
        [-106.94086940869408, 73.4607412816128],
        [-106.59886598865988, 73.59965877478774],
        [-105.25965259652597, 73.63959755407552],
        [-104.50004500045, 73.42080250232502],
      ],
    ],
    [
      [
        [-76.34056340563406, 73.10302873668735],
        [-76.25056250562506, 72.82693021900218],
        [-77.31617316173161, 72.85471371763717],
        [-78.39258392583926, 72.87728781027809],
        [-79.4869948699487, 72.74184325443252],
        [-79.7749977499775, 72.80261965769657],
        [-80.87660876608766, 73.33397906909067],
        [-80.83340833408333, 73.69342808268081],
        [-80.35460354603546, 73.75941389193892],
        [-78.0649806498065, 73.65175283472834],
        [-76.34056340563406, 73.10302873668735],
      ],
    ],
    [
      [
        [-86.5610656106561, 73.15685926529264],
        [-85.77265772657726, 72.53346701467015],
        [-84.8510485104851, 73.34092494374943],
        [-82.31662316623166, 73.75073154861548],
        [-80.59940599405994, 72.71579622446222],
        [-80.75060750607506, 72.06114753787537],
        [-78.77058770587705, 72.35287427354274],
        [-77.82377823778238, 72.74878912909128],
        [-75.60615606156061, 72.24347674766747],
        [-74.22734227342274, 71.76768433354331],
        [-74.09774097740977, 71.3300942300423],
        [-72.24372243722436, 71.55757162511625],
        [-71.19971199711996, 70.92028762517626],
        [-68.78768787687876, 70.52437276962769],
        [-67.91647916479164, 70.1215120394204],
        [-66.96966969669697, 69.18555542915428],
        [-68.80568805688057, 68.72018182701825],
        [-66.45126451264512, 68.06726960909609],
        [-64.86364863648636, 67.84673808868087],
        [-63.423634236342366, 66.92814616506163],
        [-61.85041850418504, 66.86216035580355],
        [-62.16362163621636, 66.16062701527014],
        [-63.916839168391675, 64.99892947859479],
        [-65.1480514805148, 65.4261007701077],
        [-66.72126721267212, 66.38810441034408],
        [-68.01368013680137, 66.26307866648665],
        [-68.13968139681397, 65.69004400714007],
        [-67.08847088470884, 65.10832700447003],
        [-65.73125731257312, 64.64816280832807],
        [-65.32085320853209, 64.38248310263103],
        [-64.66924669246693, 63.39269596375962],
        [-65.01485014850148, 62.67379793657935],
        [-66.27486274862748, 62.94468704827048],
        [-68.7840878408784, 63.74519910269103],
        [-67.36927369273693, 62.88391064500644],
        [-66.32886328863289, 62.2796195496955],
        [-66.16686166861668, 61.93058934809346],
        [-68.87768877688777, 62.32997714097141],
        [-71.02331023310232, 62.90995767497674],
        [-72.23652236522365, 63.39790536975369],
        [-71.88731887318873, 63.67921329343292],
        [-73.37773377733777, 64.19320801818017],
        [-74.83574835748357, 64.67941924429243],
        [-74.81774817748177, 64.38942897728975],
        [-77.70857708577086, 64.2296738601386],
        [-78.55458554585546, 64.57349465574654],
        [-77.89577895778957, 65.30975736957367],
        [-76.01656016560165, 65.32712205622056],
        [-73.96093960939609, 65.45562073740737],
        [-74.29214292142922, 65.81159681366813],
        [-73.94653946539465, 66.30996332043318],
        [-72.65052650526505, 67.2841222413224],
        [-72.92772927729277, 67.7269217508175],
        [-73.31293312933128, 68.06900607776078],
        [-74.8429484294843, 68.55521730387304],
        [-76.86976869768698, 68.89556516215163],
        [-76.2289622896229, 69.14735311853119],
        [-77.28737287372873, 69.76900890048898],
        [-78.16938169381693, 69.82631236642365],
        [-78.95778957789578, 70.16666022470224],
        [-79.49419494194942, 69.8714605517055],
        [-81.3050130501305, 69.74296187051868],
        [-84.94464944649447, 69.96696632826328],
        [-87.06147061470614, 70.26042953259531],
        [-88.68148681486815, 70.41150230642305],
        [-89.5130951309513, 70.76226897668977],
        [-88.4690846908469, 71.21896023550235],
        [-89.88749887498875, 71.22243317283173],
        [-90.20430204302043, 72.23479440434403],
        [-89.43749437494374, 73.12907576665765],
        [-88.40788407884078, 73.53714590285901],
        [-85.82665826658267, 73.80456207722077],
        [-86.5610656106561, 73.15685926529264],
      ],
    ],
    [
      [
        [-100.35640356403563, 73.84450085650855],
        [-99.16479164791647, 73.6326516794168],
        [-97.37917379173791, 73.75941389193892],
        [-97.119971199712, 73.46942362493624],
        [-98.05238052380524, 72.99015827348273],
        [-96.54036540365404, 72.55951404464045],
        [-96.72036720367204, 71.66002327633277],
        [-98.35838358383583, 71.27279076410764],
        [-99.32319323193232, 71.35614126001258],
        [-100.01440014400144, 71.73816436624367],
        [-102.49842498424984, 72.50915645336451],
        [-102.48042480424805, 72.83040315633156],
        [-100.43920439204392, 72.70537741247412],
        [-101.54081540815407, 73.36002609906097],
        [-100.35640356403563, 73.84450085650855],
      ],
    ],
    [
      [
        [-93.1959319593196, 72.7713632217322],
        [-94.26874268742687, 72.02468169591694],
        [-95.409954099541, 72.06114753787537],
        [-96.03276032760327, 72.93980068220682],
        [-96.01836018360183, 73.43816718897187],
        [-95.49635496354963, 73.86186554315543],
        [-94.50274502745027, 74.13449112351122],
        [-92.41832418324184, 74.09976175021748],
        [-90.51030510305102, 73.85665613716137],
        [-92.00432004320042, 72.96584771217712],
        [-93.1959319593196, 72.7713632217322],
      ],
    ],
    [
      [
        [-120.45900459004591, 71.38392475864757],
        [-123.09063090630906, 70.90118646986468],
        [-123.61983619836198, 71.34051304203041],
        [-125.92745927459274, 71.86839951609517],
        [-125.4990549905499, 72.2920978702787],
        [-124.80784807848079, 73.02315117811176],
        [-123.94023940239401, 73.67953633336333],
        [-124.91944919449195, 74.29250977199771],
        [-121.5390153901539, 74.44879195181952],
        [-120.10980109801098, 74.2421521807218],
        [-117.55737557375573, 74.18658518345183],
        [-116.58536585365854, 73.89659491644915],
        [-115.51255512555126, 73.4746330309303],
        [-116.76896768967688, 73.22284507455075],
        [-119.22059220592206, 72.51957526535264],
        [-120.45900459004591, 71.81977839348392],
        [-120.45900459004591, 71.38392475864757],
      ],
    ],
    [
      [
        [-93.61353613536136, 74.98015136321362],
        [-94.1571415714157, 74.5929188509885],
        [-95.6079560795608, 74.66758700357002],
        [-96.82116821168212, 74.92805730327302],
        [-96.28836288362884, 75.37780268742688],
        [-94.85194851948519, 75.6469553304533],
        [-93.97713977139772, 75.2961886601866],
        [-93.61353613536136, 74.98015136321362],
      ],
    ],
    [
      [
        [-98.49878498784987, 76.72009296522964],
        [-97.73557735577356, 76.2564558317583],
        [-97.70317703177031, 75.74419757567574],
        [-98.16038160381603, 74.99925251852517],
        [-99.80919809198092, 74.89680086730866],
        [-100.8820088200882, 75.05655598445983],
        [-100.8640086400864, 75.64000945579454],
        [-102.5020250202502, 75.56360483454833],
        [-102.56682566825668, 76.33633339033389],
        [-101.49041490414903, 76.30507695436953],
        [-99.9819998199982, 76.6471612813128],
        [-98.5779857798578, 76.58812134671345],
        [-98.49878498784987, 76.72009296522964],
      ],
    ],
    [
      [
        [-108.21168211682117, 76.20088883448832],
        [-107.81927819278192, 75.84491275822757],
        [-106.930069300693, 76.01335021870219],
        [-105.88245882458824, 75.969938502085],
        [-105.7060570605706, 75.48025433864339],
        [-106.31446314463145, 75.00446192451923],
        [-109.69849698496985, 74.84991621336212],
        [-112.22212222122221, 74.41753551585515],
        [-113.74493744937449, 74.39496142321423],
        [-113.87093870938709, 74.71968106351062],
        [-111.79371793717937, 75.16248057300572],
        [-116.31176311763117, 75.04266423514235],
        [-117.71217712177122, 75.22152050760508],
        [-116.34776347763477, 76.19915236582364],
        [-115.40455404554045, 76.47872382083821],
        [-112.58932589325893, 76.141848899889],
        [-110.81450814508145, 75.54971308523085],
        [-109.06849068490685, 75.47330846398464],
        [-110.49770497704976, 76.43010269822696],
        [-109.57969579695796, 76.79476111781116],
        [-108.550085500855, 76.67841771727717],
        [-108.21168211682117, 76.20088883448832],
      ],
    ],
    [
      [
        [-94.68274682746828, 77.09864313413132],
        [-93.5739357393574, 76.77565996249962],
        [-91.60471604716047, 76.779132899829],
        [-90.74070740707407, 76.44920385353853],
        [-90.97110971109711, 76.0741266219662],
        [-89.82269822698227, 75.84838569555694],
        [-89.18549185491855, 75.61048948849486],
        [-87.83907839078391, 75.56534130321302],
        [-86.37746377463775, 75.48199080730805],
        [-84.78984789847898, 75.6990493903939],
        [-82.75222752227522, 75.78413635496355],
        [-81.12861128611286, 75.71467760837606],
        [-80.05580055800557, 75.33612743947438],
        [-79.83259832598326, 74.92284789727896],
        [-80.4590045900459, 74.65716819158192],
        [-81.94941949419494, 74.44184607716076],
        [-83.22743227432274, 74.56339888368882],
        [-86.09666096660966, 74.4105896411964],
        [-88.14868148681487, 74.39148848588485],
        [-89.7650976509765, 74.5147777610776],
        [-92.42192421924219, 74.83776093270933],
        [-92.76752767527675, 75.3864850307503],
        [-92.88992889928899, 75.88311506885069],
        [-93.89433894338943, 76.31896870368703],
        [-95.96075960759607, 76.44052151021509],
        [-97.119971199712, 76.751349401194],
        [-96.74556745567456, 77.16115600606005],
        [-94.68274682746828, 77.09864313413132],
      ],
    ],
    [
      [
        [-116.20016200162001, 77.64563076350763],
        [-116.3369633696337, 76.87637514505144],
        [-117.10737107371074, 76.53081788077881],
        [-118.03978039780398, 76.4804602895029],
        [-119.9009990099901, 76.05328899798997],
        [-121.49941499414993, 75.90047975549754],
        [-122.85662856628566, 76.1158018699187],
        [-121.15741157411574, 76.86421986439865],
        [-119.10539105391054, 77.51192267632675],
        [-117.57177571775718, 77.49803092700927],
        [-116.20016200162001, 77.64563076350763],
      ],
    ],
    [
      [
        [-93.84033840338402, 77.52060501965019],
        [-94.29394293942939, 77.49108505235051],
        [-96.16956169561695, 77.55533439294393],
        [-96.43596435964359, 77.83490584795848],
        [-94.42354423544235, 77.81927762997628],
        [-93.72153721537215, 77.63347548285483],
        [-93.84033840338402, 77.52060501965019],
      ],
    ],
    [
      [
        [-110.18810188101881, 77.69772482344823],
        [-112.0529205292053, 77.40947102511024],
        [-113.53253532535325, 77.73245419674197],
        [-112.72612726127261, 78.05022796237961],
        [-111.26451264512644, 78.15267961359612],
        [-109.85329853298532, 77.99639743377432],
        [-110.18810188101881, 77.69772482344823],
      ],
    ],
    [
      [
        [-109.66249662496625, 78.60242499774998],
        [-110.8829088290883, 78.40620403864037],
        [-112.54252542525425, 78.40794050730506],
        [-112.52452524525245, 78.55033093780938],
        [-111.49851498514985, 78.85074001680016],
        [-110.9621096210962, 78.80385536285362],
        [-109.66249662496625, 78.60242499774998],
      ],
    ],
    [
      [
        [-95.83115831158311, 78.05717383703836],
        [-97.31077310773108, 77.85053406594065],
        [-98.12438124381244, 78.08322086700866],
        [-98.55278552785528, 78.45829809858097],
        [-98.6319863198632, 78.8715776407764],
        [-97.3359733597336, 78.83163886148861],
        [-96.75276752767527, 78.7656530522305],
        [-95.55755557555575, 78.41835931929319],
        [-95.83115831158311, 78.05717383703836],
      ],
    ],
    [
      [
        [-100.06120061200612, 78.32459001140012],
        [-99.67239672396724, 77.90783753187532],
        [-101.30321303213032, 78.01897152641524],
        [-102.94842948429483, 78.34369116671166],
        [-105.17685176851768, 78.38015700867007],
        [-104.2120421204212, 78.6770931503315],
        [-105.4180541805418, 78.91846229472293],
        [-105.49365493654936, 79.30222186961868],
        [-103.5280352803528, 79.16504084510845],
        [-100.82440824408243, 78.80038242552425],
        [-100.06120061200612, 78.32459001140012],
      ],
    ],
    [
      [
        [-87.01827018270183, 79.65993441454413],
        [-85.81585815858158, 79.33695124291242],
        [-87.18747187471874, 79.04001510125102],
        [-89.03429034290343, 78.286387700777],
        [-90.80550805508055, 78.21519248552485],
        [-92.87552875528755, 78.34369116671166],
        [-93.9519395193952, 78.75176130291302],
        [-93.93753937539375, 79.11294678516785],
        [-93.14553145531455, 79.38036295952958],
        [-94.97434974349743, 79.37168061620616],
        [-96.0759607596076, 79.705082599826],
        [-96.70956709567095, 80.1583009213092],
        [-96.01476014760148, 80.602836899469],
        [-95.32355323553236, 80.90671891578916],
        [-94.29754297542975, 80.9779141310413],
        [-94.73674736747367, 81.20712799477994],
        [-92.41112411124111, 81.25748558605585],
        [-91.13311133111331, 80.72265323733237],
        [-89.44829448294483, 80.5090675915759],
        [-87.81027810278103, 80.31979250712507],
        [-87.01827018270183, 79.65993441454413],
      ],
    ],
    [
      [
        [-68.49968499684996, 83.10682471394713],
        [-65.82845828458284, 83.02868362403623],
        [-63.67923679236792, 82.90018494284942],
        [-61.85041850418504, 82.62929583115832],
        [-61.893618936189355, 82.36187965679656],
        [-64.33444334443344, 81.9277624906249],
        [-66.75366753667537, 81.72459565685656],
        [-67.65727657276572, 81.500591199112],
        [-65.47925479254792, 81.50580060510603],
        [-67.84087840878408, 80.8997730411304],
        [-69.4680946809468, 80.61672864878648],
        [-71.18171181711817, 79.80058837638376],
        [-73.24453244532445, 79.63388738457382],
        [-73.88173881738817, 79.43072055080549],
        [-76.90936909369093, 79.32305949359494],
        [-75.53055530555305, 79.19803374973748],
        [-76.22176221762217, 79.01917747727475],
        [-75.39375393753937, 78.52602037650377],
        [-76.34416344163441, 78.1821995808958],
        [-77.88857888578886, 77.89915518855187],
        [-78.36378363783638, 77.50844973899737],
        [-79.76059760597606, 77.20977712867128],
        [-79.62019620196202, 76.98403620226202],
        [-77.91017910179102, 77.02223851288511],
        [-77.88857888578886, 76.7773964311643],
        [-80.55980559805597, 76.1783147418474],
        [-83.17343173431733, 76.4544132595326],
        [-86.1110611106111, 76.29986754837549],
        [-87.60147601476014, 76.41968388623886],
        [-89.49149491494914, 76.47177794617946],
        [-89.61749617496174, 76.95277976629765],
        [-87.7670776707767, 77.17852069270691],
        [-88.26028260282602, 77.89915518855187],
        [-87.64827648276483, 77.97035040380402],
        [-84.9770497704977, 77.53796970629705],
        [-86.34146341463415, 78.18046311223111],
        [-87.96147961479615, 78.37147466534665],
        [-87.15147151471514, 78.75870717757178],
        [-85.38025380253802, 78.99660338463383],
        [-85.09585095850959, 79.34563358623586],
        [-86.5070650706507, 79.73633903579034],
        [-86.93186931869319, 80.25207022920227],
        [-84.19944199441994, 80.20865851258512],
        [-83.40743407434074, 80.09926098670985],
        [-81.84861848618486, 80.46391940629405],
        [-84.09864098640986, 80.58026280682805],
        [-87.59787597875979, 80.51601346623465],
        [-89.36549365493654, 80.85636132451324],
        [-90.20070200702007, 81.25922205472054],
        [-91.3671136711367, 81.5526852590526],
        [-91.58671586715867, 81.89476958599585],
        [-90.09990099900999, 82.08578113911139],
        [-88.93348933489335, 82.11703757507576],
        [-86.97146971469715, 82.28026562955628],
        [-85.49905499054991, 82.65186992379924],
        [-84.26064260642606, 82.59977586385864],
        [-83.18063180631806, 82.32020440884409],
        [-82.4210242102421, 82.86024616356164],
        [-81.09981099810997, 83.02000128071279],
        [-79.3069930699307, 83.13113527525275],
        [-76.25056250562506, 83.17281052320521],
        [-75.71775717757177, 83.06341299732998],
        [-72.83052830528305, 83.23358692646926],
        [-70.66690666906669, 83.16933758587584],
        [-68.49968499684996, 83.10682471394713],
      ],
    ],
    [
      [
        [110.33930339303396, 18.678627848078477],
        [109.47529475294755, 18.19762602796027],
        [108.65448654486545, 18.508453918939182],
        [108.6256862568626, 19.368005907959073],
        [109.1188911889119, 19.821224229442294],
        [110.21330213302133, 20.10079568445684],
        [110.78570785707859, 20.078221591815918],
        [111.00891008910088, 19.696198485584844],
        [110.56970569705697, 19.255135444754444],
        [110.33930339303396, 18.678627848078477],
      ],
    ],
    [
      [
        [-82.26982269822697, 23.1882369702697],
        [-81.40581405814058, 23.11704175501754],
        [-80.61740617406174, 23.10662294302942],
        [-79.67779677796777, 22.764538616086156],
        [-79.28179281792818, 22.399880196501954],
        [-78.34578345783457, 22.512750659706597],
        [-77.99297992979929, 22.276590921309207],
        [-77.1469714697147, 21.658408076680757],
        [-76.52416524165241, 21.20692622386224],
        [-76.19296192961929, 21.22081797317972],
        [-75.5989559895599, 21.015914670746696],
        [-75.6709567095671, 20.73460674706746],
        [-74.9329493294933, 20.694667967779665],
        [-74.1769417694177, 20.284861362913617],
        [-74.29574295742957, 20.05043809318093],
        [-74.96174961749617, 19.923675880658806],
        [-75.6349563495635, 19.873318289382894],
        [-76.32256322563225, 19.95319584795847],
        [-77.75537755377553, 19.855953602736022],
        [-77.08577085770857, 20.41336004410043],
        [-77.49257492574925, 20.67383034380343],
        [-78.1369813698137, 20.739816153061525],
        [-78.48258482584825, 21.0280699513995],
        [-78.72018720187201, 21.59763167341673],
        [-79.28539285392854, 21.559429362793622],
        [-80.21780217802177, 21.826845537155364],
        [-80.51660516605166, 22.036958245582454],
        [-81.81981819818198, 22.191503956739567],
        [-82.1690216902169, 22.387724915849148],
        [-81.79461794617946, 22.637776403564033],
        [-82.77742777427774, 22.688133994839944],
        [-83.49383493834938, 22.16892986409863],
        [-83.90783907839078, 22.155038114781135],
        [-84.05184051840519, 21.91019603306033],
        [-84.54864548645486, 21.800798507185064],
        [-84.97344973449734, 21.896304283742836],
        [-84.44784447844478, 22.20539570605706],
        [-84.23184231842318, 22.566581188311872],
        [-83.77823778237781, 22.788849177391768],
        [-83.2670326703267, 22.983333667836675],
        [-82.5110251102511, 23.078839444394433],
        [-82.26982269822697, 23.1882369702697],
      ],
    ],
    [
      [
        [32.73332733327334, 35.14035078930789],
        [32.80172801728017, 35.14556019530194],
        [32.94572945729459, 35.386929339693395],
        [33.66573665736658, 35.3730375903759],
        [34.57654576545767, 35.671710200701995],
        [33.89973899738999, 35.24627537785378],
        [33.97533975339755, 35.05873676206761],
        [34.00414004140043, 34.978859203492036],
        [32.97812978129781, 34.57252553595535],
        [32.488524885248864, 34.70102421714216],
        [32.25812258122582, 35.10388494734947],
        [32.73332733327334, 35.14035078930789],
      ],
    ],
    [
      [
        [12.688326883268843, 55.60984340863408],
        [12.09072090720909, 54.80064901089011],
        [11.04311043110431, 55.36500132691327],
        [10.902709027090282, 55.78001733777336],
        [12.371523715237146, 56.11168285272851],
        [12.688326883268843, 55.60984340863408],
      ],
    ],
    [
      [
        [-71.71091710917109, 19.715299640896404],
        [-71.58851588515884, 19.8854735700357],
        [-70.80730807308073, 19.880264164041634],
        [-70.21330213302133, 19.62326680166801],
        [-69.95049950499505, 19.64757736297362],
        [-69.77049770497705, 19.29333775537755],
        [-69.22329223292232, 19.312438910689096],
        [-69.25569255692557, 19.01550276902769],
        [-68.80928809288092, 18.97903692706926],
        [-68.31968319683196, 18.612642038820383],
        [-68.69048690486905, 18.204571902619023],
        [-69.16569165691656, 18.42336695436954],
        [-69.62289622896229, 18.37995523775237],
        [-69.9540995409954, 18.428576360363593],
        [-70.1341013410134, 18.246247150571506],
        [-70.51570515705157, 18.183734278642774],
        [-70.67050670506704, 18.42683989169892],
        [-71.00171001710017, 18.282712992529923],
        [-71.40131401314012, 17.59854433864338],
        [-71.65691656916569, 17.758299455794557],
        [-71.70731707317073, 18.044816785467845],
        [-72.37332373323733, 18.21499071460714],
        [-72.8449284492845, 18.145531968019668],
        [-73.45333453334533, 18.218463651936517],
        [-73.92133921339213, 18.03092503615035],
        [-74.45774457744577, 18.341752927129264],
        [-74.37134371343713, 18.664736098760983],
        [-73.44973449734496, 18.525818605586053],
        [-72.69372693726937, 18.445941047010464],
        [-72.33372333723337, 18.66820903609036],
        [-72.79092790927909, 19.10232620226202],
        [-72.78372783727836, 19.48434930849308],
        [-73.41373413734136, 19.638895019650192],
        [-73.19053190531905, 19.914993537335363],
        [-72.57852578525785, 19.871581820718205],
        [-71.71091710917109, 19.715299640896404],
      ],
    ],
    [
      [
        [178.37278372783732, -17.339205194851957],
        [178.71838718387187, -17.62919546185462],
        [178.5527855278553, -18.15013606126061],
        [177.93357933579335, -18.287317085770866],
        [177.38277382773828, -18.164027810578105],
        [177.28557285572856, -17.724701238412393],
        [177.67077670776712, -17.380880442804425],
        [178.12438124381248, -17.504169717997186],
        [178.37278372783732, -17.339205194851957],
      ],
    ],
    [
      [
        [-180, -16.06637366363664],
        [-179.79479794797948, -16.021225478354793],
        [-179.9171991719917, -16.502227298472988],
        [-180, -16.556057827078277],
        [179.3627936279363, -16.800899908799096],
        [178.7255872558726, -17.01274908589086],
        [178.5959859598596, -16.63940832298323],
        [179.09639096390964, -16.434505020550205],
        [179.41319413194134, -16.37893802328024],
        [-180, -16.06637366363664],
      ],
    ],
    [
      [
        [-61.19881198811987, -51.84978343683437],
        [-60, -51.250701747517475],
        [-59.15039150391503, -51.50075323523235],
        [-58.549185491854914, -51.09962897368974],
        [-57.749977499775, -51.54937435784358],
        [-58.048780487804876, -51.90014102811028],
        [-59.39879398793987, -52.20055010710107],
        [-59.84879848798488, -51.84978343683437],
        [-60.698406984069834, -52.299528820988215],
        [-61.19881198811987, -51.84978343683437],
      ],
    ],
    [
      [
        [9.55989559895599, 42.15221125731256],
        [9.228692286922865, 41.379482701526996],
        [8.775087750877503, 41.58438600396002],
        [8.544685446854487, 42.25639937719376],
        [8.746287462874648, 42.628003671436716],
        [9.390693906939077, 43.010026777667775],
        [9.55989559895599, 42.15221125731256],
      ],
    ],
    [
      [
        [-7.572675726757268, 55.13231452584526],
        [-6.733867338673377, 55.17225330513304],
        [-5.661056610566106, 54.55407046050459],
        [-6.197461974619728, 53.86816533795337],
        [-6.031860318603179, 53.152740248102475],
        [-6.787867878678782, 52.26019535445354],
        [-8.56268562685625, 51.66979600846008],
        [-9.977499774997739, 51.82086878228782],
        [-9.167491674916732, 52.864486449764485],
        [-9.689496894968954, 53.88205708727085],
        [-8.32868328683287, 54.665204455044545],
        [-7.572675726757268, 55.13231452584526],
      ],
    ],
    [
      [
        [-3.0042300423004065, 58.63477182251822],
        [-4.073440734407342, 57.55295184441843],
        [-3.0546305463054466, 57.690132868928686],
        [-1.9602196021960197, 57.68492346293462],
        [-2.2194221942219485, 56.87051965919659],
        [-3.119431194311943, 55.97450182821828],
        [-2.08622086220862, 55.91025248762486],
        [-2.0070200702006957, 55.80432789907897],
        [-1.114211142111401, 54.62526567575674],
        [-0.4302043020430233, 54.4637740899409],
        [0.1854018540185507, 53.324650645906445],
        [0.46980469804699965, 52.930472259022594],
        [1.6830168301682988, 52.73946070590705],
        [1.5606156061560625, 52.100440237302365],
        [1.0494104941049613, 51.80697703297031],
        [1.4490144901449185, 51.28950937089371],
        [0.5490054900548955, 50.76509583415833],
        [-0.7866078660786684, 50.77551464614646],
        [-2.489424894248941, 50.49941612846129],
        [-2.9574295742957304, 50.69737355623556],
        [-3.6162361623616164, 50.22852701677016],
        [-4.541445414454131, 50.3413974799748],
        [-5.243452434524329, 49.95937437374374],
        [-5.776257762577615, 50.1590682701827],
        [-4.311043110431086, 51.20963181231812],
        [-3.414634146341456, 51.42669039540394],
        [-3.421834218342184, 51.42669039540394],
        [-4.9842498424984285, 51.59339138721387],
        [-5.268652686526849, 51.9910427114271],
        [-4.221042210422098, 52.30187060240601],
        [-4.768247682476812, 52.84017588845887],
        [-4.581045810458107, 53.494824575045755],
        [-3.094230942309423, 53.404528204482034],
        [-3.090630906309059, 53.404528204482034],
        [-2.9466294662946666, 53.984508738487364],
        [-3.6162361623616164, 54.600955114451125],
        [-3.6306363063630442, 54.61484686376863],
        [-4.843848438484372, 54.79023019890198],
        [-5.081450814508145, 55.061119310593085],
        [-4.7178471784717715, 55.509128226082254],
        [-5.0490504905048965, 55.783490275102736],
        [-5.585455854558546, 55.311170798307984],
        [-5.64665646656465, 56.27491090720906],
        [-6.150661506615052, 56.785432694626934],
        [-5.787057870578707, 57.8186315501155],
        [-5.009450094500949, 58.62956241652415],
        [-4.210242102421006, 58.55142132661325],
        [-3.0042300423004065, 58.63477182251822],
      ],
    ],
    [
      [
        [23.700837008370087, 35.70470310533105],
        [24.2480424804248, 35.367828184381835],
        [25.02565025650256, 35.4251316503165],
        [25.770857708577097, 35.35393643506434],
        [25.745657456574577, 35.18028956859568],
        [26.289262892628926, 35.30010590645905],
        [26.163261632616326, 35.00490623346232],
        [24.723247232472346, 34.91981926889268],
        [24.73404734047341, 35.08478379203791],
        [23.513635136351382, 35.27926828248282],
        [23.700837008370087, 35.70470310533105],
      ],
    ],
    [
      [
        [-46.76266762667626, 82.62755936249363],
        [-43.40743407434073, 83.22490458314581],
        [-39.89739897398974, 83.17975639786397],
        [-38.622986229862306, 83.54962422344224],
        [-35.08775087750877, 83.64513],
        [-27.099270992709933, 83.52010425614256],
        [-20.84600846008459, 82.72653807638076],
        [-22.692826928269284, 82.34104203282033],
        [-26.516065160651607, 82.29763031620314],
        [-31.901719017190175, 82.2003880709807],
        [-31.397713977139773, 82.02153179851797],
        [-27.855278552785535, 82.13092932439324],
        [-24.845648456484554, 81.78710852878527],
        [-22.901629016290144, 82.09272701377012],
        [-22.07002070020701, 81.73501446884467],
        [-23.1680316803168, 81.15329746617465],
        [-20.622806228062274, 81.5249017604176],
        [-15.769957699576992, 81.91213427264273],
        [-12.771127711277103, 81.7193862508625],
        [-12.209522095220933, 81.2922149593496],
        [-16.284762847628457, 80.58026280682805],
        [-16.84996849968499, 80.34931247442475],
        [-20.046800468004676, 80.17740207662075],
        [-17.731977319773193, 80.12878095400953],
        [-18.898388983889845, 79.39946411484115],
        [-19.704797047970487, 78.75176130291302],
        [-19.67239672396724, 77.63868488884887],
        [-18.47358473584734, 76.9857726709267],
        [-20.036000360003584, 76.94409742297421],
        [-21.681216812168117, 76.62806012600126],
        [-19.834398343983423, 76.09843718327181],
        [-19.600396003960043, 75.24756753757538],
        [-20.66960669606695, 75.15553469834697],
        [-19.373593735937362, 74.29598270932709],
        [-21.594815948159464, 74.22305102541026],
        [-20.43560435604354, 73.81671735787356],
        [-20.763207632076302, 73.46421421894217],
        [-22.17082170821709, 73.30966850778506],
        [-23.56763567635676, 73.30619557045569],
        [-22.314823148231483, 72.62897279122791],
        [-22.300423004230026, 72.18443681306812],
        [-24.276842768427684, 72.59771635526354],
        [-24.79164791647915, 72.33030018090179],
        [-23.441634416344158, 72.08024869318692],
        [-22.131221312213114, 71.46901172321722],
        [-21.753217532175313, 70.66329026280263],
        [-23.53523535235351, 70.4705422410224],
        [-24.30564305643057, 70.85603828458284],
        [-25.544055440554388, 71.43080941259413],
        [-25.2020520205202, 70.75185016470164],
        [-26.361263612636122, 70.2257001593016],
        [-23.726037260372607, 70.1840249113491],
        [-22.34722347223473, 70.13019438274381],
        [-25.029250292502923, 69.25848711307111],
        [-27.747277472774726, 68.47013033930338],
        [-30.67410674106742, 68.12457307503075],
        [-31.775717757177574, 68.12110013770138],
        [-32.81252812528126, 67.73560409414094],
        [-34.2021420214202, 66.67983114601145],
        [-36.351363513635135, 65.97829780547804],
        [-37.04257042570424, 65.93835902619026],
        [-38.37458374583744, 65.69178047580476],
        [-39.810998109981085, 65.45909367473675],
        [-40.66780667806677, 64.83917436144361],
        [-40.682206822068224, 64.13937748957488],
        [-41.18981189811899, 63.48299233432334],
        [-42.820628206282066, 62.68248027990279],
        [-42.417424174241745, 61.901069380793814],
        [-42.86742867428674, 61.07451029640296],
        [-43.37863378633787, 60.096878438184376],
        [-44.78624786247863, 60.03610203492033],
        [-46.262262622626224, 60.85397877598774],
        [-48.263882638826374, 60.85918818198181],
        [-49.23229232292323, 61.40617581135811],
        [-49.90189901899018, 62.3838076695767],
        [-51.633516335163335, 63.62711923349232],
        [-52.1411214112141, 64.27829498274983],
        [-52.277922779227794, 65.17604928239282],
        [-53.660336603366034, 66.09985061200612],
        [-53.300333003330024, 66.83611332583325],
        [-53.96993969939699, 67.18861646476464],
        [-52.97992979929799, 68.35725987609877],
        [-51.475114751147515, 68.7288641703417],
        [-51.079110791107894, 69.14735311853119],
        [-50.870308703087034, 69.92876401764016],
        [-52.0151201512015, 69.57452441004409],
        [-52.558725587255864, 69.42692457354573],
        [-53.45513455134551, 69.28279767437672],
        [-54.682746827468264, 69.60925378333783],
        [-54.75114751147511, 70.28994949989499],
        [-54.35874358743587, 70.8213089112891],
        [-53.42993429934299, 70.8352006606066],
        [-51.38871388713886, 70.56952095490954],
        [-53.10953109531094, 71.20506848618484],
        [-54.00594005940059, 71.54715281312812],
        [-54.99954999549995, 71.40649885128852],
        [-55.834758347583474, 71.6548138703387],
        [-54.71874718747188, 72.58556107461075],
        [-55.32715327153271, 72.95890183751837],
        [-56.11916119161191, 73.65001636606365],
        [-57.32517325173251, 74.7109987201872],
        [-58.59598595985959, 75.0982312324123],
        [-58.58518585185851, 75.5167201806018],
        [-61.26721267212672, 76.10191012060119],
        [-63.39123391233912, 76.17484180451802],
        [-66.0660606606066, 76.13490302523024],
        [-68.50328503285033, 76.06197134131341],
        [-69.6660966609666, 76.37974510695105],
        [-71.40131401314012, 77.00834676356763],
        [-68.77688776887769, 77.32264759187592],
        [-66.76446764467644, 77.37647812048121],
        [-71.04131041310413, 77.6352119515195],
        [-73.29853298532984, 78.04501855638554],
        [-73.15813158131581, 78.43225106861067],
        [-69.37449374493744, 78.91325288872889],
        [-65.70965709657096, 79.39425470884709],
        [-65.32445324453244, 79.75891312843129],
        [-68.02448024480245, 80.11662567335674],
        [-67.14967149671496, 80.51601346623465],
        [-63.690036900368995, 81.21407386943869],
        [-62.23562235622356, 81.32173492664927],
        [-62.64962649626496, 81.76974384213841],
        [-60.28080280802807, 82.03368707917079],
        [-57.206372063720636, 82.18996925899259],
        [-54.13554135541355, 82.2003880709807],
        [-53.044730447304474, 81.88782371133709],
        [-50.39150391503915, 82.43828427804277],
        [-48.00468004680047, 82.06494351513516],
        [-46.60066600666005, 81.98680242522425],
        [-44.52344523445234, 81.66034631626314],
        [-46.899468994689954, 82.2003880709807],
        [-46.76266762667626, 82.62755936249363],
      ],
    ],
    [
      [
        [120.71460714607144, -10.238784824948254],
        [120.29340293402936, -10.2578859802598],
        [118.96858968589686, -9.558089108391087],
        [119.90099900999013, -9.361868149281491],
        [120.42660426604266, -9.665750165601665],
        [120.77580775807758, -9.969632181921824],
        [120.71460714607144, -10.238784824948254],
      ],
    ],
    [
      [
        [125.0886508865089, -9.393124585245857],
        [124.43704437044374, -10.13980611106112],
        [123.58023580235806, -10.360337631476312],
        [123.46143461434616, -10.240521293612943],
        [123.55143551435515, -9.900173435334352],
        [123.97983979839802, -9.290672934029345],
        [124.969849698497, -8.893021609816103],
        [125.08505085050854, -8.656861871418712],
        [125.94545945459458, -8.432857413674142],
        [126.64386643866442, -8.398128040380413],
        [126.9570695706957, -8.273102296522964],
        [127.33507335073352, -8.398128040380413],
        [126.96786967869679, -8.669017152071518],
        [125.92745927459276, -9.106607255572555],
        [125.0886508865089, -9.393124585245857],
      ],
    ],
    [
      [
        [117.89937899378992, -8.095982492724929],
        [118.25938259382593, -8.361662198421982],
        [118.87858878588787, -8.280048171181718],
        [119.12699126991271, -8.70548299402995],
        [117.97137971379715, -8.906913359133597],
        [117.27657276572768, -9.04062144631446],
        [116.740167401674, -9.03367557165572],
        [117.08217082170825, -8.457167974979754],
        [117.63297632976332, -8.448485631656325],
        [117.89937899378992, -8.095982492724929],
      ],
    ],
    [
      [
        [122.90342903429035, -8.09424602406024],
        [122.7558275582756, -8.649915996759972],
        [121.25461254612549, -8.932960389103897],
        [119.92259922599226, -8.809671113911136],
        [119.92259922599226, -8.445012694326948],
        [120.71460714607144, -8.236636454564547],
        [121.34101341013411, -8.537045533555343],
        [122.00702007020072, -8.460640912309131],
        [122.90342903429035, -8.09424602406024],
      ],
    ],
    [
      [
        [108.62208622086223, -6.7780027762277655],
        [110.54090540905412, -6.8769814901149005],
        [110.76050760507604, -6.465438416584163],
        [112.61452614526144, -6.946440236702372],
        [112.97812978129781, -7.594143048630485],
        [114.47934479344792, -7.7764722584225865],
        [115.7069570695707, -8.370344541745425],
        [114.5657456574566, -8.752367647976484],
        [113.46413464134645, -8.349506917769176],
        [112.56052560525609, -8.375553947739476],
        [111.52371523715237, -8.302622263822641],
        [110.5877058770588, -8.122029522695229],
        [109.42849428494287, -7.740006416464169],
        [108.6940869408694, -7.641027702577034],
        [108.27648276482768, -7.766053446434469],
        [106.4548645486455, -7.354510372903732],
        [106.2820628206282, -6.925602612726138],
        [105.36405364053644, -6.8509344601446],
        [106.05166051660518, -5.895876694566951],
        [107.26487264872651, -5.954916629166291],
        [108.07128071280715, -6.345622078720794],
        [108.48528485284857, -6.422026699967006],
        [108.62208622086223, -6.7780027762277655],
      ],
    ],
    [
      [
        [134.7259472594726, -6.2136504602046045],
        [134.2111421114211, -6.8960826454264605],
        [134.1139411394114, -6.142455244952458],
        [134.29034290342906, -5.783006231362322],
        [134.49914499144995, -5.444394841748419],
        [134.7259472594726, -5.737858046080461],
        [134.7259472594726, -6.2136504602046045],
      ],
    ],
    [
      [
        [127.2486724867249, -3.459611158011583],
        [126.87426874268743, -3.791276672966731],
        [126.18306183061833, -3.6072109945099555],
        [125.9886598865989, -3.1765667656676584],
        [127.00027000270006, -3.1296821117211238],
        [127.2486724867249, -3.459611158011583],
      ],
    ],
    [
      [
        [130.4707047070471, -3.0932162697627064],
        [130.83430834308342, -3.8589989508895144],
        [129.9918999189992, -3.4457194086940888],
        [129.1566915669157, -3.3623689127891367],
        [128.59148591485916, -3.4283547220472315],
        [127.90027900279006, -3.3936253487534884],
        [128.13428134281344, -2.843164782047822],
        [129.37269372693726, -2.8014895340953387],
        [130.4707047070471, -3.0932162697627064],
      ],
    ],
    [
      [
        [141.03321033210335, -9.117026067560673],
        [140.1440014400144, -8.297412857828576],
        [139.12879128791292, -8.095982492724929],
        [138.88038880388802, -8.380763353733542],
        [137.61317613176135, -8.412019789697908],
        [138.0379803798038, -7.597615985959862],
        [138.66798667986683, -7.319780999610003],
        [138.4087840878409, -6.232751615516165],
        [137.92637926379263, -5.394037250472508],
        [135.98955989559897, -4.546640542105422],
        [135.1651516515165, -4.46329004620047],
        [133.6639366393664, -3.5394887165871722],
        [133.36873368733688, -4.023963474034744],
        [132.98352983529838, -4.112523375933762],
        [132.75672756727568, -3.746128487684885],
        [132.7531275312753, -3.312011321513225],
        [131.98991989919898, -2.820590689406899],
        [133.06633066330664, -2.461141675816762],
        [133.77913779137793, -2.4802428311283222],
        [133.69633696336962, -2.2145631254312548],
        [132.23112231122315, -2.212826656766566],
        [131.8351183511835, -1.6172179047790536],
        [130.94230942309423, -1.4331522263222638],
        [130.52110521105215, -0.9382586568865747],
        [131.86751867518677, -0.6951530438304445],
        [132.3787237872379, -0.36869693486934807],
        [133.98433984339846, -0.7802400084000851],
        [134.1427414274143, -1.1518443026430276],
        [134.42354423544236, -2.7684966294662985],
        [135.45675456754566, -3.3675783187831883],
        [136.2919629196292, -2.3065959646596497],
        [137.44037440374404, -1.7040413380133828],
        [138.32958329583295, -1.7023048693486942],
        [139.18639186391863, -2.0513350709507137],
        [139.92799927999283, -2.409047615876162],
        [141.00081000810007, -2.6000591689916916],
        [142.73602736027362, -3.2894372288722877],
        [144.5828458284583, -3.860735419554203],
        [145.2740527405274, -4.3729936756367636],
        [145.82845828458284, -4.876569588395881],
        [145.98325983259832, -5.465232465724668],
        [147.64647646476465, -6.083415310353104],
        [147.89127891278912, -6.614774721747224],
        [146.969669696697, -6.722435778957788],
        [147.19287192871928, -7.3875032775327725],
        [148.0856808568086, -8.043888432784328],
        [148.7336873368734, -9.104870786907867],
        [149.3060930609306, -9.071877882278827],
        [149.26649266492666, -9.514677391773915],
        [150.04050040500408, -9.68485132091321],
        [149.73809738097384, -9.872389936699378],
        [150.80010800108005, -10.294351822218232],
        [150.69210692106924, -10.582605620556208],
        [150.029700297003, -10.65206436714368],
        [149.78129781297815, -10.393330536105367],
        [148.92448924489247, -10.280460072900738],
        [147.9128791287913, -10.131123767737677],
        [147.13527135271352, -9.492103299132992],
        [146.56646566465668, -8.943379201092014],
        [146.04806048060482, -8.06819899408994],
        [144.7448474484745, -7.630608890588917],
        [143.8988389883899, -7.915389751597516],
        [143.2868328683287, -8.24531879788799],
        [143.41283412834127, -8.983317980379809],
        [142.62802628026282, -9.327138775987763],
        [142.070020700207, -9.160437784177844],
        [141.03321033210335, -9.117026067560673],
      ],
    ],
    [
      [
        [125.23985239852402, 1.4198657897578926],
        [124.43704437044374, 0.42834218222181164],
        [123.68463684636845, 0.2355941604415932],
        [122.72342723427238, 0.4318151195511888],
        [121.05661056610569, 0.381457528275277],
        [120.18180181801819, 0.23733062910628178],
        [120.04140041400416, -0.5197697086970976],
        [120.93420934209342, -1.4088416650166522],
        [121.47421474214741, -0.9556233435334462],
        [123.3390333903339, -0.6152754852548554],
        [123.259832598326, -1.0754396813968157],
        [122.82422824228246, -0.9313127822278204],
        [122.38862388623886, -1.51650272222723],
        [121.50661506615069, -1.9037352344523555],
        [122.45342453424536, -3.1852491089911013],
        [122.27342273422738, -3.529069904599055],
        [123.16983169831701, -4.683821566615677],
        [123.16263162631628, -5.340206721867219],
        [122.62982629826297, -5.635406394863949],
        [122.23742237422374, -5.282903255932567],
        [122.71982719827201, -4.4650265148651584],
        [121.7370173701737, -4.850522558425595],
        [121.48861488614887, -4.574424040740411],
        [121.61821618216186, -4.188927997179974],
        [120.89820898208984, -3.60200158851589],
        [120.97380973809737, -2.6278426676266804],
        [120.30420304203045, -2.9317246839468396],
        [120.39060390603908, -4.096895157951579],
        [120.43020430204302, -5.527745337653386],
        [119.79659796597969, -5.673608705487055],
        [119.36819368193682, -5.380145501155013],
        [119.6525965259653, -4.459817108871093],
        [119.49779497794981, -3.494340531305312],
        [119.07659076590767, -3.487394656646572],
        [118.7669876698767, -2.8014895340953387],
        [119.18099180991811, -2.1468408475084857],
        [119.3249932499325, -1.353274667746689],
        [119.82539825398254, 0.15398013320132975],
        [120.03420034200343, 0.5672596753967554],
        [120.88740887408875, 1.3087317952179518],
        [121.6686166861669, 1.0135321222212212],
        [122.92862928629285, 0.8746146290462917],
        [124.07704077040773, 0.9162898769987606],
        [125.06705067050672, 1.643870247502477],
        [125.23985239852402, 1.4198657897578926],
      ],
    ],
    [
      [
        [128.68868688686888, 1.1316119914199163],
        [128.63468634686348, 0.25816825308253044],
        [128.11988119881198, 0.3571469669696654],
        [127.96867968679686, -0.2523535343353416],
        [128.3790837908379, -0.7802400084000851],
        [128.09828098280985, -0.9000563462634688],
        [127.69507695076953, -0.26624528365283595],
        [127.39987399874002, 1.0117956535565327],
        [127.60147601476018, 1.8105712393123952],
        [127.93267932679328, 2.1752296588965834],
        [128.0046800468005, 1.628242029520294],
        [128.59508595085953, 1.5414185962859648],
        [128.68868688686888, 1.1316119914199163],
      ],
    ],
    [
      [
        [105.8176581765818, -5.852464977949779],
        [104.70884708847092, -5.873302601926028],
        [103.86643866438664, -5.038061174211748],
        [102.5848258482585, -4.22018443314434],
        [102.15642156421563, -3.6141568691686956],
        [101.40041400414003, -2.7997530654306644],
        [100.90360903609036, -2.049598602286025],
        [100.14040140401403, -0.6500048585485843],
        [99.26199261992622, 0.18350010050100707],
        [98.97038970389707, 1.0430520895208844],
        [98.59958599585997, 1.822726519965201],
        [97.69957699576997, 2.4530646452464424],
        [97.17757177571775, 3.309143696936971],
        [96.42516425164251, 3.868286606966066],
        [95.38115381153813, 4.970944209042088],
        [95.2947529475295, 5.479729527795271],
        [95.93555935559357, 5.439790748507477],
        [97.48357483574836, 5.247042726727258],
        [98.36918369183695, 4.267674399843997],
        [99.14319143191432, 3.590451620616207],
        [99.6939969399694, 3.1736991410914044],
        [100.64080640806407, 2.0988250376503714],
        [101.65961659616596, 2.0831968196681885],
        [102.49842498424988, 1.3990281657816581],
        [103.07803078030781, 0.5620502694026897],
        [103.83763837638378, 0.10535901059010655],
        [103.43803438034382, -0.712517730477316],
        [104.01044010440103, -1.0598114634146327],
        [104.37044370443704, -1.0841220247202585],
        [104.53964539645398, -1.7821824279242833],
        [104.8888488884889, -2.339588869288704],
        [105.62325623256231, -2.428148771187722],
        [106.10926109261095, -3.0619598337983405],
        [105.85725857258575, -4.30527139771398],
        [105.8176581765818, -5.852464977949779],
      ],
    ],
    [
      [
        [-14.509945099450988, 66.45582668826688],
        [-14.740347403474033, 65.80812387633875],
        [-13.609936099360993, 65.1274281597816],
        [-14.909549095490945, 64.36338194731945],
        [-17.793177931779326, 63.67921329343292],
        [-18.657186571865708, 63.49688408364082],
        [-19.971199711997116, 63.64448392013918],
        [-22.761227612276116, 63.96052121711216],
        [-21.778417784177833, 64.40158425794257],
        [-23.95643956439565, 64.89126842138421],
        [-22.18522185221852, 65.0857529118291],
        [-22.22842228422283, 65.37921611616116],
        [-24.327243272432725, 65.61190291722917],
        [-23.650436504365047, 66.26307866648665],
        [-22.134821348213478, 66.41067850298504],
        [-20.576005760057598, 65.73171925509254],
        [-19.056790567905665, 66.27697041580416],
        [-17.800378003780025, 65.99392602346023],
        [-16.16956169561695, 66.52702190351903],
        [-14.509945099450988, 66.45582668826688],
      ],
    ],
    [
      [
        [15.521555215552155, 38.23126501245011],
        [15.161551615516174, 37.44464470734707],
        [15.30915309153093, 37.13381681636815],
        [15.100351003510042, 36.619822091620904],
        [14.333543335433347, 36.99663579185791],
        [13.82593825938261, 37.10429684906849],
        [12.432724327243278, 37.61308216782167],
        [12.56952569525697, 38.12707689256891],
        [13.739537395373958, 38.03504405334053],
        [14.761947619476189, 38.144441579215794],
        [15.521555215552155, 38.23126501245011],
      ],
    ],
    [
      [
        [9.210692106921073, 41.209308772387715],
        [9.808298082980826, 40.50082955719557],
        [9.667896678966798, 39.17764043470433],
        [9.214292142921437, 39.24015330663306],
        [8.807488074880752, 38.906751323013225],
        [8.42948429484295, 39.17243102871029],
        [8.389883898839003, 40.377540282002826],
        [8.159481594815958, 40.9505749413494],
        [8.710287102871035, 40.90021735007349],
        [9.210692106921073, 41.209308772387715],
      ],
    ],
    [
      [
        [-77.56817568175681, 18.49108923229231],
        [-76.8949689496895, 18.40079286172862],
        [-76.36576365763658, 18.16116018600185],
        [-76.20016200162001, 17.88679813698137],
        [-76.90216902169021, 17.86769698166981],
        [-77.20457204572045, 17.70099598985989],
        [-77.76617766177661, 17.86075110701107],
        [-78.33858338583386, 18.225409526595257],
        [-78.21618216182162, 18.454623390333893],
        [-77.79857798577986, 18.524082136921365],
        [-77.56817568175681, 18.49108923229231],
      ],
    ],
    [
      [
        [134.63954639546398, 34.14882718177181],
        [134.76554765547655, 33.80674285482854],
        [134.20394203942038, 33.2007152908529],
        [133.7935379353794, 33.52196199381993],
        [133.2787327873279, 33.289275192751916],
        [133.0159301593016, 32.70408525275252],
        [132.36432364323645, 32.988866113761134],
        [132.37152371523717, 33.46292205922059],
        [132.92592925929262, 34.06026727987279],
        [133.49473494734946, 33.94392387933878],
        [133.9051390513905, 34.36414929619295],
        [134.63954639546398, 34.14882718177181],
      ],
    ],
    [
      [
        [140.97560975609758, 37.142499159691596],
        [140.60120601206012, 36.34372357393573],
        [140.77400774007742, 35.84362059850598],
        [140.2520025200252, 35.1386143206432],
        [138.97398973989743, 34.66803131251312],
        [137.21717217172176, 34.605518440584405],
        [135.79155791557918, 33.46465852788528],
        [135.1219512195122, 33.848418102781025],
        [135.0787507875079, 34.59683609726096],
        [133.33993339933403, 34.37630457684577],
        [132.15552155521556, 33.90572156871568],
        [130.98550985509854, 33.88662041340413],
        [132.00072000720007, 33.150357699576986],
        [131.3311133111331, 31.450354876848763],
        [130.68670686706866, 31.030129459994598],
        [130.20070200702008, 31.419098440884397],
        [130.44910449104492, 32.32032567785677],
        [129.81549815498158, 32.61031594485944],
        [129.4086940869409, 33.29622106741067],
        [130.35550355503557, 33.603576021060206],
        [130.8775087750878, 34.23217767767677],
        [131.88551885518854, 34.749645339753386],
        [132.61632616326165, 35.43381399363993],
        [134.6071460714607, 35.73075013530135],
        [135.67635676356764, 35.527583301533014],
        [136.7239672396724, 37.30572721417214],
        [137.389973899739, 36.828198331383305],
        [138.8587885878859, 37.826667813578126],
        [139.4275942759428, 38.21563679446794],
        [140.0540005400054, 39.43811073440733],
        [139.88479884798846, 40.563342429124276],
        [140.3060030600306, 41.195417023070235],
        [141.3680136801368, 41.377746232862336],
        [141.91521915219153, 39.99204423844239],
        [141.8828188281883, 39.18111337203371],
        [140.96120961209613, 38.17396154651547],
        [140.97560975609758, 37.142499159691596],
      ],
    ],
    [
      [
        [143.90963909639095, 44.17346078300781],
        [144.6116461164612, 43.96161160591606],
        [145.32085320853207, 44.38530996009959],
        [145.54405544055442, 43.261814734047334],
        [144.06084060840607, 42.98918915369154],
        [143.18243182431826, 41.99592907749076],
        [141.61281612816128, 42.67836126271263],
        [141.06561065610657, 41.58438600396002],
        [139.9567995679957, 41.56875778597785],
        [139.81639816398166, 42.563754330843295],
        [140.31320313203133, 43.33300994929948],
        [141.3788137881379, 43.38857694656946],
        [141.67041670416705, 44.772542472324716],
        [141.96921969219693, 45.55221690276903],
        [143.1428314283143, 44.510335703957026],
        [143.90963909639095, 44.17346078300781],
      ],
    ],
    [
      [
        [81.78741787417874, 7.523553146131462],
        [81.63621636216362, 6.4816719473194695],
        [81.21861218612185, 6.196891086310856],
        [80.34740347403476, 5.96767722257222],
        [79.87219872198722, 6.762979870998706],
        [79.6957969579696, 8.200775925359252],
        [80.14940149401497, 9.824374126841263],
        [80.84060840608407, 9.268704154141531],
        [81.30501305013053, 8.563697876278752],
        [81.78741787417874, 7.523553146131462],
      ],
    ],
    [
      [
        [49.5418954189542, -12.470147059070598],
        [49.808298082980826, -12.89558188191883],
        [50.05670056700569, -13.555439974499748],
        [50.218702187021876, -14.758812759127593],
        [50.477904779047805, -15.225922829928308],
        [50.377103771037724, -15.706924650046503],
        [50.200702007020084, -16.000387854378545],
        [49.86229862298623, -15.41346144571446],
        [49.67149671496716, -15.71039758737588],
        [49.86229862298623, -16.451869707197076],
        [49.77589775897761, -16.87556806138062],
        [49.498694986949886, -17.106518393783944],
        [49.43389433894339, -17.95391510215103],
        [49.04149041490416, -19.11908557615577],
        [48.54828548285482, -20.496105227252272],
        [47.92907929079291, -22.39232900909009],
        [47.54747547475475, -23.781503940839414],
        [47.09747097470975, -24.941465008850088],
        [46.28386283862838, -25.177624747247478],
        [45.409054090540906, -25.60132310143102],
        [44.83304833048331, -25.346062207722085],
        [44.04104041040412, -24.988349662796637],
        [43.7638376383764, -24.460463188731893],
        [43.699036990369905, -23.5748641697417],
        [43.346233462334624, -22.77608858398584],
        [43.25263252632527, -22.057190556805566],
        [43.432634326343276, -21.336556060960618],
        [43.893438934389366, -21.162909194491945],
        [43.8970389703897, -20.831243679536797],
        [44.37584375843758, -20.07240687306873],
        [44.4658446584466, -19.435122873128734],
        [44.23184231842319, -18.962803396333967],
        [44.044640446404486, -18.330728802388023],
        [43.9618396183962, -17.410400410104103],
        [44.311043110431115, -16.851257500075008],
        [44.44784447844478, -16.215709968799686],
        [44.94464944649448, -16.17924412684127],
        [45.502655026550286, -15.974340824408245],
        [45.87345873458736, -15.793748083280832],
        [46.312663126631264, -15.779856333963338],
        [46.88146881468816, -15.210294611946125],
        [47.7058770587706, -14.593848235982364],
        [48.00468004680047, -14.09200879188792],
        [47.86787867878681, -13.663101031710326],
        [48.292682926829286, -13.784653838238384],
        [48.843488434884364, -13.088329903699048],
        [48.86508865088652, -12.487511745717455],
        [49.196291962919645, -12.04123929889299],
        [49.5418954189542, -12.470147059070598],
      ],
    ],
    [
      [
        [165.779857798578, -21.079558698586993],
        [166.6006660066601, -21.699478011880117],
        [167.1190711907119, -22.159642208022078],
        [166.74106741067413, -22.399274883748845],
        [166.19026190261906, -22.130122240722415],
        [165.4738547385474, -21.68037685656857],
        [164.82944829448297, -21.14901744517445],
        [164.16704167041672, -20.444011167311672],
        [164.03024030240306, -20.105399777697784],
        [164.45864458644587, -20.119291527015278],
        [165.02025020250204, -20.459639385293855],
        [165.45945459454595, -20.799987243572446],
        [165.779857798578, -21.079558698586993],
      ],
    ],
    [
      [
        [24.723247232472346, 77.85400700327003],
        [22.491224912249123, 77.44420039840398],
        [20.727207272072718, 77.67688719947199],
        [21.414814148141488, 77.9356210305103],
        [20.81360813608137, 78.25513126481263],
        [22.88362883628838, 78.45482516125159],
        [23.279632796327974, 78.07974792967929],
        [24.723247232472346, 77.85400700327003],
      ],
    ],
    [
      [
        [18.25038250382505, 79.70160966249662],
        [21.544415444154453, 78.95666460534605],
        [19.02799027990281, 78.56248621846217],
        [18.469984699847004, 77.82622350463504],
        [17.59517595175953, 77.63868488884887],
        [17.119971199712012, 76.80865286712867],
        [15.913959139591412, 76.77045055650555],
        [13.761137611376114, 77.37995105781056],
        [14.668346683466837, 77.73592713407135],
        [13.170731707317088, 78.02418093240931],
        [11.223112231122315, 78.8698411721117],
        [10.445504455044556, 79.6529885398854],
        [13.170731707317088, 80.01070108481085],
        [13.717937179371802, 79.65993441454413],
        [15.143551435514354, 79.67382616386163],
        [15.521555215552155, 80.01591049080491],
        [16.990369903699047, 80.05063986409863],
        [18.25038250382505, 79.70160966249662],
      ],
    ],
    [
      [
        [25.4468544685447, 80.40661594035939],
        [27.4088740887409, 80.0558492700927],
        [25.92565925659258, 79.51754398403983],
        [23.02403024030241, 79.39946411484115],
        [20.07560075600756, 79.56616510665106],
        [19.895598955989556, 79.84226362433623],
        [18.462784627846275, 79.85962831098311],
        [17.36837368373685, 80.31805603846038],
        [20.457204572045725, 80.59762749347493],
        [21.908019080190797, 80.35799481774816],
        [22.919629196291964, 80.65666742807429],
        [25.4468544685447, 80.40661594035939],
      ],
    ],
    [
      [
        [173.01953019530197, -40.91871319263193],
        [173.24633246332462, -41.33199273482735],
        [173.9591395913959, -40.92739553595536],
        [174.24714247142475, -41.34935742147422],
        [174.24714247142475, -41.76958283832838],
        [173.87633876338765, -42.23321997179972],
        [173.22113221132213, -42.96948268562686],
        [172.709927099271, -43.37234341583416],
        [173.0807308073081, -43.85334523595236],
        [172.31032310323104, -43.86550051660517],
        [171.45351453514536, -44.24231421684217],
        [171.18351183511834, -44.896962903429035],
        [170.61830618306186, -45.90932413494135],
        [169.82989829898298, -46.35559658176582],
        [169.3330933309333, -46.64037744277443],
        [168.4114841148412, -46.61953981879819],
        [167.76347763477634, -46.28961077250773],
        [166.67626676266764, -46.220152025920264],
        [166.51066510665106, -45.85202066900669],
        [167.04707047070474, -45.110548549185495],
        [168.30348303483038, -44.12423434764348],
        [168.9478894788948, -43.93495926319263],
        [169.66789667896683, -43.55467262562626],
        [170.5247052470525, -43.031995557555575],
        [171.12591125911263, -42.51279142681427],
        [171.5687156871569, -41.7678463696637],
        [171.95031950319503, -41.51432194461945],
        [172.0979209792098, -40.95691550325503],
        [172.79992799928, -40.4932783697837],
        [173.01953019530197, -40.91871319263193],
      ],
    ],
    [
      [
        [174.61074610746107, -36.15557964539646],
        [175.33795337953381, -37.20961612486125],
        [175.35595355953564, -36.52544747097471],
        [175.809558095581, -36.7998095199952],
        [175.95715957159575, -37.55517338913389],
        [176.7635676356764, -37.88162949809498],
        [177.44037440374404, -37.96150705667057],
        [178.00918009180094, -37.57948395043951],
        [178.5167851678517, -37.69582735097351],
        [178.27558275582754, -38.58316283862839],
        [177.969579695797, -39.1666163099631],
        [177.20637206372066, -39.14577868598686],
        [176.939969399694, -39.44966070230703],
        [177.03357033570336, -39.88030493114931],
        [176.8859688596886, -40.06610707827078],
        [176.50796507965083, -40.60441236432364],
        [176.01116011160116, -41.29031748687487],
        [175.2407524075241, -41.68796881108811],
        [175.0679506795068, -41.42576204272043],
        [174.65034650346507, -41.28163514355144],
        [175.22635226352264, -40.45854899648997],
        [174.8987489874899, -39.9080884297843],
        [173.82233822338225, -39.50870063690637],
        [173.8511385113851, -39.14577868598686],
        [174.57474574745748, -38.79848495304953],
        [174.74394743947443, -38.027492865928664],
        [174.69714697146975, -37.381526522665226],
        [174.29034290342906, -36.71124961809618],
        [174.31914319143192, -36.53412981429815],
        [173.84033840338407, -36.12258674076741],
        [173.05553055530555, -35.23698772177722],
        [172.63432634326347, -34.52850850658507],
        [173.00873008730088, -34.45036741667417],
        [173.55233552335523, -35.006037389373894],
        [174.329943299433, -35.26477122041221],
        [174.61074610746107, -36.15557964539646],
      ],
    ],
    [
      [
        [126.37746377463776, 8.414361571115705],
        [126.47826478264784, 7.7510305412054095],
        [126.53586535865361, 7.190151162511626],
        [126.19746197461978, 6.275032176221757],
        [125.83025830258305, 7.294339282392812],
        [125.36225362253623, 6.787290432304317],
        [125.68265682656829, 6.049291249812498],
        [125.39825398253981, 5.580444710347095],
        [124.22104221042213, 6.162161713017127],
        [123.94023940239401, 6.884532677526764],
        [124.24264242642425, 7.360325091650907],
        [123.60903609036092, 7.8343810371103615],
        [123.29583295832958, 7.4193650262502615],
        [122.82422824228246, 7.4575673368733675],
        [122.08622086220862, 6.900160895508947],
        [121.9206192061921, 7.1918876311763],
        [122.31302313023133, 8.035811402214023],
        [122.9430294302943, 8.31538285722857],
        [123.48663486634865, 8.692196557465564],
        [123.83943839438393, 8.240714704647047],
        [124.60264602646026, 8.51334028500284],
        [124.76464764647648, 8.959612731827306],
        [125.47025470254704, 8.987396230462295],
        [125.41265412654127, 9.760124786247857],
        [126.22266222662228, 9.286068840788403],
        [126.30546305463054, 8.78249292802927],
        [126.37746377463776, 8.414361571115705],
      ],
    ],
    [
      [
        [123.98343983439838, 10.279328916989158],
        [123.62343623436237, 9.949399870698699],
        [123.31023310233104, 9.319061745417443],
        [122.9970299702997, 9.022125603756038],
        [122.38142381423813, 9.713240132301323],
        [122.58662586625866, 9.980656306663064],
        [122.83862838628386, 10.2619642303423],
        [122.94662946629467, 10.881883543635425],
        [123.49743497434974, 10.94092347823478],
        [123.3390333903339, 10.267173636336352],
        [124.07704077040773, 11.232650213902133],
        [123.98343983439838, 10.279328916989158],
      ],
    ],
    [
      [
        [118.5041850418504, 9.31558880808808],
        [117.1757717577176, 8.36747691716917],
        [117.66537665376654, 9.067273789037884],
        [118.38538385383856, 9.683720165001645],
        [118.98658986589868, 10.376571162211619],
        [119.51219512195121, 11.369831238412374],
        [119.68859688596888, 10.553690966009654],
        [119.02979029790299, 10.003230399303987],
        [118.5041850418504, 9.31558880808808],
      ],
    ],
    [
      [
        [121.88461884618846, 11.892508306483066],
        [122.48222482224821, 11.581680415504152],
        [123.11943119431197, 11.583416884168841],
        [123.10143101431015, 11.166664404644038],
        [122.6370263702637, 10.741229581795807],
        [122.00342003420036, 10.440820502805025],
        [121.96741967419678, 10.90619410494105],
        [122.03942039420394, 11.414979423694234],
        [121.88461884618846, 11.892508306483066],
      ],
    ],
    [
      [
        [125.50265502655026, 12.16339741817417],
        [125.78345783457837, 11.046848066780669],
        [125.01305013050131, 11.310791303813033],
        [125.03105031050313, 10.975652851528508],
        [125.2758527585276, 10.359206475564747],
        [124.80064800648006, 10.135202017820177],
        [124.76104761047611, 10.838471827018267],
        [124.45864458644587, 10.890565886958868],
        [124.30384303843039, 11.494856982269823],
        [124.89064890648905, 11.414979423694234],
        [124.87624876248765, 11.793529592595917],
        [124.2678426784268, 12.55757580505805],
        [125.22545225452257, 12.535001712417113],
        [125.50265502655026, 12.16339741817417],
      ],
    ],
    [
      [
        [121.52821528215281, 13.069834061140611],
        [121.26181261812621, 12.205072666126654],
        [120.83340833408334, 12.705175641556409],
        [120.32220322203221, 13.465748916689165],
        [121.1790117901179, 13.429283074730748],
        [121.52821528215281, 13.069834061140611],
      ],
    ],
    [
      [
        [121.32301323013229, 18.50324451294513],
        [121.93861938619386, 18.218463651936517],
        [122.24462244622447, 18.478933951639505],
        [122.33822338223382, 18.225409526595257],
        [122.1726217262173, 17.810393515735157],
        [122.51462514625149, 17.093231957219572],
        [122.2518225182252, 16.263199935499344],
        [121.66141661416617, 15.931534420544196],
        [121.50661506615069, 15.124076491464905],
        [121.72981729817297, 14.32877384303842],
        [122.25902259022592, 14.217639848498479],
        [122.7018270182702, 14.335719717697174],
        [123.9510395103951, 13.78178621366213],
        [123.85383853838539, 13.238271521615218],
        [124.18144181441818, 12.996902377223762],
        [124.07704077040773, 12.536738181081802],
        [123.29943299432995, 13.028158813188128],
        [122.92862928629285, 13.552572349923494],
        [122.67302673026734, 13.186177461674617],
        [122.03582035820358, 13.785259150991507],
        [121.1250112501125, 13.635922845828446],
        [120.62820628206282, 13.858190834908342],
        [120.67860678606786, 14.271470377103768],
        [120.9918099180992, 14.524994802148015],
        [120.69300693006932, 14.75594513455134],
        [120.56340563405632, 14.396496120961203],
        [120.07020070200701, 14.971267248972481],
        [119.92259922599226, 15.40712088380883],
        [119.88299882998831, 16.36391511805118],
        [120.28620286202863, 16.033986071760708],
        [120.39060390603908, 17.59854433864338],
        [120.71460714607144, 18.504980981609805],
        [121.32301323013229, 18.50324451294513],
      ],
    ],
    [
      [
        [155.879758797588, -6.819678024180249],
        [155.5989559895599, -6.920393206732072],
        [155.16695166951672, -6.536633631836324],
        [154.72774727747276, -5.901086100561017],
        [154.51534515345156, -5.138776356763572],
        [154.65214652146523, -5.0432705802057995],
        [154.76014760147604, -5.340206721867219],
        [155.06255062550628, -5.565947648276492],
        [155.54855548555486, -6.201495179551799],
        [156.02016020160204, -6.540106569165701],
        [155.879758797588, -6.819678024180249],
      ],
    ],
    [
      [
        [151.98451984519846, -5.477387746377474],
        [151.45891458914588, -5.560738242282426],
        [151.30051300513009, -5.8403096972969735],
        [150.75330753307531, -6.083415310353104],
        [150.24210242102424, -6.317838580085805],
        [149.70929709297093, -6.316102111421117],
        [148.8884888848889, -6.026111844418452],
        [148.319683196832, -5.746540389403904],
        [148.40248402484025, -5.437448967089679],
        [149.29889298892988, -5.583312334923349],
        [149.84609846098465, -5.505171245012448],
        [149.99729997299977, -5.025905893558942],
        [150.14130141301416, -5.001595332253331],
        [150.23850238502388, -5.532954743647437],
        [150.80730807308072, -5.456550122401225],
        [151.08811088110883, -5.11446579545796],
        [151.64611646116464, -4.756753250532512],
        [151.53811538115383, -4.168090373203739],
        [152.13572135721358, -4.148989217892179],
        [152.33732337323374, -4.3122172723727346],
        [152.31932319323192, -4.867887245072453],
        [151.98451984519846, -5.477387746377474],
      ],
    ],
    [
      [
        [153.14013140131402, -4.499755888158887],
        [152.8269282692827, -4.767172062520629],
        [152.63972639726398, -4.176772716527168],
        [152.4057240572406, -3.7895402043020425],
        [151.95211952119524, -3.4613476266762717],
        [151.38331383313835, -3.0359128038280403],
        [150.66330663306633, -2.7407131308313097],
        [150.94050940509408, -2.499343986439868],
        [151.48051480514806, -2.7806519101191043],
        [151.8189181891819, -2.999446961869623],
        [152.24012240122403, -3.2408161062610645],
        [152.63972639726398, -3.659305054450556],
        [153.02133021330212, -3.9805517574175724],
        [153.14013140131402, -4.499755888158887],
      ],
    ],
    [
      [
        [-66.2820628206282, 18.515399793597936],
        [-65.77085770857708, 18.42683989169892],
        [-65.59085590855908, 18.228882463924634],
        [-65.84645846458464, 17.975358038880387],
        [-66.59886598865988, 17.982303913539127],
        [-67.18567185671856, 17.94583807158071],
        [-67.24327243272432, 18.374745831758318],
        [-67.09927099270992, 18.520609199591988],
        [-66.2820628206282, 18.515399793597936],
      ],
    ],
    [
      [
        [143.6468364683647, 50.74773114751147],
        [144.6548465484655, 48.97653310953109],
        [143.17523175231753, 49.30646215582155],
        [142.55962559625596, 47.86172022680225],
        [143.53523535235354, 46.83720371463713],
        [143.50643506435068, 46.13740684276843],
        [142.74682746827472, 46.73996146941468],
        [142.0916209162092, 45.96723291362912],
        [141.9080190801908, 46.80594727867279],
        [142.01962019620197, 47.780106199562],
        [141.90441904419043, 48.85845324033241],
        [142.1348213482135, 49.615553578135774],
        [142.18162181621818, 50.9526344499445],
        [141.59481594815952, 51.93547571415712],
        [141.68121681216815, 53.30207655326552],
        [142.60642606426063, 53.76224074940748],
        [142.21042210422104, 54.22587788287882],
        [142.6532265322653, 54.36653184471845],
        [142.9160291602916, 53.704937283472816],
        [143.26163261632615, 52.74119717457174],
        [143.23643236432366, 51.7566194416944],
        [143.6468364683647, 50.74773114751147],
      ],
    ],
    [
      [
        [180, 70.83172772327723],
        [178.9019890198902, 70.78137013200131],
        [178.7255872558726, 71.09914389763898],
        [-180, 71.51589637716376],
        [-179.87039870398704, 71.55757162511625],
        [-179.02439024390245, 71.55583515645156],
        [-177.5771757717577, 71.26931782677826],
        [-177.66357663576636, 71.13213680226801],
        [-178.69318693186932, 70.89250412654127],
        [-180, 70.83172772327723],
        [180, 70.83172772327723],
      ],
    ],
    [
      [
        [143.6036360363604, 73.21242626256262],
        [142.08802088020883, 73.20548038790386],
        [140.03960039600395, 73.31661438244382],
        [139.86319863198634, 73.3704449110491],
        [140.810008100081, 73.76462329793296],
        [142.06282062820628, 73.85839260582605],
        [143.48123481234813, 73.4746330309303],
        [143.6036360363604, 73.21242626256262],
      ],
    ],
    [
      [
        [150.7317073170732, 75.08433948309482],
        [149.57609576095763, 74.68842462754625],
        [147.9776797767978, 74.77872099810997],
        [146.12006120061204, 75.17289938499385],
        [146.3576635766358, 75.49761902529025],
        [148.22248222482227, 75.34654625146251],
        [150.7317073170732, 75.08433948309482],
      ],
    ],
    [
      [
        [145.0868508685087, 75.56186836588364],
        [144.29844298442987, 74.82039624606244],
        [140.61560615606157, 74.84817974469743],
        [138.9559895598956, 74.61202000630004],
        [136.9759697596976, 75.26145928689286],
        [137.51237512375127, 75.94910087810877],
        [138.82998829988298, 76.13663949389493],
        [141.47241472414726, 76.09322777727778],
        [145.0868508685087, 75.56186836588364],
      ],
    ],
    [
      [
        [57.5339753397534, 70.72059372873727],
        [56.94356943569437, 70.63203382683827],
        [53.678336783367854, 70.76226897668977],
        [53.4119341193412, 71.20680495484953],
        [51.601116011160116, 71.47422112921129],
        [51.45711457114572, 72.01426288392884],
        [52.479524795247954, 72.22958499834996],
        [52.44352443524437, 72.77483615906158],
        [54.42714427144273, 73.62744227342273],
        [53.50913509135091, 73.74899507995079],
        [55.90315903159032, 74.62764822428224],
        [55.63315633156333, 75.08086654576545],
        [57.868778687786886, 75.6087530198302],
        [61.170011700117016, 76.25124642576424],
        [64.50004500045, 76.4387850415504],
        [66.210062100621, 76.81038933579336],
        [68.15768157681578, 76.93888801698017],
        [68.85248852488525, 76.54470963009629],
        [68.17928179281793, 76.23388173911738],
        [64.6368463684637, 75.73725170101702],
        [61.5840158401584, 75.26145928689286],
        [58.47718477184773, 74.30987445864457],
        [56.98676986769868, 73.33224260042599],
        [55.420754207542075, 72.37197542885428],
        [55.622356223562235, 71.54020693846937],
        [57.5339753397534, 70.72059372873727],
      ],
    ],
    [
      [
        [105.0760507605076, 78.30722532475323],
        [99.43839438394383, 77.9217292811928],
        [101.26361263612637, 79.23449959169591],
        [102.08802088020883, 79.34563358623586],
        [102.8368283682837, 79.28138424564244],
        [105.37125371253711, 78.7135589922899],
        [105.0760507605076, 78.30722532475323],
      ],
    ],
    [
      [
        [51.13671136711369, 80.54726990219902],
        [49.7938979389794, 80.41529828368283],
        [48.893888938889404, 80.33889366243662],
        [48.75348753487535, 80.17566560795606],
        [47.587075870758724, 80.01070108481085],
        [46.50346503465036, 80.24686082320824],
        [47.07227072270723, 80.55942518285181],
        [44.847448474484764, 80.58894515015149],
        [46.798667986679874, 80.77127435994359],
        [48.317883178831806, 80.78342964059641],
        [48.5230852308523, 80.51427699756997],
        [49.095490954909565, 80.75390967329673],
        [50.03870038700387, 80.91887419644195],
        [51.52191521915219, 80.70007914469144],
        [51.13671136711369, 80.54726990219902],
      ],
    ],
    [
      [
        [99.93879938799387, 78.88025998409984],
        [97.75717757177574, 78.75697070890709],
        [94.97434974349744, 79.04522450724505],
        [93.31113311133112, 79.42724761347611],
        [92.54432544325442, 80.14440917199173],
        [91.17991179911797, 80.3406301311013],
        [93.77913779137793, 81.02479878498784],
        [95.93915939159393, 81.2505397113971],
        [97.88317883178831, 80.74696379863798],
        [100.1872018720187, 79.77975075240752],
        [99.93879938799387, 78.88025998409984],
      ],
    ],
    [
      [
        [162.1186211862119, -10.481890438004385],
        [162.39942399423995, -10.825711233612338],
        [161.70101701017012, -10.820501827618287],
        [161.31941319413193, -10.204055451654526],
        [161.91701917019174, -10.447161064710656],
        [162.1186211862119, -10.481890438004385],
      ],
    ],
    [
      [
        [160.85140851408516, -9.872389936699378],
        [160.4626046260463, -9.8949640293403],
        [159.8505985059851, -9.794248846788477],
        [159.6417964179642, -9.639703135631365],
        [159.70299702997033, -9.24378828008281],
        [160.36180361803622, -9.400070459904597],
        [160.68940689406895, -9.610183168331687],
        [160.85140851408516, -9.872389936699378],
      ],
    ],
    [
      [
        [161.67941679416793, -9.59976435634357],
        [161.52821528215281, -9.783830034800346],
        [160.78660786607867, -8.917332171121714],
        [160.58140581405814, -8.319986950469513],
        [160.91980919809197, -8.319986950469513],
        [161.27981279812798, -9.12049900489005],
        [161.67941679416793, -9.59976435634357],
      ],
    ],
    [
      [
        [159.87579875798758, -8.33735163711637],
        [159.9189991899919, -8.538782002220032],
        [159.13419134191344, -8.1133471793718],
        [158.58698586985872, -7.755634634446352],
        [158.21258212582126, -7.4222326508265155],
        [158.360183601836, -7.319780999610003],
        [158.8209882098821, -7.5594136753367565],
        [159.6417964179642, -8.019577871478717],
        [159.87579875798758, -8.33735163711637],
      ],
    ],
    [
      [
        [157.53937539375397, -7.347564498244992],
        [157.3377733777338, -7.404867964179644],
        [156.9021690216902, -7.177390569105697],
        [156.49176491764916, -6.76584749557496],
        [156.5421654216542, -6.599146503765041],
        [157.13977139771401, -7.021108389283896],
        [157.53937539375397, -7.347564498244992],
      ],
    ],
    [
      [
        [-61.68121681216812, 10.760330737107367],
        [-61.10521105211052, 10.890565886958868],
        [-60.89640896408963, 10.855836513665125],
        [-60.93600936009359, 10.109154987849877],
        [-61.77121771217712, 9.99975746197461],
        [-61.951219512195124, 10.090053832538317],
        [-61.65961659616596, 10.364415881558813],
        [-61.68121681216812, 10.760330737107367],
      ],
    ],
    [
      [
        [121.77661776617765, 24.39508269222692],
        [121.17541175411753, 22.790585646056456],
        [120.74700747007472, 21.97097243632436],
        [120.22140221402213, 22.814896207362068],
        [120.10620106201065, 23.556368327183264],
        [120.69300693006932, 24.539209591395903],
        [121.4958149581496, 25.296309929199282],
        [121.95301953019532, 24.99763731887319],
        [121.77661776617765, 24.39508269222692],
      ],
    ],
    [
      [
        [-155.54135541355413, 19.08322504695046],
        [-155.68895688956889, 18.916524055140542],
        [-155.93735937359372, 19.05891448564485],
        [-155.90855908559087, 19.338485940659396],
        [-156.07416074160741, 19.7031443602436],
        [-156.02376023760237, 19.81427835478354],
        [-155.8509585095851, 19.97750640926408],
        [-155.91935919359193, 20.173727368373676],
        [-155.8617586175862, 20.26749667626676],
        [-155.7861578615786, 20.2483955209552],
        [-155.4009540095401, 20.079958060480593],
        [-155.22455224552246, 19.993134627246263],
        [-155.06255062550625, 19.8594265400654],
        [-154.80694806948068, 19.50865986979869],
        [-154.8321483214832, 19.453092872528714],
        [-155.2209522095221, 19.23950722677226],
        [-155.54135541355413, 19.08322504695046],
      ],
    ],
    [
      [
        [-156.07776077760778, 20.644310376503753],
        [-156.4161641616416, 20.573115161251607],
        [-156.58536585365854, 20.783227869678697],
        [-156.70056700567005, 20.86484189691896],
        [-156.71136711367114, 20.927354768847678],
        [-156.61416614166143, 21.012441733417333],
        [-156.25776257762578, 20.91693595685956],
        [-155.9949599495995, 20.764126714367137],
        [-156.07776077760778, 20.644310376503753],
      ],
    ],
    [
      [
        [-156.75816758167582, 21.177406256562563],
        [-156.79056790567904, 21.068008730687296],
        [-157.3269732697327, 21.097528697986974],
        [-157.25137251372513, 21.219081504515046],
        [-156.75816758167582, 21.177406256562563],
      ],
    ],
    [
      [
        [-157.65457654576545, 21.321533155731558],
        [-157.70857708577086, 21.264229689796892],
        [-157.7769777697777, 21.278121439114386],
        [-158.12618126181263, 21.312850812408115],
        [-158.2521825218252, 21.538591738817388],
        [-158.29178291782918, 21.57853051810517],
        [-158.02538025380255, 21.717448011280112],
        [-157.94257942579426, 21.653198670686706],
        [-157.65457654576545, 21.321533155731558],
      ],
    ],
    [
      [
        [-159.34659346593466, 21.981391248312477],
        [-159.46539465394653, 21.88241253442534],
        [-159.80019800198002, 22.064741744217443],
        [-159.74979749797498, 22.137673428134278],
        [-159.5949959499595, 22.236652142021413],
        [-159.36459364593645, 22.21407804938049],
        [-159.34659346593466, 21.981391248312477],
      ],
    ],
    [
      [
        [-153.0069300693007, 57.11536174091739],
        [-154.0041400414004, 56.73507510335102],
        [-154.51534515345153, 56.99207246572465],
        [-154.67014670146702, 57.46091900519005],
        [-153.7629376293763, 57.81689508145081],
        [-153.230132301323, 57.96970432394323],
        [-152.5641256412564, 57.901982046020464],
        [-152.14292142921428, 57.59115415504155],
        [-153.0069300693007, 57.11536174091739],
      ],
    ],
    [
      [
        [-165.57825578255782, 59.90933982239821],
        [-166.1938619386194, 59.7547941112411],
        [-166.8490684906849, 59.940596258362575],
        [-167.4538745387454, 60.21322183871837],
        [-166.46746467464675, 60.38339576785768],
        [-165.67545675456753, 60.29309939729396],
        [-165.57825578255782, 59.90933982239821],
      ],
    ],
    [
      [
        [-171.73071730717308, 63.781664944649435],
        [-171.1151111511115, 63.59238986019861],
        [-170.49230492304923, 63.69484151141512],
        [-169.68229682296823, 63.43089827438274],
        [-168.68868688686888, 63.29719018720186],
        [-168.77148771487714, 63.1877926613266],
        [-169.5310953109531, 62.97767995289951],
        [-170.29070290702907, 63.19473853598535],
        [-170.67230672306724, 63.375331277112764],
        [-171.55431554315544, 63.3180278111781],
        [-171.79191791917918, 63.40658771307713],
        [-171.73071730717308, 63.781664944649435],
      ],
    ],
    [
      [
        [167.84627846278465, -16.46576145651457],
        [167.5150751507515, -16.59773307503076],
        [167.18027180271804, -16.160142971529723],
        [167.21627216272162, -15.890990328503293],
        [167.84627846278465, -16.46576145651457],
      ],
    ],
    [
      [
        [167.10827108271081, -14.93419609426094],
        [167.27027270272703, -15.739917554675557],
        [167.00027000270006, -15.614891810818108],
        [166.79146791467917, -15.668722339423397],
        [166.65106651066515, -15.392623821738226],
        [166.62946629466296, -14.626841140611404],
        [167.10827108271081, -14.93419609426094],
      ],
    ],
  ],
};
