import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { preloadPreview } from '../state/actions';

class SeoHelmet extends Component {
  constructor(props) {
    super(props);
    if (this.props.isDynamicPreview && process.env.NODE_ENV === 'production' && typeof window !== 'undefined') {
      preloadPreview(this.props.img);
    }
  }

  render() {
    const {
      title,
      description,
      img,
      url,
    } = this.props;
    return (
      <Helmet
        title={title}
        meta={!description ? [] : [
          { name: 'description', content: description },
          { property: 'og:title', content: title },
          { property: 'og:description', content: description },
          { property: 'og:url', content: url },
          { property: 'og:image', content: img },
          { property: 'og:image:width', content: 1200 },
          { property: 'og:image:height', content: 628 },
          { name: 'twitter:image:alt', content: title },
          { name: 'twitter:card', content: 'summary_large_image' },
        ]}
        link={url ? [{ name: 'canonical', href: url }] : []}
        />
    );
  }
}

export default SeoHelmet;
