import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { FixedSizeList } from 'react-window';
import classNames from 'classnames';
import AutoSizer from 'react-virtualized-auto-sizer';
import { withRouter } from 'react-router';

import queryString from 'query-string';
import { immutableSort, getSortFn } from '../utils';
import * as Actions from '../state/actions';
import StickyTree from './StickyTree';

const sortTypeLabel = {
  sort: 'Sort by',
  order: 'Order',
  group: 'Group by',
};

const getSavedSort = (prefix) => {
  if (typeof localStorage === 'undefined') return {};
  const fullPrefix = prefix ? `${prefix}-` : '';
  return {
    sort: localStorage.getItem(`df-${fullPrefix}sort`),
    order: localStorage.getItem(`df-${fullPrefix}order`),
    group: localStorage.getItem(`df-${fullPrefix}group`),
  };
};

const getSortFnFromKey = (key, orderValue, sData) => {
  if (!orderValue || !sData) return null;
  const sortInfo = sData.sort.find((d) => d.key === key);
  if (!sortInfo) return null;
  if (sortInfo.type === 'str') return (orderValue === 'asc') ? 'compareStringAZ' : 'compareStringZA';
  if (sortInfo.type === 'float') return (orderValue === 'asc') ? 'compareFloatAsc' : 'compareFloatDesc';
  return null;
};

const rowRendererLoading = (value, valueIndex) => (
  <div
    key={`list-value-${valueIndex}`}
    className={`list-value-container${(valueIndex === 0) ? ' first' : ''}`}>
    <div className="list-value">
      <div className="list-value-grow">
        <div className="loading-line"></div>
        <div className="loading-line shorter"></div>
      </div>
      <div className="list-value-airlines">
        <div className="loading-line"></div>
      </div>
      <div className="list-value-caret">
      </div>
    </div>
  </div>
);

class List extends Component {
  constructor(props) {
    super(props);
    const savedSort = getSavedSort(props.prefix);
    const defaultSort = props.sortData ? (props.sortData.sort.find((d) => d.default) || {}) : {};
    const defaultGroupKey = props.sortData
      ? (props.sortData.group.find((d) => d.default) || {})
      : {};
    const sortKey = savedSort.sort || defaultSort.key;
    const sortOrder = savedSort.order || defaultSort.defaultOrder || 'asc';
    const groupKey = savedSort.group || defaultGroupKey.key;
    const { location, match } = this.props;

    let departure = null;
    let arrival = null;
    let airlineSlug = null;
    let airlineCode = null;
    if (match.params) {
      if (match.params.arrivalCode) {
        arrival = match.params.arrivalCode;
      }
      if (match.params.departureCode) {
        departure = match.params.departureCode;
      }
      if (match.params.airlineSlug) {
        airlineSlug = match.params.airlineSlug;
      }
    }

    if (airlineSlug) {
      airlineCode = this.props.airlineSlugs[airlineSlug];
    }

    let weekCode = 'default';
    if (location.search) {
      const qs = queryString.parse(location.search);
      weekCode = qs.weekCode || 'default';
    }

    let type = null;
    // airport
    if (location.pathname.match(/\/([a-z0-9-]{2,})-([A-Za-z]{3})$/g)) {
      type = 'airport';
    }
    // route
    if (location.pathname.match(/\/([a-z0-9-]{2,})-([A-Za-z]{3})\/([a-z0-9-]{2,})-([A-Za-z]{3})$/g)) {
      type = 'route';
    }
    // airline
    if (location.pathname.match(/\/([a-z0-9-]{2,})-([A-Za-z]{3})\/([a-z0-9-]{2,})$/g)) {
      type = 'airline';
    }
    if (['/airport-list', '/airline-list'].includes(location.pathname)) {
      type = null;
    }

    const link = `deep-${departure}-${arrival}-${airlineCode}-${weekCode}`;
    this.state = {
      filteredDataset: this.sortData(props.dataset || [], { sortKey, sortOrder }),
      filterValue: '',
      headerHeight: 0,
      windowWidth: 0,
      windowHeight: 0,
      isPanelScrollOpen: false,
      sortKey,
      sortOrder,
      groupKey,
      type,
      link,
      arrival,
    };
  }

  sortData(dataset, defaultSort) {
    const { sortKey, sortOrder } = this.state || defaultSort;
    if (!sortKey) return dataset;
    const sortFn = getSortFnFromKey(sortKey, sortOrder, this.props.sortData);
    if (sortKey) {
      return immutableSort(dataset, getSortFn(sortFn), sortKey);
    }
    return dataset;
  }

  filterData() {
    const { filterValue } = this.state;
    const {
      dataset,
      filterFunction,
      filterMarkers,
      syncVisualization,
    } = this.props;

    const filteredDataset = (filterValue === '')
      ? dataset
      : dataset.filter((d) => filterFunction(d, filterValue));

    this.setState({ filteredDataset: this.sortData(filteredDataset) });

    if (syncVisualization && this.lastFilterValue !== filterValue) {
      filterMarkers((filterValue === '') ? null : new Set(filteredDataset.map((d) => d.airportCode || d.arrivalCode || d.key)));
      this.lastFilterValue = filterValue;
    }
  }

  componentWillUnmount() {
    clearTimeout(this.filterTimeout);
    const { filterMarkers, syncVisualization } = this.props;
    if (syncVisualization) {
      filterMarkers(null);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.filterValue !== prevState.filterValue) {
      clearTimeout(this.filterTimeout);
      this.filterTimeout = setTimeout(() => {
        this.filterData();
      }, 200);
    }

    if (this.props.dataset !== prevProps.dataset) {
      this.setState({ filteredDataset: this.props.dataset });
      this.filterData();
    }
  }

  handleSortClick(action, value) {
    const fullPrefix = this.props.prefix ? `${this.props.prefix}-` : '';
    localStorage.setItem(`df-${fullPrefix}${action}`, value);
    const toUpdate = {};
    if (action === 'sort') {
      toUpdate.sortKey = value;
      toUpdate.groupKey = 'none';
      localStorage.setItem(`df-${fullPrefix}group`, 'none');
    }
    if (action === 'order') toUpdate.sortOrder = value;
    if (action === 'group') toUpdate.groupKey = value;
    this.setState(toUpdate, () => {
      const toUpdateAfter = { isPanelScrollOpen: false };
      if (action === 'sort' || action === 'order') {
        toUpdateAfter.filteredDataset = this.sortData(this.state.filteredDataset);
      }
      this.setState(toUpdateAfter);
    });
  }

  getPanelSortHeight() {
    const { sortData } = this.props;
    let height = 30;
    height += Object.keys(sortData).length * 29;
    height += Object.keys(sortData).reduce((acc, k) => acc + sortData[k].length, 0) * 35;
    return height;
  }

  render() {
    const {
      filterPlaceholder,
      rowRenderer,
      emptyLayout,
      headerLayout,
      tabLayout,
      noResultLayout,
      isLoading,
      sortData,
      history,
    } = this.props;

    const {
      filteredDataset,
      filterValue,
      isPanelScrollOpen,
      sortKey,
      sortOrder,
      groupKey,
      type,
      link,
      arrival,
    } = this.state;

    if (!filteredDataset) return null;

    const filteredDatasetByPivot = {};
    const filteredGroupList = {};
    let activeGroupList = {};
    let groupKeys = [];
    if (groupKey && sortData && sortData.group && groupKey !== 'none') {
      const groupInfo = sortData.group.find((d) => d.key === groupKey);
      if (groupInfo) {
        if (groupInfo.list) {
          Object.keys(groupInfo.list).forEach((p) => {
            filteredDatasetByPivot[p] = [];
          });
        }
        filteredDataset.forEach((d, i) => {
          const groupValue = d[groupKey] || 'N/A';
          if (!filteredDatasetByPivot[groupValue]) {
            filteredDatasetByPivot[groupValue] = [];
          }
          filteredDatasetByPivot[groupValue].push({ ...d, treeIndex: `child-${i}` });

          filteredGroupList[groupValue] = groupInfo.list
            ? groupInfo.list[groupValue]
            : groupValue;
        });
        activeGroupList = (groupInfo.list && filterValue === '') ? groupInfo.list : filteredGroupList;
        groupKeys = groupInfo.transform
          ? groupInfo.transform(Object.keys(activeGroupList))
          : Object.keys(activeGroupList);
      }
    }

    const rowHeight = 66;
    const pivotHeight = 40;
    const defaultWidth = 400;
    const defaultHeight = 500;
    let listContent = null;
    if (isLoading) {
      listContent = Array.from(Array(20).keys()).map(rowRendererLoading);
    } else if (!filteredDataset.length) {
      listContent = noResultLayout ? noResultLayout() : null;
    } else if (groupKeys.length) {
      const dataTree = {
        root: {
          pivotName: 'Earth',
          children: groupKeys.map((k, i) => `pivot-${i}`),
          depth: 0,
        },
      };
      groupKeys.forEach((k, pivotIndex) => {
        dataTree[`pivot-${pivotIndex}`] = {
          pivotName: activeGroupList[k],
          groupKey: k,
          pivotIndex,
          depth: 1,
          children: filteredDatasetByPivot[k].length
            ? filteredDatasetByPivot[k].map((d) => `child-${d.treeIndex}`)
            : [`empty-${pivotIndex}`],
        };
        filteredDatasetByPivot[k].forEach((d, rowIndex) => {
          dataTree[`child-${d.treeIndex}`] = {
            rowData: d,
            depth: 2,
            rowIndex,
          };
        });
        if (!filteredDatasetByPivot[k].length) {
          dataTree[`empty-${pivotIndex}`] = {
            depth: 2,
            isEmpty: true,
          };
        }
      });
      const getTreeChildren = (id) => {
        if (!dataTree[id].children) return null;
        return dataTree[id].children.map((k) => ({
          id: k,
          height: (dataTree[k].groupKey) ? pivotHeight : rowHeight,
          isSticky: !!(dataTree[k].groupKey),
        }));
      };
      if (typeof (window) === 'undefined') {
        listContent = dataTree.root.children.map((pivotId) => (
          <Fragment key={pivotId}>
            <div className={`list-header${(dataTree[pivotId].pivotIndex === 0) ? ' first' : ''}`}>
              {dataTree[pivotId].pivotName}
            </div>
            <Fragment>
              {dataTree[pivotId].children.map((childId) => (
                (dataTree[childId].isEmpty && emptyLayout)
                  ? emptyLayout({ key: childId })
                  : rowRenderer({
                    key: childId,
                    index: dataTree[childId].rowIndex,
                    value: dataTree[childId].rowData,
                    style: {},
                    pivotId,
                  })
              ))}
            </Fragment>
          </Fragment>
        ));
      } else {
        listContent = (
          <AutoSizer defaultWidth={defaultWidth} defaultHeight={defaultHeight}>
            {({ width, height }) => (
              <StickyTree
                ref={(list) => {
                  this.list = list;
                }}
                width={width}
                height={height}
                root={{ id: 'root', isSticky: true, height: 0 }}
                renderRoot={false}
                rowCount={filteredDataset.length}
                rowHeight={rowHeight}
                getChildren={getTreeChildren}
                overscanRowCount={20}
                rowRenderer={({ id, style }) => {
                  const node = dataTree[id];
                  if (!node) return null;
                  if (node.isEmpty && emptyLayout) {
                    return emptyLayout({ key: id });
                  }
                  if (node.pivotName) {
                    return (
                      <div className={`list-header${(node.pivotIndex === 0) ? ' first' : ''}`}>
                        {node.pivotName}
                      </div>
                    );
                  }
                  return rowRenderer({
                    key: id,
                    index: node.rowIndex,
                    value: node.rowData,
                    style,
                    groupKey,
                  });
                }} />
            )}
          </AutoSizer>
        );
      }
    } else {
      listContent = (
        <AutoSizer defaultWidth={defaultWidth} defaultHeight={defaultHeight}>
          {({ width, height }) => (
            <FixedSizeList
              itemCount={filteredDataset.length}
              itemSize={rowHeight}
              height={height}
              overscanCount={(typeof window === 'undefined') ? filteredDataset.length : 20}
              width={width}>
              {({ index, style }) => (
                rowRenderer({
                  index,
                  key: index,
                  value: filteredDataset[index],
                  style,
                  groupKey,
                })
              )}
            </FixedSizeList>
          )}
        </AutoSizer>
      );
    }

    const panelContentClassnames = classNames(
      'panel-content',
      'list',
      { 'is-loading': isLoading },
    );

    const sortClassnames = classNames(
      'btn-sort',
      { 'is-active': isPanelScrollOpen },
    );

    const sortIconClassnames = classNames(
      'icon',
      { 'icon-sort-asc': sortOrder === 'asc' },
      { 'icon-sort-desc': sortOrder === 'desc' },
    );

    const rightArrowIconClasses = classNames(
      'icon',
      'icon-light-arrow-right',
    );

    const getSelectedSort = (action) => {
      if (action === 'sort') return sortKey;
      if (action === 'order') return sortOrder;
      if (action === 'group') return groupKey;
      return null;
    };

    const sortInfo = sortData ? sortData.sort.find((d) => d.key === sortKey) : {};

    return (
      <Fragment>
        {headerLayout({})}
        <div className="panel-container">
          {tabLayout ? tabLayout() : null}
          <div className="panel-filter">
          {isLoading
            ? (
              <Fragment>
                <div className="loading-line panel-filter-loading"></div>
                <div className="loading-line panel-search-loading"></div>
              </Fragment>
            )
            : null
          }
            <input
              type="text"
              placeholder={isLoading ? '' : filterPlaceholder}
              value={filterValue}
              disabled={isLoading}
              onChange={(e) => this.setState({ filterValue: e.target.value })}
              />
            {isLoading ? null : <div className="search-icon"><div className="icon-search"></div></div>}
          </div>
          <div className={panelContentClassnames}>
            {listContent}
          </div>
          {(sortData && sortInfo) ? (
            <div className="panel-footer">
            {(isPanelScrollOpen) ? (
              <div className="panel-sort" style={{ height: this.getPanelSortHeight() }}>
                <ul>
                  {Object.keys(sortData).map((typeKey, typeIndex) => (
                    <Fragment key={typeIndex}>
                      <li className={typeIndex === 0 ? 'header is-first' : 'header'}>{sortTypeLabel[typeKey]}</li>
                      {sortData[typeKey].map((d, i) => (
                        <li
                          className={d.key === getSelectedSort(typeKey) ? 'item is-active' : 'item'}
                          key={i}
                          onClick={() => this.handleSortClick(typeKey, d.key)}>
                          {d.label}
                        </li>
                      ))}
                    </Fragment>
                  ))}
                </ul>
              </div>
            ) : null}
            <div className="top-line">
              <button
                className={sortClassnames}
                onClick={() => this.setState({ isPanelScrollOpen: !isPanelScrollOpen })}>
                <i className={sortIconClassnames}></i> Sort by <b>{sortInfo.label}</b>
              </button>
              {(isPanelScrollOpen) ? (
                <div
                  className="panel-sort-backdrop"
                  onClick={() => this.setState({ isPanelScrollOpen: !isPanelScrollOpen })}
                ></div>
              ) : null}
              {(type) ? (
                  <button onClick={() => { window.open(`/redirect/${link}-${type}-KY`); }} className="check-prices">Check prices <i className={rightArrowIconClasses}></i></button>
              ) : null}
            </div>
            {(type === 'route') ? (
                <div className="hotel-car-prices">
                  <button onClick={() => { window.open(`/redirect/hotels/${arrival}`); }} className="hotel-prices">HOTEL PRICES</button>
                  <button onClick={() => { window.open(`/redirect/cars/${arrival}`); }} className="car-prices">CAR-RENTAL PRICES</button>
                </div>
            ) : null}
          </div>
          ) : null}
        </div>
      </Fragment>
    );
  }
}

const ListComponent = withRouter(List);

const mapStateToProps = (state) => ({
  airlineSlugs: state.dataset.airlineSlugs,
});

const mapDispatchToProps = (dispatch) => ({
  filterMarkers: (s) => dispatch(Actions.filterMarkers(s)),
});

const statefulList = connect(mapStateToProps, mapDispatchToProps)(ListComponent);

export default statefulList;
