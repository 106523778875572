export default {
  router: {},
  /* */
  isLoading: false,
  isMapVisible: false,
  isModal: false,
  error: null,
  /* */
  rawDataset: {},
  dataset: {},
  airlineAirportsData: null,
  airlineRoutesData: null,
  airlineScheduleData: {},
  routesData: null,
  scheduleData: {},
  redirectData: {},
  nearbyAirportsData: null,
  /* */
  markers: {},
  loadedData: {},
  loadedRoutes: {},
  flightRoutes: {},
};
