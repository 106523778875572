import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { connect } from 'react-redux';

class Nav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      modalOpen: false,
    };
  }

  toogleMenu() {
    this.setState({ visible: !(this.state.visible) });
  }


  hideNav() {
    this.setState({ visible: false });
  }

  render() {
    const { visible, modalOpen } = this.state;
    const navClassnames = classNames('nav', { 'is-visible': visible });
    const navSelClassnames = classNames('nav-selector', { 'is-visible': visible });
    const iconClassnames = classNames('icon', { 'icon-menu': !visible, 'icon-close': visible });
    if (this.props.isPreview || this.props.isRedirect || this.props.isAlternatives) return null;
    return (
      <div>
        {(modalOpen) ? (
          <div className="external-modal">
            <div className="close" onClick={() => this.setState({ modalOpen: false })}>
              <i className="icon icon-close"></i>
            </div>
            <div className="backdrop"></div>
            <div className="content">
              <div className="modal-loading">Loading...</div>
              <iframe
                title="Contact form"
                width="100%"
                height="100%"
                frameBorder="0"
                src="https://tonoid.typeform.com/to/tmbI63"></iframe>
            </div>
          </div>
        ) : null}
        <div className={navSelClassnames} onClick={() => this.toogleMenu()}>
          <i className={iconClassnames}></i>
        </div>
        <div className={navClassnames}>
          <div className="nav-header">
          <div className="logo">
            <div className="img"></div>
            <div className="text">
              <div className="main">Direct<b>Flights</b></div>
            </div>
          </div>
          </div>
          <ul>
            <li><NavLink to="/airport-list" onClick={() => this.hideNav()}>Airports</NavLink></li>
            <li><NavLink to="/airline-list" onClick={() => this.hideNav()}>Airlines</NavLink></li>
            <li><div onClick={() => this.setState({ modalOpen: true })}>Contact</div></li>
            <li><NavLink to="/terms" onClick={() => this.hideNav()}>Terms</NavLink></li>
          </ul>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  isPreview: (state.router.location.search === '?preview=1'),
  isRedirect: (state.router.location.pathname.includes('/fr/')),
  isAlternatives: (state.router.location.pathname.includes('alternatives')),
});

export default connect(mapStateToProps)(Nav);
