import axios from 'axios';

import mapFields from '../utils/mapFields';
import { mapObject, immutableSort, compareFloatDesc } from '../utils';

export function getRequest(url) {
  return axios.get(url);
}

export const SET_LOADING = 'SET_LOADING';
export function setLoading(isLoading) {
  return {
    type: SET_LOADING,
    isLoading,
  };
}

export const SET_MODAL = 'SET_MODAL';
export function setModal(isModal) {
  return {
    type: SET_MODAL,
    isModal,
  };
}

export const SET_MAP_VISIBLE = 'SET_MAP_VISIBLE';
export function setMapVisible(isMapVisible) {
  return {
    type: SET_MAP_VISIBLE,
    isMapVisible,
  };
}

export const SET_DATASET = 'SET_DATASET';
export function setDataset(dataset) {
  return {
    type: SET_DATASET,
    dataset,
  };
}

export const SET_RAW_DATASET = 'SET_RAW_DATASET';
export function setRawDataset(rawDataset) {
  return {
    type: SET_RAW_DATASET,
    rawDataset,
  };
}

export const SET_MARKERS = 'SET_MARKERS';
export function setMarkers(markers) {
  return {
    type: SET_MARKERS,
    markers,
  };
}

export const SET_AIRLINE_AIRPORTS_DATA = 'SET_AIRLINE_AIRPORTS_DATA';
export function setAirlineAirportsData(airlineAirportsData) {
  return {
    type: SET_AIRLINE_AIRPORTS_DATA,
    airlineAirportsData,
  };
}

export const SET_AIRLINE_ROUTES_DATA = 'SET_AIRLINE_ROUTES_DATA';
export function setAirlineRoutesData(airlineRoutesData) {
  return {
    type: SET_AIRLINE_ROUTES_DATA,
    airlineRoutesData,
  };
}

export const SET_AIRLINE_SCHEDULE_DATA = 'SET_AIRLINE_SCHEDULE_DATA';
export function setAirlineScheduleData(airlineScheduleData) {
  return {
    type: SET_AIRLINE_SCHEDULE_DATA,
    airlineScheduleData,
  };
}

export const SET_NEARBY_AIRPORTS_DATA = 'SET_NEARBY_AIRPORTS_DATA';
export function setNearbyAirportsData(nearbyAirportsData) {
  return {
    type: SET_NEARBY_AIRPORTS_DATA,
    nearbyAirportsData,
  };
}

export const SET_ROUTES_DATA = 'SET_ROUTES_DATA';
export function setRoutesData(routesData) {
  return {
    type: SET_ROUTES_DATA,
    routesData,
  };
}

export const SET_FIND_ROUTES = 'SET_FIND_ROUTES';
export function setFindRoutes(data) {
  return {
    type: SET_FIND_ROUTES,
    loadedRoutes: data,
  };
}

export const SET_FLIGHT_ROUTES = 'SET_FLIGHT_ROUTES';
export function setFlightRoutes(data) {
  return {
    type: SET_FLIGHT_ROUTES,
    data,
  };
}

export const SET_SCHEDULE_DATA = 'SET_SCHEDULE_DATA';
export function setScheduleData(scheduleData) {
  return {
    type: SET_SCHEDULE_DATA,
    scheduleData,
  };
}

export const SET_REDIRECT_DATA = 'SET_REDIRECT_DATA';
export function setRedirectData(redirectData) {
  return {
    type: SET_REDIRECT_DATA,
    redirectData,
  };
}

export const SET_LOADED_DATA_FOR = 'SET_LOADED_DATA_FOR';
export function setLoadedDataFor(key, value) {
  return {
    type: SET_LOADED_DATA_FOR,
    key,
    value,
  };
}

export const SET_ERROR = 'SET_ERROR';
export function setError(err) {
  if (err) {
    console.error(`[Client Side Error] ${err.message} (${err.stack.replace(/\n/g, ', ')})`);
  }
  const error = (!err) ? null : [
    (err.response) ? err.response.status : 500,
    err.message,
  ];
  return {
    type: SET_ERROR,
    error,
  };
}

export const FILTER_MARKERS = 'FILTER_MARKERS';
export function filterMarkers(idList) {
  return {
    type: FILTER_MARKERS,
    idSet: idList ? new Set(idList) : null,
  };
}

export function preloadPreview(img) {
  const url = img.replace('.jpg', '.json');
  return getRequest(url).then(() => {
    // console.log('Preview preloaded');
  }).catch(() => {
    console.error('Cannot preload preview');
  });
}

export function getApiUrl(endpoint, params) {
  // if we are in server in development (dont care at the moment) we use https://direct-flights.com/static/mock/dataset.json
  // if we are in server in production we use https://api:5000/dataset.json
  // if we are in client in development we use /static/mock/dataset.json
  // if we are in client in production we use /api/dataset.json

  const isProd = (process.env.REACT_APP_ENV === 'production');
  const isServer = (typeof window === 'undefined');

  let url;
  if (isProd) {
    url = isServer ? process.env.API_PROXY : '/api';
  } else {
    url = isServer ? process.env.API_PROXY : '/static/mock';
  }

  // let laravelAPi = isProd ? 'https://wwww.flig.ht/api/v1/direct-flights' : 'http://flig.local/api/v1/direct-flights'

  if (endpoint === 'nearby') return `${url}/nearby.json`;
  if (endpoint === 'dataset') return `${url}/dataset.json`;
  if (endpoint === 'redirect') {
    // url = laravelAPi;
    // isProd = true;
    return `${url}/redirect/${isProd ? params.flightCode : 'CDG-BCN-VY-201907141850-KW'}.json`;
  }
  if (endpoint === 'redirectHotelCar') {
    const param = `${params.type}-${params.arrival}`;
    return `${url}/redirect/hc/${isProd ? param : 'hotels-RIX'}.json`;
  }
  if (endpoint === 'routes') {
    // url = laravelAPi;
    // isProd = true;
    // url = 'http://0.0.0.0:5001/api';
    // isProd = true;
    const randomCodeArray = ['CDG', 'SBA'];
    const randomCode = randomCodeArray[Math.floor(Math.random() * randomCodeArray.length)];
    const selectedCode = (randomCodeArray.indexOf(params.departureCode) !== -1)
      ? params.departureCode
      : randomCode;
    return `${url}/airports/${isProd ? params.departureCode : selectedCode}.json`;
  }
  if (endpoint === 'find-routes') {
    const randomCodeArray = ['CDG', 'SBA', 'RIX'];
    const randomCode = randomCodeArray[Math.floor(Math.random() * randomCodeArray.length)];
    const selectedCode = (randomCodeArray.indexOf(params.departureCode) !== -1)
      ? params.departureCode
      : randomCode;
    const search = isProd ? params.search : 'lax';
    if (params.search) {
      return `${url}/airports/find/${isProd ? params.departureCode : selectedCode}/${search}.json`;
    }
    return `${url}/airports/find/${isProd ? params.departureCode : selectedCode}.json`;
  }
  if (endpoint === 'schedule') {
    // url = laravelAPi;
    // isProd = true;
    // url = 'http://0.0.0.0:5001/api';
    // isProd = true;
    const { weekCode, departureCode, arrivalCode } = params;
    const randomRouteArray = ['CDG/BCN'];
    const requestedCode = `${departureCode}/${arrivalCode}`;
    const randomRoute = randomRouteArray[Math.floor(Math.random() * randomRouteArray.length)];
    const selectedRoute = (randomRouteArray.indexOf(requestedCode) !== -1)
      ? requestedCode
      : randomRoute;
    const randomWeekArray = ['201934', 'default'];
    const randomWeek = randomWeekArray[Math.floor(Math.random() * randomWeekArray.length)];
    const selectedWeek = (randomWeekArray.indexOf(weekCode) !== -1) ? weekCode : randomWeek;
    return `${url}/airports/${isProd ? `${requestedCode}/${weekCode}` : `${selectedRoute}/${selectedWeek}`}.json`;
  }
  if (endpoint === 'airline-airports') {
    const randomCodeArray = ['AF', 'F9'];
    const randomCode = randomCodeArray[Math.floor(Math.random() * randomCodeArray.length)];
    const selectedCode = (randomCodeArray.indexOf(params.airlineCode) !== -1)
      ? params.airlineCode
      : randomCode;
    return `${url}/airlines/${isProd ? `${params.airlineCode}` : selectedCode}.json`;
  }
  if (endpoint === 'airline-all') {
    return `${url}/airlines/all.json`;
  }
  if (endpoint === 'airline-routes') {
    const randomCodeArray = ['AF/CDG'];
    const requestedCode = `${params.airlineCode}/${params.departureCode}`;
    const randomCode = randomCodeArray[Math.floor(Math.random() * randomCodeArray.length)];
    const selectedCode = (randomCodeArray.indexOf(requestedCode) !== -1)
      ? requestedCode
      : randomCode;
    return `${url}/airlines/${isProd ? requestedCode : selectedCode}.json`;
  }
  if (endpoint === 'airline-schedule') {
    const {
      airlineCode,
      weekCode,
      departureCode,
      arrivalCode,
    } = params;
    const randomRouteArray = ['AF/CDG/BCN'];
    const requestedCode = `${airlineCode}/${departureCode}/${arrivalCode}`;
    const randomRoute = randomRouteArray[Math.floor(Math.random() * randomRouteArray.length)];
    const selectedRoute = (randomRouteArray.indexOf(requestedCode) !== -1)
      ? requestedCode
      : randomRoute;
    const randomWeekArray = ['201934', 'default'];
    const randomWeek = randomWeekArray[Math.floor(Math.random() * randomWeekArray.length)];
    const selectedWeek = (randomWeekArray.indexOf(weekCode) !== -1) ? weekCode : randomWeek;
    return `${url}/airlines/${isProd ? `${requestedCode}/${weekCode}` : `${selectedRoute}/${selectedWeek}`}.json`;
  }
  throw new Error('no valid endpoint');
}

export function setAirlineMarkers() {
  return (dispatch, getState) => {
    const { dataset } = getState();
    const markers = {};
    const airportAirlines = {};
    Object.keys(dataset.airlines).forEach((k) => {
      const d = dataset.airlines[k];
      if (!airportAirlines[d.airportCode]) airportAirlines[d.airportCode] = [];
      airportAirlines[d.airportCode].push(d);
    });

    Object.keys(airportAirlines).forEach((airportCode) => {
      const sortedAirlines = immutableSort(airportAirlines[airportCode], compareFloatDesc, 'countWeeklyFlights');
      markers[airportCode] = {
        context: 'airlines',
        weight: sortedAirlines[0].countWeeklyFlights,
        linkedTo: [],
        visible: true,
        airlines: sortedAirlines,
      };
    });
    dispatch(setMarkers(markers));
  };
}

export function loadAirlineAirportsData(airlineCode) {
  return (dispatch, getState) => {
    const { loadedData, airlineAirportsData } = getState();
    const loadedDataValue = airlineCode;
    let prm;
    if (loadedData.airlineAirports && loadedData.airlineAirports === airlineCode) {
      prm = Promise.resolve(airlineAirportsData);
    } else {
      dispatch(setLoading(true));
      dispatch(setAirlineAirportsData(null));
      const endpoint = getApiUrl('airline-airports', { airlineCode });
      prm = getRequest(endpoint)
        .then(({ data }) => (data.map(mapFields.airlineAirports)));
    }
    return prm
      .then((mappedData) => {
        const markers = {};
        mappedData.forEach((d) => {
          markers[d.airportCode] = {
            context: 'airlineAirports',
            weight: d.countRoutes,
            linkedTo: [],
            visible: true,
          };
        });
        dispatch(setMarkers(markers));
        dispatch(setAirlineAirportsData(mappedData));
        dispatch(setLoading(false));
        dispatch(setLoadedDataFor('airlineAirports', loadedDataValue));
      })
      .catch((e) => {
        dispatch(setLoading(false));
        dispatch(setError(e));
      });
  };
}

export function loadAirlineRoutesData(airlineCode, departureCode) {
  return (dispatch, getState) => {
    const { loadedData, airlineRoutesData } = getState();
    const loadedDataValue = `${airlineCode}-${departureCode}`;
    let prm;
    if (loadedData.airlineRoutes && loadedData.airlineRoutes === loadedDataValue) {
      prm = Promise.resolve(airlineRoutesData);
    } else {
      dispatch(setLoading(true));
      dispatch(setAirlineRoutesData(null));
      const endpoint = getApiUrl('airline-routes', { airlineCode, departureCode });
      prm = getRequest(endpoint).then(({ data }) => (data.map(mapFields.airlineRoutes)));
    }
    return prm
      .then((mappedData) => {
        const markers = {};
        markers[departureCode] = {
          context: 'airlineRoutes',
          weight: 1,
          linkedTo: mappedData.map((r) => r.arrivalCode).filter((v) => v !== departureCode),
          visible: true,
          alwaysVisible: true,
        };
        mappedData.forEach((d) => {
          if (d.arrivalCode === departureCode) return;
          markers[d.arrivalCode] = {
            context: 'airlineRoutes',
            weight: d.countWeeklyFlights,
            linkedTo: [],
            visible: true,
          };
        });
        dispatch(setMarkers(markers));
        dispatch(setAirlineRoutesData(mappedData));
        dispatch(setLoading(false));
        dispatch(setLoadedDataFor('airlineRoutes', loadedDataValue));
      })
      .catch((e) => {
        dispatch(setLoading(false));
        dispatch(setError(e));
      });
  };
}

export function loadAirlineScheduleData(airlineCode, departureCode, arrivalCode, weekCode) {
  return (dispatch, getState) => {
    const { loadedData, airlineScheduleData } = getState();
    let prm;
    const loadedDataValue = `${airlineCode}-${departureCode}-${arrivalCode}-${weekCode}`;
    if (loadedData.airlineSchedule && loadedData.airlineSchedule === loadedDataValue) {
      prm = Promise.resolve(airlineScheduleData);
    } else {
      dispatch(setLoading(true));
      dispatch(setAirlineScheduleData({}));
      const endpoint = getApiUrl('airline-schedule', {
        airlineCode,
        departureCode,
        arrivalCode,
        weekCode,
      });
      prm = getRequest(endpoint).then(({ data }) => ({
        route: mapFields.airlineSchedule.route(data.route),
        weeks: data.weeks.map(mapFields.airlineSchedule.weeks),
        selected: (process.env.REACT_APP_ENV !== 'production' && weekCode !== 'default')
          ? weekCode
          : data.selected,
        flights: data.flights.map(mapFields.airlineSchedule.flights),
      }));
    }
    return prm
      .then((mappedData) => {
        const markers = {};
        markers[departureCode] = {
          context: 'airlineSchedule',
          weight: 1,
          linkedTo: [arrivalCode],
          visible: true,
          alwaysVisible: true,
        };
        markers[arrivalCode] = {
          context: 'airlineSchedule',
          weight: 1,
          linkedTo: [],
          visible: true,
          alwaysVisible: true,
        };
        dispatch(setMarkers(markers));
        dispatch(setAirlineScheduleData(mappedData));
        dispatch(setLoading(false));
        dispatch(setLoadedDataFor('airlineSchedule', loadedDataValue));
      })
      .catch((e) => {
        dispatch(setLoading(false));
        dispatch(setError(e));
      });
  };
}

export function loadNearbyAirportsData() {
  return (dispatch, getState) => {
    const { nearbyAirportsData } = getState();
    if (nearbyAirportsData) return true;
    const endpoint = getApiUrl('nearby');
    return getRequest(endpoint)
      .then(({ data }) => {
        dispatch(setNearbyAirportsData(data));
      })
      .catch(() => {
        console.warn('Cannot load nearby airports');
      });
  };
}


export function mapDataset(rawDataset) {
  const mappedData = {
    airlines: mapObject(rawDataset.airlines, mapFields.dataset.airlines),
    countries: mapObject(rawDataset.countries, mapFields.dataset.countries),
    alliances: mapObject(rawDataset.alliances, mapFields.dataset.alliances),
    count: rawDataset.count,
    airports: mapObject(rawDataset.airports, mapFields.dataset.airports),
  };
  mappedData.airlineSlugs = {};
  Object.keys(mappedData.airlines).forEach((k) => {
    mappedData.airlineSlugs[mappedData.airlines[k].slug] = k;
  });
  return mappedData;
}

export function fetchRawDataset() {
  const endpoint = getApiUrl('dataset');
  return getRequest(endpoint).then(({ data }) => (data));
}

export function loadDataset() {
  return (dispatch, getState) => {
    const { dataset } = getState();
    if (dataset && Object.keys(dataset).length) return true;
    dispatch(setLoading(true));
    return fetchRawDataset()
      .then((rawDataset) => {
        dispatch(setRawDataset(rawDataset || {}));
        dispatch(setDataset(mapDataset(rawDataset || {})));
        dispatch(setLoading(false));
      })
      .catch((e) => {
        dispatch(setLoading(false));
        dispatch(setError(e));
      });
  };
}

export function setAirportMarkers() {
  return (dispatch, getState) => {
    const { dataset } = getState();
    const markers = {};
    Object.keys(dataset.airports).forEach((k) => {
      const d = dataset.airports[k];
      markers[k] = {
        context: 'airports',
        weight: d.countRoutes,
        linkedTo: [],
        visible: true,
      };
    });
    dispatch(setMarkers(markers));
  };
}

export function findRoutes(departureCode, search = null) {
  return (dispatch, getState) => {
    const { loadedRoutes } = getState();
    const loadedDataValue = departureCode;
    let prm;
    if (loadedRoutes[loadedDataValue]) {
      prm = Promise.resolve(loadedRoutes[loadedDataValue]);
    } else {
      dispatch(setFindRoutes({}));
      dispatch(setLoading(true));
      const endpoint = getApiUrl('find-routes', { departureCode, search });
      prm = getRequest(endpoint)
        .then(({ data }) => ({
          airports: data.airports.map(mapFields.findroutes.airports),
          flightRoutes: data.flightRoutes.map(mapFields.findroutes.flightRoutes),
        }));
    }
    return prm
      .then((mappedData) => {
        dispatch(setFindRoutes(mappedData.airports));
        dispatch(setFlightRoutes(mappedData.flightRoutes));
        dispatch(setLoading(false));
      })
      .catch((e) => {
        dispatch(setLoading(false));
        dispatch(setError(e));
      });
  };
}

export function loadRoutesData(departureCode) {
  return (dispatch, getState) => {
    const { loadedData, routesData } = getState();
    const loadedDataValue = departureCode;
    let prm;
    if (loadedData.routes && loadedData.routes === loadedDataValue) {
      prm = Promise.resolve(routesData);
    } else {
      dispatch(setRoutesData(null));
      dispatch(setLoading(true));
      const endpoint = getApiUrl('routes', { departureCode });
      prm = getRequest(endpoint)
        .then(({ data }) => (data.map(mapFields.routes)));
    }
    return prm
      .then((mappedData) => {
        const markers = {};
        markers[departureCode] = {
          context: 'routes',
          weight: 1,
          linkedTo: mappedData.map((r) => r.arrivalCode).filter((v) => v !== departureCode),
          visible: true,
          alwaysVisible: true,
        };
        mappedData.forEach((d) => {
          if (d.arrivalCode === departureCode) return;
          markers[d.arrivalCode] = {
            context: 'routes',
            weight: d.countWeeklyFlights,
            linkedTo: [],
            visible: true,
          };
        });
        dispatch(setMarkers(markers));
        dispatch(setRoutesData(mappedData));
        dispatch(setLoading(false));
        dispatch(setLoadedDataFor('routes', loadedDataValue));
      })
      .catch((e) => {
        dispatch(setLoading(false));
        dispatch(setError(e));
      });
  };
}

export function loadScheduleData(departureCode, arrivalCode, weekCode) {
  return (dispatch, getState) => {
    const { loadedData, scheduleData } = getState();
    const loadedDataValue = `${departureCode}-${arrivalCode}-${weekCode}`;
    let prm;
    if (loadedData.schedule && loadedData.schedule === loadedDataValue) {
      prm = Promise.resolve(scheduleData);
    } else {
      dispatch(setScheduleData({}));
      dispatch(setLoading(true));
      const endpoint = getApiUrl('schedule', { departureCode, arrivalCode, weekCode });
      prm = getRequest(endpoint)
        .then(({ data }) => ({
          route: mapFields.schedule.route(data.route),
          weeks: data.weeks.map(mapFields.schedule.weeks),
          selected: (process.env.REACT_APP_ENV !== 'production' && weekCode !== 'default')
            ? weekCode
            : data.selected,
          flights: data.flights.map(mapFields.schedule.flights),
        }));
    }
    return prm
      .then((mappedData) => {
        const markers = {};
        markers[departureCode] = {
          context: 'schedule',
          weight: 1,
          linkedTo: [arrivalCode],
          visible: true,
          alwaysVisible: true,
        };
        markers[arrivalCode] = {
          context: 'schedule',
          weight: 1,
          linkedTo: [],
          visible: true,
          alwaysVisible: true,
        };
        dispatch(setMarkers(markers));
        dispatch(setScheduleData(mappedData));
        dispatch(setLoading(false));
        dispatch(setLoadedDataFor('schedule', loadedDataValue));
      })
      .catch((e) => {
        dispatch(setLoading(false));
        dispatch(setError(e));
      });
  };
}

export function loadRedirectLink(flightCode) {
  return (dispatch, getState) => {
    const { loadedData } = getState();
    const loadedDataValue = flightCode;
    if (loadedData.redirect && loadedData.redirect === loadedDataValue) {
      return null;
    }
    dispatch(setLoading(true));
    dispatch(setRedirectData({}));
    const endpoint = getApiUrl('redirect', { flightCode });
    return getRequest(endpoint)
      .then(({ data }) => {
        const mappedData = mapFields.redirect(data);
        dispatch(setRedirectData(mappedData));
        dispatch(setLoading(false));
        dispatch(setLoadedDataFor('redirect', loadedDataValue));
      })
      .catch((e) => {
        dispatch(setLoading(false));
        dispatch(setError(e));
      });
  };
}

export function loadRedirectHotelCarLink(arrival, type) {
  return (dispatch, getState) => {
    const { loadedData } = getState();
    const loadedDataValue = arrival;
    if (loadedData.redirect && loadedData.redirect === loadedDataValue) {
      return null;
    }
    dispatch(setLoading(true));
    dispatch(setRedirectData({}));
    const endpoint = getApiUrl('redirectHotelCar', { arrival, type });
    return getRequest(endpoint)
      .then(({ data }) => {
        const mappedData = { deepLinkUrl: data[0] };
        dispatch(setRedirectData(mappedData));
        dispatch(setLoading(false));
        dispatch(setLoadedDataFor('redirect', loadedDataValue));
      })
      .catch((e) => {
        dispatch(setLoading(false));
        dispatch(setError(e));
      });
  };
}
