import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
  geoOrthographic,
  geoPath,
  geoInterpolate,
  geoDistance,
  geoCentroid,
  geoClipRectangle,
} from 'd3-geo';
import { line, curveCardinal } from 'd3-shape';
import { scaleLinear } from 'd3-scale';
import { extent } from 'd3-array';
import { geoVoronoi } from 'd3-geo-voronoi';
import {
  Tween,
  Easing,
  remove as removeTween,
  autoPlay as autoPlayTweens,
} from 'es6-tween';
import classNames from 'classnames';

import { toTopoCoordinates, toTopoLine, extractParams } from '../utils';
import geoZoom from '../utils/geoZoom';
import geoData from '../utils/geoData';
import worldData from '../utils/world';
import markersBehavior from '../utils/markersBehavior';
import { setMapVisible } from '../state/actions';
import {
  globeStokeColor,
  globeColor,
  waterColor,
} from '../utils/colors';

function pathArc(pts, ctx, projectionLands, projectionLines) {
  // Swoosh
  const swoosh = line().context(ctx).curve(curveCardinal.tension(-2));

  // Flying Arc
  const { source, target } = pts;

  const interp = geoInterpolate(source, target);

  const result = [
    projectionLands(source),
    projectionLines(interp(0.5)),
    projectionLands(target),
  ];
  return swoosh(result);
}

autoPlayTweens(true);

class Visualization extends Component {
  constructor(props) {
    super(props);

    this.geoData = geoData;

    this.worldData = worldData;

    this.scaleRatioLands = 0.94;
    this.scaleRatioLines = 1;
    this.strokeWidth = 1;

    this.state = {
      isNavigating: false,
      activeTooltip: null,
      isHover: false,
      width: null,
      height: null,
      currentRotation: null,
      currentScale: null,
      currentDeltaX: null,
    };
  }

  componentWillUnmount() {
    this.mounted = false;
    clearTimeout(this.resizeTimeout);
    clearTimeout(this.navigatingTimeout);
  }

  componentDidMount() {
    this.mounted = true;
    // Width / Height
    window.addEventListener('resize', () => {
      clearTimeout(this.resizeTimeout);
      this.resizeTimeout = window.setTimeout(() => {
        this.setState({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }, 200);
    });

    // Prepare Data
    this.prepareData();

    // Set dimensions
    const width = window.innerWidth;
    const height = window.innerHeight;

    this.setState({
      width,
      height,
      currentRotation: this.getRelevantRotation(),
      currentScale: this.getRelevantScale({ width, height }),
      currentDeltaX: this.getRelevantDeltaX({ width, height }),
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.mounted) return;

    const { departureCode, arrivalCode } = this.props;
    const prevDepartureCode = prevProps.departureCode;
    const prevArrivalCode = prevProps.arrivalCode;

    // hndling rotation/scale change
    if (
      prevState.currentRotation !== this.state.currentRotation
      || prevState.currentScale !== this.state.currentScale
      || prevState.currentDeltaX !== this.state.currentDeltaX
    ) {
      this.drawMap();
    }

    // Handling data change
    if (
      prevProps.dataset !== this.props.dataset
      || prevProps.markers !== this.props.markers
      || prevDepartureCode !== departureCode
      || prevArrivalCode !== arrivalCode
    ) {
      this.prepareData();
      const newRotation = this.getRelevantRotation();
      const newScale = this.getRelevantScale();

      const rotationDiff = this.state.currentRotation[0] - newRotation[0];
      if (Math.abs(rotationDiff) > 180) newRotation[0] += 360 * (rotationDiff > 0 ? 1 : -1);

      const toUpdate = {
        currentRotation: newRotation,
        currentScale: newScale,
      };
      if (this.props.isCentered) {
        toUpdate.currentDeltaX = this.getRelevantDeltaX();
      }

      const self = this;
      if (this.tweenPositionEarth && this.tweenPositionEarth.isPlaying()) {
        removeTween(this.tweenPositionEarth);
      }
      this.tweenPositionEarth = this.tweenState(toUpdate, () => {
        if (self.zoom) {
          self.zoom.syncProjection(() => self.getProjection(true));
        }
      });

      this.setState({
        activeTooltip: null,
      });
    }

    // Change to Modal
    if (!prevProps.isModal && this.props.isModal) {
      this.setState({ currentScale: this.getRelevantScale() });
    }

    // Back from Modal
    if (prevProps.isModal && !this.props.isModal) {
      removeTween(this.tweenSendGalaxy);
    }

    // Change width and height
    if (
      prevState.width && prevState.height
      && (prevState.width !== this.state.width || prevState.height !== this.state.height)
    ) {
      this.setState({
        currentRotation: this.getRelevantRotation(),
        currentScale: this.getRelevantScale(),
        currentDeltaX: this.getRelevantDeltaX(),
      });
    }

    // Change Panel Home - Other Panel
    if (prevProps.isCentered !== this.props.isCentered) {
      if (this.props.isCentered && !this.props.isMapVisible) {
        this.props.setMapVisible(true);
      } else if (!this.props.isCentered && this.props.isMapVisible) {
        this.props.setMapVisible(false);
      }
      if (this.tweenPositionEarth && this.tweenPositionEarth.isPlaying()) {
        removeTween(this.tweenPositionEarth);
      }
      if (this.tweenEarthMove && this.tweenEarthMove.isPlaying()) {
        removeTween(this.tweenEarthMove);
      }
      this.tweenEarthMove = this.tweenState({ currentDeltaX: this.getRelevantDeltaX() });
    }

    // First time we set the state for width and height
    if (
      !prevState.width && !prevState.currentRotation
      && this.state.width && this.state.currentRotation
    ) {
      // Geo Zoom
      this.zoom = geoZoom()
        .projection(() => this.getProjection(true))
        .scaleExtent([1, 4])
        .northUp(true)
        .onStart(() => {
          this.lastActiveTooltip = this.state.activeTooltip;
          this.setState({ isNavigating: true, activeTooltip: null });
        })
        .onEnd(() => {
          this.setState({ isNavigating: false, activeTooltip: this.state.activeTooltip });
        })
        .onMove(({ scale, rotation }) => {
          const ajustedScale = scale / this.scaleRatioLands;
          this.setState({ currentRotation: rotation, currentScale: ajustedScale });
        })(this.node);
    }
  }

  sendToGalaxy() {
    if (!this.props.isModal) return;
    removeTween(this.tweenSendGalaxy);
    this.tweenSendGalaxy = this.tweenState({ currentScale: 1 }, () => {}, 30000);
  }

  tweenState(toState, onComplete, duration = 1000) {
    if (this.props.isPreview) duration = 0;
    const fromState = {};
    Object.keys(toState).forEach((k) => {
      fromState[k] = this.state[k];
    });

    return new Tween(fromState)
      .to(toState, duration)
      .easing(Easing.Quadratic.InOut)
      .on('update', (intermediateState) => {
        this.setState(intermediateState);
      })
      .start()
      .on('complete', () => {
        if (onComplete) onComplete();
      });
  }

  prepareData() {
    const {
      dataset,
      markers,
    } = this.props;


    // Compute areaData and linksData
    this.linksData = [];
    this.areaData = [];
    this.voronoiPoints = {
      type: 'FeatureCollection',
      features: [],
    };

    // Require dataset to be defined
    if (!Object.keys(dataset).length) return;

    const filteredIds = new Set(
      Object.keys(markers).filter((k) => markers[k].visible),
    );
    Object.keys(markers).filter((k) => filteredIds.has(k)).forEach((id) => {
      if (!dataset.airports[id]) return;
      this.areaData.push({
        id,
        ...dataset.airports[id],
        ...markers[id],
      });
      if (Array.isArray(markers[id].linkedTo)) {
        markers[id].linkedTo
          .filter((k) => filteredIds.has(k))
          .forEach((toId) => {
            if (
              !dataset.airports[id]
              || !dataset.airports[toId]
              || !dataset.airports[id].longitude
              || !dataset.airports[id].latitude
              || !dataset.airports[toId].longitude
              || !dataset.airports[toId].latitude
            ) return;
            this.linksData.push({
              key: toId,
              source: [dataset.airports[id].longitude, dataset.airports[id].latitude],
              target: [dataset.airports[toId].longitude, dataset.airports[toId].latitude],
              weight: markers[toId].weight,
            });
          });
      }
    });

    if (!this.areaData.length) return;

    // Compute Voronoi
    this.voronoiPoints.features = this.areaData.map((d) => ({
      type: 'Point',
      coordinates: [d.longitude, d.latitude],
      properties: d,
    }));

    this.voronoi = this.voronoiPoints.features.length
      ? geoVoronoi()(this.voronoiPoints)
      : null;

    // If there's a to
    this.minRotationY = -30;
  }

  getProjection(isLand) {
    const { width, height } = this.state;
    const scaleRatioValue = isLand ? this.scaleRatioLands : this.scaleRatioLines;
    return geoOrthographic()
      .rotate(this.state.currentRotation)
      .scale(this.state.currentScale * scaleRatioValue)
      .translate(this.getEarthCenter())
      .clipAngle(90)
      .postclip(geoClipRectangle(0, 0, width, height));
  }

  getRelevantRotation() {
    const {
      departureCode,
      airlineSlug,
      dataset,
    } = this.props;

    // Initial rotation
    let relevantRotation = [10, -10];

    const departureData = (departureCode && dataset.airports)
      ? dataset.airports[departureCode]
      : null;

    let minRotation = -30;
    if (departureData) {
      const ratioZoom = this.getRatioZoom();
      if (ratioZoom > 2) minRotation = -40;
      if (ratioZoom > 3) minRotation = -50;
      if (this.linksData.length === 1 && ratioZoom === 1) minRotation = -70;
      const sourceCentroid = this.linksData.length === 1
        ? geoCentroid(toTopoLine(this.linksData[0]))
        : geoCentroid(toTopoCoordinates(departureData));
      relevantRotation = [-sourceCentroid[0], -sourceCentroid[1]];
    } else if (airlineSlug && !this.linksData.length) {
      const mainPointWeight = Math.max(...this.areaData.map((d) => d.weight));
      const mainPoint = this.areaData.find((d) => d.weight === mainPointWeight);
      if (mainPoint) {
        relevantRotation = [-mainPoint.longitude, -mainPoint.latitude];
      }
    }
    if (relevantRotation[1] > -1 * minRotation) relevantRotation[1] = -1 * minRotation;
    if (relevantRotation[1] < minRotation) relevantRotation[1] = minRotation;
    return relevantRotation;
  }

  getRatioZoom() {
    let mult = 1;
    if (this.linksData.length) {
      const distances = this.linksData.map((d) => (geoDistance(d.source, d.target)));
      const maxDistance = Math.max(...distances);
      const ratioZoom = ((Math.PI / 2) / maxDistance) * 0.75;
      if (ratioZoom > 1) mult = ratioZoom;
      if (ratioZoom > 4) mult = 4;
    }

    if (this.props.airlineSlug && !this.linksData.length) {
      const mainPointWeight = Math.max(...this.areaData.map((d) => d.weight));
      const mainPoint = this.areaData.find((d) => d.weight === mainPointWeight);
      const distances = this.areaData
        .filter((d) => d.id !== mainPoint.id)
        .map((d) => (geoDistance(
          [mainPoint.longitude, mainPoint.latitude],
          [d.longitude, d.latitude],
        )));
      const maxDistance = Math.max(...distances);
      const ratioZoom = ((Math.PI / 2) / maxDistance) * 0.75;
      if (ratioZoom > 1) mult = ratioZoom;
      if (ratioZoom > 4) mult = 4;
    }
    return mult;
  }

  getRelevantScale(windowSize) {
    const { width, height } = windowSize || this.state;
    const scaleSide = width > height ? height : width;
    const multiplier = (width < 500 && !this.props.isModal) ? 1.5 : 1;
    const ratioZoom = windowSize || this.props.isModal ? 1 : this.getRatioZoom();
    return ((scaleSide / 2) - 1) * multiplier * ratioZoom;
  }


  getRelevantDeltaX(windowSize) {
    const { width, height } = windowSize || this.state;
    const earthSize = width > height ? height : width;
    const movePosition = (width - earthSize) / 2;
    const dX = movePosition > 400 ? 400 : movePosition;
    return this.props.isCentered ? 0 : dX;
  }

  getEarthCenter() {
    const { width, height, currentDeltaX } = this.state;
    return [(width / 2) + currentDeltaX, height / 2];
  }

  outOfViewport(d, projection) {
    const { width, height } = this.state;
    if (d.target) {
      const targetPos = projection(d.target);
      if (
        targetPos[0] < 0
        || targetPos[1] < 0
        || targetPos[0] > width
        || targetPos[1] > height
      ) return true;
    }

    if (d.source) {
      const sourcePos = projection(d.source);
      if (
        sourcePos[0] < 0
        || sourcePos[1] < 0
        || sourcePos[0] > width
        || sourcePos[1] > height
      ) return true;
    }

    if (d.longitude && d.latitude) {
      const pointPos = projection([d.longitude, d.latitude]);
      if (
        pointPos[0] < 0
        || pointPos[1] < 0
        || pointPos[0] > width
        || pointPos[1] > height
      ) return true;
    }
    return false;
  }

  fadeAtEdge(d, projection) {
    if (this.outOfViewport(d, projection)) return 0.1;
    const earthCenter = this.getEarthCenter();
    const centerPos = projection.invert(earthCenter);
    const start = d.source ? d.source : d.geometry.coordinates[0];
    const end = d.target ? d.target : d.geometry.coordinates[1];

    const startDist = 1.57 - geoDistance(start, centerPos);
    const endDist = 1.57 - geoDistance(end, centerPos);

    const fade = scaleLinear().domain([-0.1, 0]).range([0, 0.3]);
    const dist = startDist < endDist ? startDist : endDist;
    const opacity = fade(dist);
    if (opacity > 1) return 1;
    if (opacity < 0.1) return 0.1;
    return opacity;
  }

  handleAreaMove(e, projection) {
    if (this.state.isNavigating || this.props.isLoading) return;
    const mCoords = [e.clientX, e.clientY];
    const mapCoord = projection.invert(mCoords);
    if (!this.voronoi) return;
    const markerIndex = this.voronoi.find(mapCoord[0], mapCoord[1]);
    if (
      !this.voronoiPoints.features.length
      || markerIndex === null
      || !this.voronoiPoints.features[markerIndex]
    ) return;
    this.selectedMarker = this.voronoiPoints.features[markerIndex].properties;
    const tCoords = projection([this.selectedMarker.longitude, this.selectedMarker.latitude]);

    // tooltip height (hacky)
    let tooltipHeight = 53;
    if (this.tooltipNode) {
      const tooltipRect = this.tooltipNode.getBoundingClientRect();
      tooltipHeight = tooltipRect.height + 7;
    }

    const tCoordsFull = [tCoords[0], tCoords[1], mCoords[1] < tCoords[1] ? 7 : -1 * tooltipHeight];
    const toUpdate = {};
    if (
      !this.state.activeTooltipPos
      || this.state.activeTooltipPos[0] !== tCoordsFull[0]
      || this.state.activeTooltipPos[1] !== tCoordsFull[1]
      || this.state.activeTooltipPos[2] !== tCoordsFull[2]
    ) {
      toUpdate.activeTooltipPos = tCoordsFull;
    }
    if (
      !this.state.activeTooltip
      || !this.selectedMarker.activeTooltip
      || this.state.activeTooltip.key !== this.selectedMarker.key
    ) {
      toUpdate.activeTooltip = this.selectedMarker;
    }
    if (Object.keys(toUpdate).length) {
      this.setState(toUpdate);
    }

    clearTimeout(this.navigatingTimeout);
    if (this.state.isNavigating === null) {
      this.setState({ isNavigating: false });
    }
    this.navigatingTimeout = setTimeout(() => {
      this.setState({ isNavigating: null });
    }, 400);
  }

  handleAreaClick() {
    const { setRoute, params, markers } = this.props;
    if (!this.selectedMarker || !Object.keys(markers).length) return null;

    const firstMarker = markers[Object.keys(markers)[0]];
    if (!firstMarker) return null;
    const currentContext = firstMarker.context;
    if (!currentContext || !markersBehavior[currentContext]) return null;

    const { selectedMarker } = this;
    return markersBehavior[currentContext].onClick({ selectedMarker, params, setRoute });
  }

  getTooltipContent(activeTooltip) {
    const { markers } = this.props;

    const firstMarker = markers[Object.keys(markers)[0]];
    if (!firstMarker) return null;
    const currentContext = firstMarker.context;
    if (!currentContext || !markersBehavior[currentContext]) return null;

    const refData = (node) => {
      this.tooltipNode = node;
    };
    return markersBehavior[currentContext].tooltipContent(activeTooltip, refData);
  }

  drawMap() {
    if (!this.canvas) return;

    const ctx = this.canvas.getContext('2d');
    const projection = this.getProjection(true);
    const projectionLines = this.getProjection(false);
    const path = geoPath(projection, ctx);
    const earthCenter = this.getEarthCenter();

    const {
      width,
      height,
      departureCode,
      arrivalCode,
      activeTooltip,
    } = this.state;
    const { isPreview } = this.props;

    const markerMultiplier = isPreview ? 2 : 1;
    const flyerMultiplier = isPreview ? 2 : 1;

    // Ratio
    // assume the device pixel ratio is 1 if the browser doesn't specify it
    const devicePixelRatio = window.devicePixelRatio || 1;

    // determine the 'backing store ratio' of the canvas context
    const backingStoreRatio = (
      ctx.webkitBackingStorePixelRatio
      || ctx.mozBackingStorePixelRatio
      || ctx.msBackingStorePixelRatio
      || ctx.oBackingStorePixelRatio
      || ctx.backingStorePixelRatio
      || 1
    );

    // determine the actual ratio we want to draw at
    const ratio = devicePixelRatio / backingStoreRatio;

    // Retina
    this.canvas.width = width * ratio;
    this.canvas.height = height * ratio;
    this.canvas.style.width = `${width}px`;
    this.canvas.style.height = `${height}px`;
    ctx.scale(ratio, ratio);

    // Clear
    ctx.clearRect(0, 0, width, height);

    // Context
    ctx.lineJoin = 'round';
    ctx.lineCap = 'round';

    // Draw globe
    ctx.beginPath();
    ctx.arc(earthCenter[0], earthCenter[1], projection.scale(), 0, 2 * Math.PI, false);
    ctx.fillStyle = waterColor;
    ctx.fill();
    ctx.lineWidth = 1;
    ctx.strokeStyle = globeColor;
    ctx.stroke();

    // Lands
    ctx.beginPath();
    path(this.geoData);
    ctx.fillStyle = globeColor;
    ctx.fill();
    ctx.lineWidth = 1;
    ctx.strokeStyle = globeStokeColor;
    ctx.stroke();

    // Borders
    ctx.beginPath();
    path(this.worldData);
    ctx.lineWidth = 1;
    ctx.strokeStyle = '#595959';
    ctx.stroke();

    // Markers
    const outOfViz = (d) => {
      const distance = geoDistance(
        [d.longitude, d.latitude],
        projection.invert(earthCenter),
      );
      return distance > Math.PI / 2;
    };

    const visibleMarkers = this.areaData
      .filter((d) => (
        d.longitude
        && d.latitude
        && !outOfViz(d)
        && !this.outOfViewport(d, projection)
      ));

    const weightExtent = extent(this.areaData, (d) => d.weight);
    const visibleWeightExtent = extent(visibleMarkers, (d) => d.weight);
    const markersScale = scaleLinear()
      .domain(weightExtent)
      .range([1.5, 4]);

    const markersOpacity = scaleLinear()
      .domain(visibleWeightExtent)
      .range([0.5, 1]);

    const flyerOpacityScale = scaleLinear()
      .domain(weightExtent)
      .range([0.6, 1.0]);

    const flyerStrokeScale = scaleLinear()
      .domain(weightExtent)
      .range([0.8, 3]);

    visibleMarkers.forEach((d) => {
      const radius = (visibleMarkers.length < 3 ? 4 : markersScale(d.weight)) * markerMultiplier;
      const coords = projection([d.longitude, d.latitude]);

      const isHighlighted = activeTooltip && d.key === activeTooltip.key;

      ctx.beginPath();
      ctx.arc(coords[0], coords[1], radius, 0, 2 * Math.PI, false);
      const colorMarker = isHighlighted ? '248, 3, 99' : '253, 100, 160';
      ctx.fillStyle = `rgba(${colorMarker}, ${markersOpacity(d.weight)})`;
      ctx.fill();
    });

    // Arc shadow
    this.linksData.forEach((d) => {
      ctx.beginPath();
      path(toTopoCoordinates(d));
      ctx.lineWidth = 1 * flyerMultiplier;
      ctx.strokeStyle = `rgba(119, 119, 119, ${this.fadeAtEdge(d, projection) * 0.04})`;
      ctx.stroke();
    });

    // Arc
    this.linksData.forEach((d) => {
      const opacityEdge = this.fadeAtEdge(d, projection);
      let opacity = d.weight !== undefined
        ? opacityEdge * flyerOpacityScale(d.weight)
        : opacityEdge;

      if (opacity > 1) opacity = 1;

      const strokeWidth = d.weight !== undefined
        ? this.strokeWidth * flyerStrokeScale(d.weight)
        : this.strokeWidth;

      if (departureCode && !arrivalCode && activeTooltip) {
        if (d.key === activeTooltip.key) {
          opacity *= 3;
        }
        if (d.key !== activeTooltip.key) {
          opacity *= 0.2;
        }
      }

      opacity += 0.5;

      if (opacity > 1) opacity = 1;

      ctx.beginPath();
      pathArc(d, ctx, projection, projectionLines);
      ctx.lineWidth = strokeWidth * flyerMultiplier;
      ctx.strokeStyle = `rgba(43, 166, 200, ${opacity})`;
      ctx.stroke();
    });

    // Voronoi
    // if (this.voronoi) {
    //   this.voronoi.polygons().features.forEach((d) => {
    //     ctx.beginPath();
    //     path(d);
    //     ctx.lineWidth = 1;
    //     ctx.strokeStyle = 'red';
    //     ctx.stroke();
    //   });
    // }

    // Globe shadow
    const grd = ctx.createRadialGradient(
      -(projection.scale() / 2) + earthCenter[0],
      -(projection.scale() / 2) + earthCenter[1],
      0,
      earthCenter[0],
      earthCenter[1],
      projection.scale() * 1.1,
    );
    grd.addColorStop(0, 'rgba(255, 255, 255, 0.3)');
    grd.addColorStop(0.2, 'rgba(233, 253, 255, 0.3)');
    grd.addColorStop(0.8, 'rgba(233, 253, 255, 0.05)');
    grd.addColorStop(1, 'rgba(0, 47, 81, 0.3)');

    ctx.beginPath();
    ctx.arc(earthCenter[0], earthCenter[1], projection.scale(), 0, 2 * Math.PI, false);
    ctx.fillStyle = grd;
    ctx.fill();
  }

  render() {
    const {
      width,
      height,
      isNavigating,
      activeTooltip,
      activeTooltipPos,
    } = this.state;

    const {
      isCentered,
      isMapVisible,
      params,
    } = this.props;

    const vizToggleIconClassnames = classNames('icon', { 'icon-list': isMapVisible }, { 'icon-map': !isMapVisible });
    const vizToggleClassnames = classNames('visualization-toggle', { 'is-visible': !isCentered });
    const vizToggle = (
      <div
          className={vizToggleClassnames}
          onClick={() => this.props.setMapVisible(!isMapVisible)}
        >
          <i className={vizToggleIconClassnames}></i>
        </div>
    );
    if (!width || !height) return null;

    const earthCenter = this.getEarthCenter();
    const projection = this.getProjection(true);
    let svgContent = null;

    const svgClassNames = classNames(
      'map-svg',
      { isCentered: this.props.isCentered },
      { mousedown: isNavigating },
      { clickable: isNavigating === null },
      { mouseup: !isNavigating },
    );

    svgContent = (
      <svg
        width={width}
        height={height}
        className={svgClassNames}
      >
        <circle
          cx={earthCenter[0]}
          cy={earthCenter[1]}
          r={projection.scale()}
          fill="transparent"
          onMouseMove={(e) => this.handleAreaMove(e, projection)}
          onClick={() => this.handleAreaClick()}
          onMouseEnter={() => this.setState({ activeTooltip: this.lastActiveTooltip })}
          onMouseLeave={() => {
            this.lastActiveTooltip = this.state.activeTooltip;
            this.setState({ activeTooltip: null });
          }}
          ref={(node) => {
            this.node = node;
          }}
          />
      </svg>
    );

    let tooltipContent = null;
    if (activeTooltip && !isNavigating) {
      const tooltipLeft = `${activeTooltipPos[0] - 90}px`;
      const tooltipTop = `${activeTooltipPos[1] + activeTooltipPos[2]}px`;

      const tooltipClassNames = classNames(
        'map-tooltip',
        { bottom: activeTooltipPos[2] > 0 },
        { top: activeTooltipPos[2] <= 0 },
      );
      const tooltipStyles = { top: tooltipTop, left: tooltipLeft };
      tooltipContent = this.getTooltipContent({
        activeTooltip,
        params,
        tooltipClassNames,
        tooltipStyles,
      });
    }

    const vizClassnames = classNames(
      'visualization',
      { 'is-hidden': !isMapVisible && !isCentered },
      { 'is-centered': isCentered },
    );

    return (
      <Fragment>
        {vizToggle}
        <div className={vizClassnames}>
          <canvas
            ref={(node) => {
              this.canvas = node;
            }}
            className="map-canvas"
          />
          {tooltipContent}
          {svgContent}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const params = extractParams(state.router.location);
  return {
    dataset: state.dataset,
    markers: state.markers,
    isModal: state.isModal,
    isLoading: state.isLoading,
    isMapVisible: state.isMapVisible,
    isCentered: (
      state.isModal
      || params.flightCode
      || ['/', '/terms', '/privacy'].indexOf(state.router.location.pathname) !== -1
    ),
    params,
    departureCode: params.departureCode,
    airlineSlug: params.airlineSlug,
    arrivalCode: params.arrivalCode,
    isPreview: (state.router.location.search === '?preview=1'),
  };
};

const mapDispatchToProps = (dispatch) => ({
  setRoute: (r) => dispatch(push(r)),
  setMapVisible: (r) => dispatch(setMapVisible(r)),
});

const statefulVisualization = connect(mapStateToProps, mapDispatchToProps);

export default statefulVisualization(Visualization);
