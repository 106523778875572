import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import * as Actions from './actions';
import initial from './initial';

function isLoading(state = initial.isLoading, action) {
  switch (action.type) {
    case Actions.SET_LOADING:
      return action.isLoading;
    default:
      return state;
  }
}

function isModal(state = initial.isModal, action) {
  switch (action.type) {
    case Actions.SET_MODAL:
      return action.isModal;
    default:
      return state;
  }
}

function isMapVisible(state = initial.isMapVisible, action) {
  switch (action.type) {
    case Actions.SET_MAP_VISIBLE:
      return action.isMapVisible;
    default:
      return state;
  }
}

function error(state = initial.error, action) {
  switch (action.type) {
    case Actions.SET_ERROR:
      return action.error;
    default:
      return state;
  }
}

function airlineAirportsData(state = initial.airlineAirportsData, action) {
  switch (action.type) {
    case Actions.SET_AIRLINE_AIRPORTS_DATA:
      return action.airlineAirportsData;
    default:
      return state;
  }
}

function airlineRoutesData(state = initial.airlineRoutesData, action) {
  switch (action.type) {
    case Actions.SET_AIRLINE_ROUTES_DATA:
      return action.airlineRoutesData;
    default:
      return state;
  }
}

function airlineScheduleData(state = initial.airlineScheduleData, action) {
  switch (action.type) {
    case Actions.SET_AIRLINE_SCHEDULE_DATA:
      return action.airlineScheduleData;
    default:
      return state;
  }
}

function nearbyAirportsData(state = initial.nearbyAirportsData, action) {
  switch (action.type) {
    case Actions.SET_NEARBY_AIRPORTS_DATA:
      return action.nearbyAirportsData;
    default:
      return state;
  }
}

function dataset(state = initial.dataset, action) {
  switch (action.type) {
    case Actions.SET_DATASET:
      return action.dataset;
    default:
      return state;
  }
}

function rawDataset(state = initial.rawDataset, action) {
  switch (action.type) {
    case Actions.SET_RAW_DATASET:
      return action.rawDataset;
    default:
      return state;
  }
}

function markers(state = initial.markers, action) {
  switch (action.type) {
    case Actions.SET_MARKERS:
      return action.markers;
    case Actions.FILTER_MARKERS: {
      const newState = { ...state };
      Object.keys(newState).forEach((id) => {
        newState[id].visible = (
          action.idSet === null
          || newState[id].alwaysVisible
          || (action.idSet && action.idSet.has(id))
        );
      });
      return newState;
    }
    default:
      return state;
  }
}

function routesData(state = initial.routesData, action) {
  switch (action.type) {
    case Actions.SET_ROUTES_DATA:
      return action.routesData;
    default:
      return state;
  }
}

function loadedData(state = initial.loadedData, action) {
  switch (action.type) {
    case Actions.SET_LOADED_DATA_FOR: {
      const newState = { ...state };
      newState[action.key] = action.value;
      return newState;
    }
    default:
      return state;
  }
}

function loadedRoutes(state = initial.loadedRoutes, action) {
  switch (action.type) {
    case Actions.SET_FIND_ROUTES:
      return action.loadedRoutes;
    default:
      return state;
  }
}

function flightRoutes(state = initial.flightRoutes, action) {
  switch (action.type) {
    case Actions.SET_FLIGHT_ROUTES:
      return action.data;
    default:
      return state;
  }
}
function scheduleData(state = initial.scheduleData, action) {
  switch (action.type) {
    case Actions.SET_SCHEDULE_DATA:
      return action.scheduleData;
    default:
      return state;
  }
}

function redirectData(state = initial.redirectData, action) {
  switch (action.type) {
    case Actions.SET_REDIRECT_DATA:
      return action.redirectData;
    default:
      return state;
  }
}

export default (history) => combineReducers({
  router: connectRouter(history),
  isLoading,
  isModal,
  isMapVisible,
  error,
  dataset,
  rawDataset,
  airlineAirportsData,
  airlineRoutesData,
  airlineScheduleData,
  routesData,
  scheduleData,
  redirectData,
  nearbyAirportsData,
  markers,
  loadedData,
  loadedRoutes,
  flightRoutes,
});
