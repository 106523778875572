import React, { Fragment } from 'react';

const tooltipAirports = ({ activeTooltip, tooltipClassNames, tooltipStyles }, refData) => (
  <div
    className={tooltipClassNames}
    ref={refData}
    style={tooltipStyles} >
    <div className="info">
      <div><b>{activeTooltip.cityName}</b></div>
      <div>{activeTooltip.weight} destinations</div>
    </div>
    <div className="code">{activeTooltip.key}</div>
  </div>
);
const onClickAirports = ({ setRoute, selectedMarker }) => setRoute(`/${selectedMarker.citySlug}-${selectedMarker.key}`);

const tooltipAirlines = ({ activeTooltip, tooltipClassNames, tooltipStyles }, refData) => {
  if (!activeTooltip.airlines) return null;
  return (
    <div
      className={`${tooltipClassNames} expandable`}
      ref={refData}
      style={tooltipStyles} >
      <div className="info">
        <div><b>{activeTooltip.airlines[0].name}</b></div>
        <div>based in <strong>{activeTooltip.airlines[0].airportCode}</strong></div>
        {(activeTooltip.airlines.length === 1) ? null : (
          <div>
            {activeTooltip.airlines.slice(1).map((d) => (
              <div className="one-line" key={d.key}> + {d.name}</div>
            ))}
          </div>
        )}
      </div>
      <div className="code light"><div className={`al large al-${activeTooltip.airlines[0].key}`}></div></div>
    </div>
  );
};

const onClickAirlines = ({ setRoute, selectedMarker }) => {
  setRoute(`/${selectedMarker.airlines[0].slug}`);
};
const onClickAirlineAirports = ({ setRoute, selectedMarker, params }) => {
  setRoute(`/${selectedMarker.citySlug}-${selectedMarker.key}/${params.airlineSlug}`);
};

const tooltipRoutes = ({ activeTooltip, tooltipClassNames, tooltipStyles }, refData) => (
  <div
    className={tooltipClassNames}
    ref={refData}
    style={tooltipStyles} >
    <div className="info">
      <div><b>{activeTooltip.cityName}</b></div>
      <Fragment>{activeTooltip.weight.toLocaleString()}<i className="icon icon-aircraft" />{'/week '}</Fragment>
    </div>
    <div className="code">{activeTooltip.key}</div>
  </div>
);
const onClickRoutes = ({ setRoute, selectedMarker, params }) => {
  if (params.departureCode === selectedMarker.key) {
    return setRoute('/airport-list');
  }
  return setRoute(`/${params.departureCitySlug}-${params.departureCode}/${selectedMarker.citySlug}-${selectedMarker.key}`);
};
const onClickAirlineRoutes = ({ setRoute, selectedMarker, params }) => {
  if (params.departureCode === selectedMarker.key) {
    return setRoute(`/${params.airlineSlug}`);
  }
  return setRoute(`/${params.departureCitySlug}-${params.departureCode}/${selectedMarker.citySlug}-${selectedMarker.key}/${params.airlineSlug}`);
};

const tooltipSchedule = ({
  activeTooltip,
  params,
  tooltipClassNames,
  tooltipStyles,
}, refData) => (
  <div
    className={tooltipClassNames}
    ref={refData}
    style={tooltipStyles} >
    <div className="info">
      <div>{(activeTooltip.key === params.arrivalCode) ? 'to' : 'from'}</div>
      <b>{activeTooltip.cityName}</b>
    </div>
    <div className="code">{activeTooltip.key}</div>
  </div>
);
const onClickSchedule = ({ setRoute, selectedMarker, params }) => {
  if (params.departureCode === selectedMarker.key) {
    return setRoute(`/${params.departureCitySlug}-${params.departureCode}`);
  }
  if (params.arrivalCode === selectedMarker.key) {
    return setRoute(`/${params.arrivalCitySlug}-${params.arrivalCode}`);
  }
  return null;
};

const onClickAirlineSchedule = ({ setRoute, selectedMarker, params }) => {
  if (params.departureCode === selectedMarker.key) {
    return setRoute(`/${params.departureCitySlug}-${params.departureCode}/${params.airlineSlug}`);
  }
  if (params.arrivalCode === selectedMarker.key) {
    return setRoute(`/${params.arrivalCitySlug}-${params.arrivalCode}/${params.airlineSlug}`);
  }
  return null;
};


const markersBehavior = {
  markers: {
    tooltipContent: tooltipAirports,
    onClick: onClickAirports,
  },
  airports: {
    tooltipContent: tooltipAirports,
    onClick: onClickAirports,
  },
  airlines: {
    tooltipContent: tooltipAirlines,
    onClick: onClickAirlines,
  },
  airlineAirports: {
    tooltipContent: tooltipAirports,
    onClick: onClickAirlineAirports,
  },
  routes: {
    tooltipContent: tooltipRoutes,
    onClick: onClickRoutes,
  },
  airlineRoutes: {
    tooltipContent: tooltipRoutes,
    onClick: onClickAirlineRoutes,
  },
  schedule: {
    tooltipContent: tooltipSchedule,
    onClick: onClickSchedule,
  },
  airlineSchedule: {
    tooltipContent: tooltipSchedule,
    onClick: onClickAirlineSchedule,
  },
};

export default markersBehavior;
