import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Loadable from 'react-loadable';
import queryString from 'query-string';

import { getCurrentWeekCode } from '../utils';
import { routesPath } from '../config';
import PanelLoading from './PanelLoading';
import PanelHome from './PanelHome';
import PanelError from './PanelError';

const PanelAirports = Loadable({
  loader: () => import(/* webpackChunkName: "PanelAirports" */ './PanelAirports'),
  loading: PanelLoading,
  modules: ['PanelAirports'],
});


const PanelAirlineAirports = Loadable({
  loader: () => import(/* webpackChunkName: "PanelAirlineAirports" */ './PanelAirlineAirports'),
  loading: PanelLoading,
  modules: ['PanelAirlineAirports'],
});

const PanelAirlineRoutes = Loadable({
  loader: () => import(/* webpackChunkName: "PanelAirlineRoutes" */ './PanelAirlineRoutes'),
  loading: PanelLoading,
  modules: ['PanelAirlineRoutes'],
});

const PanelAirlineSchedule = Loadable({
  loader: () => import(/* webpackChunkName: "PanelAirlineSchedule" */ './PanelAirlineSchedule'),
  loading: PanelLoading,
  modules: ['PanelAirlineSchedule'],
});

const PanelAirlines = Loadable({
  loader: () => import(/* webpackChunkName: "PanelAirlines" */ './PanelAirlines'),
  loading: PanelLoading,
  modules: ['PanelAirlines'],
});

const PanelRoutes = Loadable({
  loader: () => import(/* webpackChunkName: "PanelRoutes" */ './PanelRoutes'),
  loading: PanelLoading,
  modules: ['PanelRoutes'],
});

const PanelSchedule = Loadable({
  loader: () => import(/* webpackChunkName: "PanelSchedule" */ './PanelSchedule'),
  loading: PanelLoading,
  modules: ['PanelSchedule'],
});

const PanelRedirect = Loadable({
  loader: () => import(/* webpackChunkName: "PanelRedirect" */ './PanelRedirect'),
  loading: () => null,
  modules: ['PanelRedirect'],
});

const PanelRedirectHotelCar = Loadable({
  loader: () => import(/* webpackChunkName: "PanelRedirect" */ './PanelRedirectHotelCar'),
  loading: () => null,
  modules: ['PanelRedirectHotelCar'],
});

const PanelRedirectFr = Loadable({
  loader: () => import(/* webpackChunkName: "PanelRedirectFr" */ './PanelRedirectFr'),
  loading: () => null,
  modules: ['PanelRedirectFr'],
});

const PageTerms = Loadable({
  loader: () => import(/* webpackChunkName: "PageTerms" */ './PageTerms'),
  loading: () => null,
  modules: ['PageTerms'],
});

const PagePrivacy = Loadable({
  loader: () => import(/* webpackChunkName: "PagePrivacy" */ './PagePrivacy'),
  loading: () => null,
  modules: ['PagePrivacy'],
});

const PageAlternatives = Loadable({
  loader: () => import(/* webpackChunkName: "PageAlternatives" */ './PageAlternatives'),
  loading: () => null,
  modules: ['PageAlternatives'],
});

const RouteList = () => (
  <Switch>
    <Route path={routesPath.alternatives} exact component={PageAlternatives} />
    <Route path={routesPath.allAirports} exact sensitive component={PanelAirports} />
    <Route path={routesPath.allAirlines} exact sensitive component={PanelAirlines} />
    <Route path={routesPath.airlineRoutes} exact sensitive component={PanelAirlineRoutes} />
    <Route path={routesPath.airlineAirports} exact sensitive component={PanelAirlineAirports} />
    <Route path={routesPath.airlineSchedule} exact sensitive render={(props) => {
      const qs = queryString.parse(props.location.search);
      const p = props.match.params;
      if (qs.weekCode !== 'default' && Number(qs.weekCode) < getCurrentWeekCode()) {
        return <Redirect to={`/${p.departureCitySlug}-${p.departureCode}/${p.arrivalCitySlug}-${p.arrivalCode}/${p.airlineSlug}`} />;
      }
      return <PanelAirlineSchedule {...props}/>;
    }} />
    <Route path={routesPath.airlineSchedule} exact sensitive component={PanelAirlineSchedule} />
    <Route path={routesPath.routeSchedule} exact sensitive render={(props) => {
      const qs = queryString.parse(props.location.search);
      const p = props.match.params;
      if (qs.weekCode !== 'default' && Number(qs.weekCode) < getCurrentWeekCode()) {
        return <Redirect to={`/${p.departureCitySlug}-${p.departureCode}/${p.arrivalCitySlug}-${p.arrivalCode}`} />;
      }
      return <PanelSchedule {...props}/>;
    }} />
    <Route path={routesPath.routeSchedule} exact sensitive component={PanelSchedule} />
    <Route path={routesPath.airportRoutes} exact sensitive component={PanelRoutes} />
    <Route path={routesPath.homepage} exact sensitive component={PanelHome} />
    <Route path={routesPath.redirect} exact sensitive component={PanelRedirect} />
    <Route path={routesPath.redirectHotel} exact sensitive component={PanelRedirectHotelCar} />
    <Route path={routesPath.redirectCar} exact sensitive component={PanelRedirectHotelCar} />
    <Route path={routesPath.redirectDeep} exact sensitive component={PanelRedirect} />
    <Route path={routesPath.redirectFr} exact sensitive component={PanelRedirectFr} />
    <Route path={routesPath.terms} exact sensitive component={PageTerms} />
    <Route path={routesPath.privacy} exact sensitive component={PagePrivacy} />
    <Route component={() => <PanelError errorCode="404"/>} />
  </Switch>
);

export default RouteList;
