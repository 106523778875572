import {
  select as d3Select,
  event as d3Event,
  mouse as d3Mouse,
} from 'd3-selection';
import { zoom as d3Zoom, zoomIdentity } from 'd3-zoom';
import versor from 'versor';
import Kapsule from 'kapsule';

export default Kapsule({
  props: {
    projection: {},
    scaleExtent: {
      default: [0.1, 1e3],
      onChange: (extent, state) => (state.zoom && state.zoom.scaleExtent(extent)),
    },
    northUp: { default: false },
    minRotationY: { default: false },
    onMove: { defaultVal: () => {} },
    onStart: { defaultVal: () => {} },
    onEnd: { defaultVal: () => {} },
  },
  methods: {
    syncProjection: (state, projection) => {
      if (state.zoom) {
        const t = zoomIdentity.scale(projection().scale() / state.unityScale);
        d3Select(state.nodeEl).call(state.zoom.transform, t);
      }
    },
  },
  init(nodeEl, state) {
    let v0; let r0; let
      q0;

    state.nodeEl = nodeEl;

    if (state.projection) {
      state.unityScale = state.projection().scale();
    }

    function zoomStarted() {
      if (!state.projection) return;
      state.onStart();
      const projection = state.projection();

      v0 = versor.cartesian(projection.invert(d3Mouse(this)));
      r0 = projection.rotate();
      q0 = versor(r0);
    }

    function zoomed() {
      if (!state.projection) return;
      const projection = state.projection();

      const scale = d3Event.transform.k * state.unityScale;
      projection.scale(scale);

      const v1 = versor.cartesian(projection.rotate(r0).invert(d3Mouse(this)));


      const q1 = versor.multiply(q0, versor.delta(v0, v1));


      const rotation = versor.rotation(q1);

      if (state.northUp) {
        rotation[2] = 0; // Don't rotate on Z axis
      }

      if (state.minRotationY) {
        if (rotation[1] > 30) rotation[1] = 30;
        else if (rotation[1] < state.minRotationY) rotation[1] = state.minRotationY;
      }

      state.onMove({ scale, rotation });
    }

    state.zoom = d3Zoom()
      .duration(2000)
      .scaleExtent(state.scaleExtent)
      .on('start', zoomStarted)
      .on('end', () => state.onEnd())
      .on('zoom', zoomed);

    d3Select(state.nodeEl).call(state.zoom);
  },
});
