import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import { createBrowserHistory, createMemoryHistory } from 'history';
import logger from 'redux-logger';

import createRootReducer from './reducers';
import { mapDataset } from './actions';

// A nice helper to tell us if we're on the server
export const isServer = !(
  typeof window !== 'undefined'
  && window.document
  && window.document.createElement
);

export default (url = '/', serverState = {}) => {
  // Create a history depending on the environment
  const history = isServer
    ? createMemoryHistory({ initialEntries: [url] })
    : createBrowserHistory();

  const enhancers = [];

  // Dev tools are helpful
  if (process.env.NODE_ENV === 'development' && !isServer) {
    const { devToolsExtension } = window;

    enhancers.push(applyMiddleware(logger));
    if (typeof devToolsExtension === 'function') {
      enhancers.push(devToolsExtension());
    }
  }

  const middleware = [thunk, routerMiddleware(history)];
  const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers,
  );

  // Do we have preloaded state available? Great, save it.
  let preloadedState = {};
  if (typeof window !== 'undefined' && window.__PRELOADED_STATE__) {
    preloadedState = window.__PRELOADED_STATE__;
    preloadedState.dataset = mapDataset(preloadedState.rawDataset);
  }

  const initialState = isServer
    ? serverState
    : preloadedState;

  // Delete it once we have it stored in a variable
  if (!isServer && typeof window !== 'undefined') {
    delete window.__PRELOADED_STATE__;
  }

  // Create the store
  const store = createStore(
    createRootReducer(history),
    initialState,
    composedEnhancers,
  );

  return {
    store,
    history,
  };
};
